import { ColDef, ExcelStyle, SideBarDef, ToolPanelDef, ValueFormatterParams } from 'ag-grid-community';
import { DataStatus, DataStatusType } from 'src/models/ForecastModels';
import { ForecastTemplateLegend } from '../ForecastTemplateCustomToolPanel';
import { CellDataType } from 'src/utils/ag-grid-utils';
import { convertsUTCtoUserLocalAndReturnsReadableFormat } from 'src/utils/date-time-utilities';

// Default widths for different types of columns in the grid
export enum ColumnWidths {
  DEFAULT = 120,
  LINE_ITEM_ID = 180,
  USER_ALIAS_COLUMN = 120,
  BUDGET_TYPE_COLUMN = 120,
  CORP_SEGMENT_CLOSED_COLUMN = 130,
  CORP_SEGMENT_COLUMN = 95,
  CORP_SEGMENT_DESCRIPTION_COLUMN = 180,
  BUSINESS_SEGMENT_CLOSED_COLUMN = 160,
  BUSINESS_SEGMENT_COLUMN = 160,
  TOTAL_LIFE_TIME_SPEND = 165,
  MONTH_COLUMN = 120,
  QUARTER_COLUMN = 130,
  YEAR_COLUMN = 130,
  LAST_UPDATED_AT = 190
}

// Default column definition for AgGrid
export const DefaultColDef: ColDef = {
  resizable: true,
  filter: true,
  sortable: true,
  floatingFilter: false
};

const suppressToolPanel = {
  suppressRowGroups: true,
  suppressValues: true,
  suppressPivots: true,
  suppressPivotMode: true,
  suppressColumnFilter: false,
  suppressColumnSelectAll: false,
  suppressColumnExpandAll: true
};

const toolPanelBaseWidth = {
  minWidth: 180,
  maxWidth: 400,
  width: 250
};

export const forecastDataGridIcons = {
  // The key 'custom-stats' represents a custom icon for stats.
  'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>'
};

export const SideBar: SideBarDef = {
  position: 'right',
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: suppressToolPanel,
      ...toolPanelBaseWidth
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: false
      },
      ...toolPanelBaseWidth
    }
  ]
};

export const RowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'never';

export const ForecastGridFixedFields = {

  // FE metadata fields
  RowId: { value: 'row_id', displayName: 'Row Id' },
  IsNewFERow: { value: 'is_new_fe_row', displayName: 'Is New FE Row' },
  IsTouched: { value: 'is_touched', displayName: 'Is Edited' },
  IsEdited: { value: 'is_edited', displayName: 'Is Dirty' },
  IsActive: { value: 'is_active', displayName: 'Is Active' },
  IsSegmentEdited: { value: 'is_segment_edited', displayName: 'Is Segment Edited' },
  
  // ID fields
  XptLineItemId: { value: 'xpt_line_item_id', displayName: 'Line Item Id' },
  XptLineItemSeqId: { value: 'xpt_line_item_seq_id', displayName: 'Line Item Seq Id' },
  ScenarioSeqId: { value: 'scenario_seq_id', displayName: 'Scenario Seq Id' },

  // Tracking fields
  IsNew: { value: 'is_new', displayName: 'Is New' },
  

  TotalLifetimeSpend: { value: 'total_lifetime_spend', displayName: 'Total Lifetime Spend' },
  BudgetOwner: { value: 'budget_owner', displayName: 'Budget Owner' },
  BudgetType: { value: 'budget_type', displayName: 'Budget Type' },
  UpdatedAt: { value: 'updated_at', displayName: 'Updated At' },
  UpdatedBy: { value: 'updated_by', displayName: 'Updated By' }
} as const;

export type ForecastGridFixedFieldKeys = keyof typeof ForecastGridFixedFields;
export type ForecastGridFixedFieldValues = (typeof ForecastGridFixedFields)[ForecastGridFixedFieldKeys]['value'];

/**
 * Maps display names to their corresponding values based on the ForecastGridFixedFields.
 *
 * @param {string} displayName - The display name to be mapped.
 * @returns {string} - The corresponding value.
 */
export const mapDisplayNameToValue = (displayName: string): string => {
  const field = Object.values(ForecastGridFixedFields).find((field) => field.displayName === displayName);
  return field ? field.value : displayName;
};

// Utility function to replace values in the input array with their corresponding displayName from ForecastGridFixedFields
export const replaceWithDisplayNames = (fields: string[]): string[] => {
  return fields.map((field) => {
    // Find the key in ForecastGridFixedFields whose value matches the current field
    const matchingField = Object.values(ForecastGridFixedFields).find((fixedField) => fixedField.value === field);

    // If a match is found, return the displayName, otherwise return the original field
    return matchingField ? matchingField.displayName : field;
  });
};

export const PREFIX_FIXED_COLUMNS_FORECAST_TEMPLATE: ColDef[] = [
  {
    field: ForecastGridFixedFields.RowId.value,
    headerName: ForecastGridFixedFields.RowId.displayName,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    cellClass: ['text-field'],
    editable: false
  },
  {
    field: ForecastGridFixedFields.IsNewFERow.value,
    headerName: ForecastGridFixedFields.IsNewFERow.displayName,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    editable: false
  },
  {
    field: ForecastGridFixedFields.IsTouched.value,
    headerName: ForecastGridFixedFields.IsTouched.displayName,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    editable: false
  },
  {
    field: ForecastGridFixedFields.IsEdited.value,
    headerName: ForecastGridFixedFields.IsEdited.displayName,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    editable: false
  },
  {
    field: ForecastGridFixedFields.XptLineItemSeqId.value,
    headerName: ForecastGridFixedFields.XptLineItemSeqId.displayName,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    cellDataType: CellDataType.NUMBER,
    editable: false
  },
  {
    field: ForecastGridFixedFields.ScenarioSeqId.value,
    headerName: ForecastGridFixedFields.ScenarioSeqId.displayName,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    editable: false
  }
];

export const SUFFIX_FIXED_COLUMNS_FORECAST_TEMPLATE: ColDef[] = [
  {
    field: ForecastGridFixedFields.UpdatedAt.value,
    headerName: ForecastGridFixedFields.UpdatedAt.displayName,
    wrapHeaderText: true,
    hide: true,
    width: ColumnWidths.LAST_UPDATED_AT,
    editable: false,
    valueFormatter: (params: ValueFormatterParams) => convertsUTCtoUserLocalAndReturnsReadableFormat(params.value),
  },
  {
    field: ForecastGridFixedFields.UpdatedBy.value,
    headerName: ForecastGridFixedFields.UpdatedBy.displayName,
    wrapHeaderText: true,
    hide: true,
    width: ColumnWidths.DEFAULT,
    cellClass: ['text-field'],
    editable: false
  }
];

export const ForecastTemplateDataStatuses: Record<DataStatusType, DataStatus> = {
  NotLoaded: {
    status: 'NotLoaded',
    statusDisplayName: 'Not Loaded',
    statusType: 'stopped',
    statusColor: 'grey',
    tooltip: 'Data not loaded yet.'
  },
  Latest: {
    status: 'Latest',
    statusDisplayName: 'Latest',
    statusType: 'success',
    statusColor: 'green',
    tooltip: 'Data is up-to-date.'
  },
  Modified: {
    status: 'Modified',
    statusDisplayName: 'Changes detected. Submit when done.',
    statusType: 'pending',
    statusColor: 'yellow',
    tooltip: 'You have made changes to this data. Remember to submit your changes.'
  },
  Outdated: {
    status: 'Outdated',
    statusDisplayName: 'Outdated',
    statusType: 'warning',
    statusColor: 'red',
    tooltip: 'This data is outdated. Another user has made updates. Refresh to get the latest data.'
  }
};

export const forecastExcelStyles = [
  {
    id: 'editable-cell',
    interior: {
      color: '#FFFFED',
      pattern: 'Solid'
    }
  },
  {
    id: 'nan-cell',
    interior: {
      color: 'lightcoral' // Light coral color
    }
  },
  {
    id: 'text-field',
    dataType: 'String'
  },
  {
    id: 'required-cell',
    dataType: 'String',
    font: {
      bold: true
    }
  },
  {
    id: 'total-lifetime-spend-cell'
  },
  {
    id: 'actual-month-cell'
  },
  {
    id: 'actual-quarter-cell'
  },
  {
    id: 'actual-year-cell'
  },
  {
    id: 'forecast-month-cell'
  },
  {
    id: 'forecast-quarter-cell'
  },
  {
    id: 'forecast-year-cell'
  }
] as ExcelStyle[];
