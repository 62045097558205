import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ICellRendererParams,
  INumberCellEditorParams,
  IRichCellEditorParams,
  NewValueParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams
} from 'ag-grid-community';
import { logger } from 'src/analytics/KatalLogger';
import { eBusinessSegmentNames, eCorpSegmentNames } from 'src/constants/corp-segment-constants';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { HEADER_TYPES, HeaderType, STAGES } from 'src/constants/generic-constants';
import { customBusinessSegmentSort, customCorpSegmentSort } from 'src/features/admin-console/onboarding-business-groups/OnboardingFormUtils';
import {
  BusinessGroupEntity,
  BusinessSegmentDataType,
  BusinessSegmentsEntity,
  CorpSegmentsEntity,
  MasterBusinessSegments
} from 'src/models/AppContextModels';
import { ForecastTemplateMasterCorpSegmentDropdownValues } from 'src/models/ForecastModels';
import { PlanningCycle } from 'src/models/PlanningCycleModel';
import { AccountBudgetTypeMapping } from 'src/models/xPTMappingModels';
import { AgGridEditorType, CellDataType, cellValueParser, deepClone, forecastCurrencyFormatter } from 'src/utils/ag-grid-utils';
import { convertMonthFormatToDisplay, generateMonthList, getQuarterFromMonth, getYearFromMonthYear } from 'src/utils/date-time-utilities';
import { ForecastCustomTooltip } from './ForecastCustomTooltip';
import * as ForecastGridConstants from './ForecastGridConstants';

/**
 * Generates column definitions for the Ag-Grid based on various conditions and settings.
 * This function handles the creation of both standard and dynamic columns based on the
 * provided planning cycle, user permissions, and corporate/business segment filters.
 *
 * @param {string} userAlias - The alias of the user, used to personalize certain columns.
 * @param {PlanningCycle} planningCycle - An object containing details about the current planning cycle.
 * @param {BusinessGroupEntity} businessGroup - Metadata about the current business group, which includes corporate and business segments.
 * @param {boolean} isCycleLocked - Flag to determine if the planning cycle is locked, affecting the edit ability of columns.
 * @param {boolean} isReadOnlyUser - Flag to indicate if the user has read-only access, which restricts editing capabilities.
 * @param {boolean} isAdminUser - Flag indicating if the user has administrative privileges, potentially allowing more editing options.
 * @param {boolean} isBudgetOwner - Flag to check if the user is the budget owner, which may grant additional permissions on specific columns.
 * @param {ForecastTemplateMasterCorpSegmentDropdownValues[]} corpSegmentDropdownValues - Filters applicable to corporate segments, affecting the rendering and options of segment columns.
 * @param {MasterBusinessSegments[]} masterBusinessSegments - Master Business Segments with dropdowns list fetched from S3
 * @returns {Promise<ColDef[]>} Promise that resolves to an array of column definitions for use in an Ag-Grid setup.
 *
 * @throws {Error} Throws an error if unable to generate column definitions due to data or internal issues.
 */
export const generateColumnDefinitions = async (
  userAlias: string,
  planningCycle: PlanningCycle,
  businessGroup: BusinessGroupEntity,
  isCycleLocked: boolean,
  isReadOnlyUser: boolean,
  isAdminUser: boolean,
  isBudgetOwner: boolean,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  masterBusinessSegments: MasterBusinessSegments[],
  expenseTypesForCurrentGroup: string[],
  accountBudgetTypeMapping: AccountBudgetTypeMapping[],
  budgetOwnerUserAliases: string[]
): Promise<ColDef[]> => {
  if (!planningCycle) {
    return [];
  }
  try {
    const isEditable = !isCycleLocked && !isReadOnlyUser;

    // Display only required Corp Segments in Forecast Template
    const requiredCorpSegments = businessGroup.corp_segments.filter((corpSegment) => corpSegment.corp_segment_required);
    const businessSegments = businessGroup.business_segments;

    const InitialLineItemIdColumn: ColDef = prepareInitialLineItemIdColumn(isReadOnlyUser, isCycleLocked);

    const informativeColumns: ColDef[] = metadataColumns();

    // Fixed prefix columns are pre-defined
    const finalGridColDef: ColDef[] = [
      InitialLineItemIdColumn,
      ...informativeColumns,
      prepareBudgetOwnerColumnDefinition(isAdminUser, isCycleLocked, budgetOwnerUserAliases),
      prepareBudgetTypeColumnDefinition(),
      ...ForecastGridConstants.PREFIX_FIXED_COLUMNS_FORECAST_TEMPLATE
    ];

    const actualMonths = generateMonthList(planningCycle.actuals_start_month_id, planningCycle.actuals_end_month_id);
    const actualMonthsGroup: ColGroupDef = {
      headerName: 'Actuals',
      marryChildren: true,
      children: getActualForecastHeadersWithGrouping(actualMonths, HEADER_TYPES.ACTUAL, false, userAlias, isAdminUser)
    };

    const forecastMonths = generateMonthList(planningCycle.forecast_start_month_id, planningCycle.forecast_end_month_id);
    const forecastMonthsGroup: ColGroupDef = {
      headerName: 'Forecast',
      marryChildren: true,
      children: getActualForecastHeadersWithGrouping(forecastMonths, HEADER_TYPES.FORECAST, isEditable, userAlias, isAdminUser)
    };

    // all column definitions in the correct order
    finalGridColDef.push(
      prepareCorpSegmentColumns(
        requiredCorpSegments,
        userAlias,
        isReadOnlyUser,
        isAdminUser,
        isBudgetOwner,
        corpSegmentDropdownValues,
        isEditable,
        accountBudgetTypeMapping
      ),
      prepareBusinessGroupColumns(
        masterBusinessSegments,
        businessSegments,
        expenseTypesForCurrentGroup,
        userAlias,
        isReadOnlyUser,
        isAdminUser,
        isBudgetOwner,
        isEditable
      ),
      prepareLifeTimeSpendColumn([...actualMonthsGroup.children, ...forecastMonthsGroup.children]),
      actualMonthsGroup,
      forecastMonthsGroup,
      ...ForecastGridConstants.SUFFIX_FIXED_COLUMNS_FORECAST_TEMPLATE
    );

    // console.debug('finalGridColDef', JSON.stringify(finalGridColDef));
    return finalGridColDef;
  } catch (error: any) {
    logger.error('Unable to generate column definition', error);
    throw new Error(error?.message || 'Unable to generate column definition');
  }
};

export const prepareBudgetOwnerColumnDefinition = (isAdminUser: boolean, isCycleLocked: boolean, budgetOwnerUserAliases: string[]): ColDef => {
  const isBudgetOwnerEditable = isAdminUser && !isCycleLocked;
  const budgetOwnerColDefinition: ColDef = {
    field: ForecastGridConstants.ForecastGridFixedFields.BudgetOwner.value,
    headerName: ForecastGridConstants.ForecastGridFixedFields.BudgetOwner.displayName,
    pinned: 'left',
    wrapHeaderText: true,
    hide: false,
    width: ForecastGridConstants.ColumnWidths.USER_ALIAS_COLUMN,
    minWidth: ForecastGridConstants.ColumnWidths.USER_ALIAS_COLUMN,
    floatingFilter: false,
    headerClass: ['required-cell'],
    cellClass: (cellClassParams: CellClassParams<any, any>) => {
      if (cellClassParams.node.footer) {
        return [];
      }
      return isBudgetOwnerEditable ? ['editable-cell', 'text-field'] : ['text-field'];
    },
    editable: isBudgetOwnerEditable,
    cellDataType: CellDataType.TEXT,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: budgetOwnerUserAliases,
      valueListMaxHeight: 300,
      allowTyping: true,
      searchType: 'matchAny',
      filterList: true,
      highlightMatch: true
    } as IRichCellEditorParams,
    cellEditorPopup: true,
    cellEditorPopupPosition: 'over'
  };
  return budgetOwnerColDefinition;
};

export const prepareBudgetTypeColumnDefinition = (): ColDef => {
  const budgetTypeColumnDefinition: ColDef = {
    field: ForecastGridConstants.ForecastGridFixedFields.BudgetType.value,
    headerName: ForecastGridConstants.ForecastGridFixedFields.BudgetType.displayName,
    headerComponent: ForecastCustomTooltip,
    pinned: 'left',
    wrapHeaderText: true,
    hide: false,
    width: ForecastGridConstants.ColumnWidths.BUDGET_TYPE_COLUMN,
    minWidth: ForecastGridConstants.ColumnWidths.BUDGET_TYPE_COLUMN,
    floatingFilter: false,
    cellClass: ['text-field'],
    editable: false
  };
  return budgetTypeColumnDefinition;
};

/**
 * Converts an array of month identifiers to their corresponding quarters.
 * This function processes a list of month-year strings and extracts the unique quarters they belong to.
 *
 * @param {string[]} monthsOfThisYear - An array of month strings formatted as "MonthYear" (e.g., "Jan2023").
 * @returns {string[]} An array of unique quarter identifiers derived from the input months, formatted as "Q1", "Q2", "Q3", or "Q4".
 */
export const getQuartersFromMonths = (monthsOfThisYear: string[]): string[] => {
  const quarters: Set<string> = new Set();
  monthsOfThisYear?.forEach((month) => {
    quarters.add(getQuarterFromMonth(month));
  });
  return Array.from(quarters);
};

export const metadataColumns = (): ColDef[] => {
  const stage: string = ENVIRONMENT_VARIABLES.env.Stage; // Explicitly define the type for `stage`
  const isDevStage: boolean = stage === STAGES.DEV; // Calculate once for reuse

  // Common properties shared across metadata columns
  const commonColumnProps: Partial<ColDef> = {
    pinned: 'left',
    lockPinned: true,
    headerClass: ['text-center'],
    wrapHeaderText: true,
    hide: !isDevStage,
    suppressColumnsToolPanel: !isDevStage,
    width: 100,
    minWidth: 100,
    cellDataType: CellDataType.TEXT,
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false
    }
  };

  // Metadata columns definition using spread syntax to combine common properties
  const metadataColumns: ColDef[] = [
    {
      field: ForecastGridConstants.ForecastGridFixedFields.IsNew.value,
      headerName: ForecastGridConstants.ForecastGridFixedFields.IsNew.displayName,
      ...commonColumnProps
    },
    {
      field: ForecastGridConstants.ForecastGridFixedFields.IsSegmentEdited.value,
      headerName: ForecastGridConstants.ForecastGridFixedFields.IsSegmentEdited.displayName,
      ...commonColumnProps
    },
    {
      field: ForecastGridConstants.ForecastGridFixedFields.IsNewFERow.value,
      headerName: ForecastGridConstants.ForecastGridFixedFields.IsNewFERow.displayName,
      ...commonColumnProps
    },
    {
      field: ForecastGridConstants.ForecastGridFixedFields.IsEdited.value,
      headerName: ForecastGridConstants.ForecastGridFixedFields.IsEdited.displayName,
      ...commonColumnProps
    }
  ];

  return metadataColumns;
};

export const prepareInitialLineItemIdColumn = (isReadOnlyUser: boolean, isCycleLocked: boolean): ColDef => {
  const lineItemIdColumnDefault: ColDef = {
    field: ForecastGridConstants.ForecastGridFixedFields.XptLineItemId.value,
    headerName: ForecastGridConstants.ForecastGridFixedFields.XptLineItemId.displayName,
    pinned: 'left',
    lockPinned: true,
    headerClass: ['text-center'],
    wrapHeaderText: true,
    hide: false,
    width: ForecastGridConstants.ColumnWidths.LINE_ITEM_ID,
    minWidth: ForecastGridConstants.ColumnWidths.LINE_ITEM_ID,
    cellDataType: CellDataType.TEXT,
    cellClass: ['text-field'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.footer) {
        return 'Total';
      }
      return params.value;
    }
  };

  // Show checkboxes only for users with edit permissions and when the planning cycle is unlocked
  if (!isReadOnlyUser && !isCycleLocked) {
    Object.assign(lineItemIdColumnDefault, {
      checkboxSelection: (params: ICellRendererParams) => {
        // Only show checkbox if the row is not a footer
        return !params.node.footer;
      },
      showDisabledCheckboxes: false
    } as ColDef);
  }

  return lineItemIdColumnDefault;
};

export const prepareLifeTimeSpendColumn = (allMonths: ColDef[]) => {
  const lifeTimeSpendColumn: ColDef = {
    editable: false,
    field: ForecastGridConstants.ForecastGridFixedFields.TotalLifetimeSpend.value,
    headerName: ForecastGridConstants.ForecastGridFixedFields.TotalLifetimeSpend.displayName,
    headerComponent: ForecastCustomTooltip,
    wrapHeaderText: true,
    width: ForecastGridConstants.ColumnWidths.TOTAL_LIFE_TIME_SPEND,
    minWidth: ForecastGridConstants.ColumnWidths.TOTAL_LIFE_TIME_SPEND,
    suppressMovable: true,
    lockPinned: true,
    cellDataType: CellDataType.NUMBER,
    cellClass: ['total-lifetime-spend-cell'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false
    },
    valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    valueGetter: (params: ValueGetterParams) => sumUpYearlyColumns(params, allMonths),
    aggFunc: 'sum'
  };
  return lifeTimeSpendColumn;
};

export type ColumnGroupType = 'ClosedGroup' | 'OpenGroup';

// To check the pinned state based on group and segment name
export const isColumnPinned = (group: ColumnGroupType, segmentName: eCorpSegmentNames): 'left' | 'right' | undefined => {
  if (group === 'ClosedGroup' && segmentName === eCorpSegmentNames.COST_CENTER) {
    return 'left';
  }
  return undefined;
};

// Function to generate the column definition for a corporate segment
const createCorpSegmentColumn = (
  segment: CorpSegmentsEntity,
  group: ColumnGroupType,
  index: number,
  userAlias: string,
  isReadOnlyUser: boolean,
  isAdminUser: boolean,
  isBudgetOwner: boolean,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  isDescriptionField: boolean,
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
): ColDef => {
  const fieldName = isDescriptionField ? `${segment.corp_segment_name} Description` : segment.corp_segment_name;
  const baseConfig: ColDef = {
    field: fieldName,
    headerName: fieldName,
    headerClass: isDescriptionField ? [] : ['required-cell'],
    pinned: isColumnPinned(group, segment.corp_segment_name as eCorpSegmentNames),
    wrapHeaderText: true,
    width: isDescriptionField
      ? ForecastGridConstants.ColumnWidths.CORP_SEGMENT_DESCRIPTION_COLUMN
      : ForecastGridConstants.ColumnWidths.CORP_SEGMENT_COLUMN,
    minWidth: isDescriptionField
      ? ForecastGridConstants.ColumnWidths.CORP_SEGMENT_DESCRIPTION_COLUMN
      : ForecastGridConstants.ColumnWidths.CORP_SEGMENT_COLUMN,
    suppressFiltersToolPanel: group === 'ClosedGroup',
    suppressColumnsToolPanel: group === 'ClosedGroup',
    columnGroupShow: group === 'ClosedGroup' ? 'closed' : 'open',
    cellDataType: CellDataType.TEXT,
    cellClass: ['text-field'],
    enableRowGroup: true
  };

  if (group !== 'ClosedGroup') {
    Object.assign(baseConfig, {
      enablePivot: true,
      pivotIndex: index,
      pivot: true
    });
  }

  if (!isReadOnlyUser && (isAdminUser || isBudgetOwner)) {
    const segmentDropdownValues = getSegmentDropdownValues(segment, corpSegmentDropdownValues, isDescriptionField);

    Object.assign(baseConfig, {
      editable: (editParams: EditableCallbackParams<any, any>) => isCorpSegmentEditable(editParams.data, userAlias, isAdminUser),
      cellClass: (cellClassParams: CellClassParams<any, any>) =>
        isCorpSegmentEditable(cellClassParams.data, userAlias, isAdminUser) ? ['text-field', 'editable-cell'] : ['text-field'],
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: createRichCellEditorParams(segmentDropdownValues),
      onCellValueChanged: async (params: NewValueParams) => {
        // Trigger the appropriate handler based on whether it's a description field
        if (isDescriptionField) {
          handleCorpSegmentDescriptionChange(params, segment, corpSegmentDropdownValues, accountBudgetTypeMapping);
        } else {
          handleCorpSegmentNameChange(params, segment, corpSegmentDropdownValues, accountBudgetTypeMapping);
        }
      }
    });
  }

  return baseConfig;
};

// Function to retrieve dropdown values for a corporate segment
const getSegmentDropdownValues = (
  segment: CorpSegmentsEntity,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  isDescriptionField: boolean
): string[] => {
  const dropdownValues =
    corpSegmentDropdownValues.find((filter) => filter.masterCorpSegmentDisplayName === segment.corp_segment_name)?.masterCorpSegmentDropdownValues ||
    [];

  return isDescriptionField ? dropdownValues.map((option) => option.description || '') : dropdownValues.map((option) => option.label || '');
};

// Function to create rich select cell editor parameters
const createRichCellEditorParams = (values: string[]): IRichCellEditorParams => ({
  values,
  valueListMaxHeight: 300,
  allowTyping: true,
  highlightMatch: true,
  searchType: 'matchAny',
  filterList: true
});

// Function to handle changes in corporate segment name field
const handleCorpSegmentNameChange = (
  params: NewValueParams,
  segment: CorpSegmentsEntity,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
) => {
  try {
    const newValue = params.newValue;
    const rowData = params.data;
    const node = params.node;

    const descriptionFieldName = `${segment.corp_segment_name} Description`;
    const newDescription = findNewDescription(segment, newValue, corpSegmentDropdownValues);

    updateRowData(node, rowData, descriptionFieldName, newDescription);
    updateBudgetTypeIfAccount(segment, node, newValue, accountBudgetTypeMapping);
  } catch (error: any) {
    logger.error('Error processing corp segment cell value change:', error);
  }
};

// Function to handle changes in corporate segment description field
const handleCorpSegmentDescriptionChange = (
  params: NewValueParams,
  segment: CorpSegmentsEntity,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
) => {
  try {
    const newValue = params.newValue;
    const rowData = params.data;
    const node = params.node;

    const segmentCodeFieldName = `${segment.corp_segment_name}`;
    const newCode = findNewCode(segment, newValue, corpSegmentDropdownValues);

    updateRowData(node, rowData, segmentCodeFieldName, newCode);
    updateBudgetTypeIfAccount(segment, node, newCode, accountBudgetTypeMapping);
  } catch (error: any) {
    logger.error('Error processing corp segment cell value change:', error);
  }
};

// Helper function to find the new description for a segment
const findNewDescription = (
  segment: CorpSegmentsEntity,
  newValue: string,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[]
): string => {
  return (
    corpSegmentDropdownValues
      .find((filter) => filter.masterCorpSegmentDisplayName === segment.corp_segment_name)
      ?.masterCorpSegmentDropdownValues?.find((option) => option.label === newValue)?.description || ''
  );
};

// Helper function to find the new code for a segment
const findNewCode = (
  segment: CorpSegmentsEntity,
  newValue: string,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[]
): string => {
  return (
    corpSegmentDropdownValues
      .find((filter) => filter.masterCorpSegmentDisplayName === segment.corp_segment_name)
      ?.masterCorpSegmentDropdownValues?.find((option) => option.description === newValue)?.label || ''
  );
};

// Helper function to update row data and refresh cells
const updateRowData = (node: any, rowData: any, fieldName: string, newValue: string | null) => {
  rowData[fieldName] = newValue;
  node?.setDataValue(fieldName, newValue);
  node?.gridOptionsWrapper?.gridOptions?.api?.refreshCells({ force: true });
};

// Helper function to update the budget type if the segment is an account
const updateBudgetTypeIfAccount = (
  segment: CorpSegmentsEntity,
  node: any,
  newValue: string,
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
) => {
  if (segment.corp_segment_name === eCorpSegmentNames.ACCOUNT && node) {
    const budgetTypeField = ForecastGridConstants.ForecastGridFixedFields.BudgetType.value;
    const budgetType = accountBudgetTypeMapping.find((mapping) => mapping.account_code === newValue)?.budget_type || null;

    if (budgetType === null) logger.warn(`Unable to find Budget Type for Account Code ${newValue}`);

    node.setDataValue(budgetTypeField, budgetType);
    node.gridOptionsWrapper.gridOptions.api.refreshCells({ force: true });
  }
};

// Function to prepare columns for corporate segments
export const prepareCorpSegmentColumns = (
  corpSegments: [] | CorpSegmentsEntity[],
  userAlias: string,
  isReadOnlyUser: boolean,
  isAdminUser: boolean,
  isBudgetOwner: boolean,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  isEditable: boolean,
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
) => {
  const modifiableCorpSegments = deepClone(corpSegments);
  const sortedCorpSegments = modifiableCorpSegments.sort(customCorpSegmentSort);
  const corpSegmentGroup: ColGroupDef = {
    headerName: 'Corp Segments',
    marryChildren: true,
    children: [
      ...sortedCorpSegments
        .slice(0, 1)
        .flatMap((segment, index) => [
          createCorpSegmentColumn(
            segment,
            'ClosedGroup',
            index,
            userAlias,
            isReadOnlyUser,
            isAdminUser,
            isBudgetOwner,
            corpSegmentDropdownValues,
            false,
            accountBudgetTypeMapping
          ),
          createCorpSegmentColumn(
            segment,
            'ClosedGroup',
            index,
            userAlias,
            isReadOnlyUser,
            isAdminUser,
            isBudgetOwner,
            corpSegmentDropdownValues,
            true,
            accountBudgetTypeMapping
          )
        ]),
      ...sortedCorpSegments.flatMap((segment, index) => [
        createCorpSegmentColumn(
          segment,
          'OpenGroup',
          index,
          userAlias,
          isReadOnlyUser,
          isAdminUser,
          isBudgetOwner,
          corpSegmentDropdownValues,
          false,
          accountBudgetTypeMapping
        ),
        createCorpSegmentColumn(
          segment,
          'OpenGroup',
          index,
          userAlias,
          isReadOnlyUser,
          isAdminUser,
          isBudgetOwner,
          corpSegmentDropdownValues,
          true,
          accountBudgetTypeMapping
        )
      ])
    ]
  };
  return corpSegmentGroup;
};

/**
 *
 * @param businessSegment
 * @param agGridColumnGroupStatus
 * @param index
 * @param userAlias
 * @param isReadOnlyUser
 * @param isAdminUser
 * @param isBudgetOwner
 * @returns  {ColDef}
 */
export const businessSegmentColumn = (
  masterBusinessSegments: MasterBusinessSegments[],
  businessSegment: BusinessSegmentsEntity,
  expenseTypesForCurrentGroup: string[],
  agGridColumnGroupStatus: 'ClosedGroup' | 'OpenGroup',
  index: number,
  userAlias: string,
  isReadOnlyUser: boolean,
  isAdminUser: boolean,
  isBudgetOwner: boolean,
  isEditable: boolean
): ColDef => {
  const baseConfig: ColDef = {
    field: `${businessSegment.business_segment_name}`,
    headerName: businessSegment.business_segment_name,
    headerClass: businessSegment.is_id_column ? ['required-cell'] : [],
    wrapHeaderText: true,
    cellDataType: CellDataType.TEXT,
    width:
      agGridColumnGroupStatus === 'ClosedGroup'
        ? ForecastGridConstants.ColumnWidths.BUSINESS_SEGMENT_CLOSED_COLUMN
        : ForecastGridConstants.ColumnWidths.BUSINESS_SEGMENT_COLUMN,
    minWidth:
      agGridColumnGroupStatus === 'ClosedGroup'
        ? ForecastGridConstants.ColumnWidths.BUSINESS_SEGMENT_CLOSED_COLUMN
        : ForecastGridConstants.ColumnWidths.BUSINESS_SEGMENT_COLUMN,
    columnGroupShow: agGridColumnGroupStatus === 'ClosedGroup' ? 'closed' : 'open',
    suppressFiltersToolPanel: agGridColumnGroupStatus === 'ClosedGroup',
    suppressColumnsToolPanel: agGridColumnGroupStatus === 'ClosedGroup',
    enableRowGroup: true
  };

  if (!isReadOnlyUser && isEditable) {
    if (isAdminUser || isBudgetOwner) {
      // Requirements for Business Segment editable
      // Editable:
      // If the row is new (XptLineItemId is null), the field is editable.
      // If the user is the budget owner of the record, the field is editable.
      // If the user is an admin, the field is editable.

      // Not Editable:
      // If the row already exists (XptLineItemId is not null) and the field is an ID field, it is not editable.

      const isIdField = businessSegment.is_id_column;
      const dataType = segmentDataTypeMatchWithAgGridEditorType(businessSegment.business_segment_data_type);
      Object.assign(baseConfig, {
        cellEditor: dataType,
        editable: (editParams: EditableCallbackParams<any, any>) => isBusinessSegmentEditable(editParams.data, userAlias, isAdminUser, isIdField),
        cellClass: (cellClassParams: CellClassParams<any, any>) =>
          isBusinessSegmentEditable(cellClassParams.data, userAlias, isAdminUser, isIdField) ? ['text-field', 'editable-cell'] : ['text-field']
      } as ColDef);

      // If Business Segment data_type is Dropdown
      if (dataType === 'agRichSelectCellEditor') {
        let masterSegmentDropdowns: string[] = [];

        if (businessSegment.business_segment_name === eBusinessSegmentNames.EXPENSE_TYPE) {
          masterSegmentDropdowns = expenseTypesForCurrentGroup;
        } else {
          masterSegmentDropdowns =
            masterBusinessSegments.find((masterBusinessSegment) => masterBusinessSegment.segment_name === businessSegment.business_segment_name)
              ?.business_segment_dropdown_list || [];
        }

        if (masterSegmentDropdowns?.length === 0) logger.error(`No dropdown values found for segment ${businessSegment.business_segment_name}`);

        Object.assign(baseConfig, {
          cellEditorParams: {
            values: masterSegmentDropdowns,
            valueListMaxHeight: 300,
            allowTyping: true,
            highlightMatch: true,
            searchType: 'matchAny',
            filterList: true
          } as IRichCellEditorParams
        } as ColDef);
      }
    }
  }

  return baseConfig;
};

export const prepareBusinessGroupColumns = (
  masterBusinessSegments: MasterBusinessSegments[],
  businessSegments: BusinessSegmentsEntity[] | [],
  expenseTypesForCurrentGroup: string[],
  userAlias: string,
  isReadOnlyUser: boolean,
  isAdminUser: boolean,
  isBudgetOwner: boolean,
  isEditable: boolean
) => {
  // Creates a deep copy of `modifiableBusinessSegments` from `businessSegments` to ensure immutability.
  // This allows modifications to the segments without altering the original array.
  // `cloneDeep` is used to copy nested objects, ensuring changes to deep properties do not affect the original data structure.
  const modifiableBusinessSegments = deepClone(businessSegments);
  const sortedBusinessSegments = modifiableBusinessSegments.sort(customBusinessSegmentSort);
  const businessSegmentGroup: ColGroupDef = {
    headerName: 'Business Segments',
    marryChildren: true,
    children: [
      ...sortedBusinessSegments
        .slice(0, 1)
        .map((segment, index) =>
          businessSegmentColumn(
            masterBusinessSegments,
            segment,
            expenseTypesForCurrentGroup,
            'ClosedGroup',
            index,
            userAlias,
            isReadOnlyUser,
            isAdminUser,
            isBudgetOwner,
            isEditable
          )
        ),
      ...sortedBusinessSegments.map((segment, index) =>
        businessSegmentColumn(
          masterBusinessSegments,
          segment,
          expenseTypesForCurrentGroup,
          'OpenGroup',
          index,
          userAlias,
          isReadOnlyUser,
          isAdminUser,
          isBudgetOwner,
          isEditable
        )
      )
    ]
  };
  return businessSegmentGroup;
};

/**
 * Generates columns for each year, including quarterly and monthly groupings, for the forecast table.
 * @param {string[]} monthHeaders - Array of month headers in the format 'YYYYMM01'.
 * @param {'Actual' | 'Forecast'} headerFor - Indicates if the headers are for 'Actual' or 'Forecast'.
 * @param {boolean} isEditable - Boolean indicating if the columns are editable.
 * @param {string} userAlias - The alias of the current user.
 * @param {boolean} isAdmin - Boolean indicating if the user is an admin.
 * @returns {ColDef[]} - Array of column definitions for the AG Grid.
 */
export const getActualForecastHeadersWithGrouping = (
  monthHeaders: string[],
  headerFor: HeaderType,
  isEditable: boolean,
  userAlias: string,
  isAdmin: boolean
): ColDef[] => {
  const agGridHeaderData: ColDef[] = [];

  // Extract unique years from month headers
  const years = new Set<string>();
  monthHeaders?.forEach((monthHeader) => {
    years.add(getYearFromMonthYear(monthHeader));
  });

  years.forEach((year: string) => {
    // Filter month headers for the current year
    const monthsOfThisYear = monthHeaders?.filter((monthHeader) => monthHeader.startsWith(year.toString()));
    const quartersOfThisYear = getQuartersFromMonths(monthsOfThisYear);

    const quarterWithMonthlyHeaderInfo: ColDef[] = [];

    quartersOfThisYear?.forEach((quarter) => {
      // Filter month headers for the current quarter
      const monthsOfThisQuarter = monthsOfThisYear?.filter((month) => quarter === getQuarterFromMonth(month));

      // Generate month column definitions
      const monthColumnDefinitions: ColDef[] = generateMonthColumnDefinition(monthsOfThisQuarter, headerFor, isEditable, userAlias, isAdmin);

      // Generate quarter column definitions including monthly columns
      generateMonthsWithQuarterColumnDefinition(quarterWithMonthlyHeaderInfo, quarter, monthColumnDefinitions, year, headerFor);
    });

    // Generate year column definitions including quarter columns
    const yearColumnDefinitions = generateYearColumnDefinitions(year, quarterWithMonthlyHeaderInfo, headerFor);
    agGridHeaderData.push(yearColumnDefinitions);
  });

  const finalOutput: ColDef[] = [];
  agGridHeaderData.forEach((year: any) => {
    year.children?.forEach((quarter: any) => {
      if (quarter.children?.length > 0) {
        quarter.children?.forEach((monthlyData: any) => {
          finalOutput.push({ ...monthlyData, columnGroupShow: 'open' });
        });
      } else {
        finalOutput.push({ ...quarter, columnGroupShow: 'open' });
        finalOutput.push({ ...quarter, columnGroupShow: 'closed' });
      }
    });
  });

  return agGridHeaderData;
};

/**
 * Generates column definitions for the months of a quarter
 * @param monthsOfThisQuarter - Array of month identifiers for the quarter. month in the format 'YYYYMM01'.
 * @param headerFor - The header type (e.g., 'Actual', 'Forecast')
 * @param isEditable - Boolean indicating if the column is editable
 * @param userAlias - The alias of the current user
 * @param isAdmin - Boolean indicating if the user is an admin
 * @returns ColDef[] - Array of column definitions for the months
 */
export const generateMonthColumnDefinition = (
  monthsOfThisQuarter: string[],
  headerFor: string,
  isEditable: boolean,
  userAlias: string,
  isAdmin: boolean
): ColDef<any, any>[] => {
  return monthsOfThisQuarter?.map((monthInQuarter) => {
    return {
      editable: (editParams: EditableCallbackParams<any, any>) =>
        headerFor === HEADER_TYPES.ACTUAL ? false : isEditable && isUserAuthorizedToEditThisRow(editParams.data, userAlias, isAdmin),
      field: monthInQuarter,
      headerName: convertMonthFormatToDisplay(monthInQuarter),
      wrapHeaderText: true,
      width: ForecastGridConstants.ColumnWidths.MONTH_COLUMN,
      suppressMovable: true,
      lockPinned: headerFor === HEADER_TYPES.ACTUAL,
      cellDataType: CellDataType.NUMBER,
      cellClass: (cellClassParams: CellClassParams<any, any>) => {
        const classes = [headerFor === HEADER_TYPES.ACTUAL ? 'actual-month-cell' : 'forecast-month-cell'];
        if (isEditable && isUserAuthorizedToEditThisRow(cellClassParams.data, userAlias, isAdmin)) {
          classes.push('editable-cell');
        }
        return classes;
      },
      cellClassRules: {
        'bold-text': (params: CellClassParams) => params.node.footer
      },
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        showStepperButtons: false,
        preventStepping: true
      } as INumberCellEditorParams,
      valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
      valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
      aggFunc: 'sum',
      columnGroupShow: 'open'
    } as ColDef<any, any>;
  });
};

/**
 * Generates columns for each quarter including monthly and quarterly total columns
 * @param quarterWithMonthlyHeaderInfo - Array to hold the column definitions for the quarter
 * @param quarter - The quarter identifier (e.g., 'Q1', 'Q2')
 * @param monthColumnDefinitions - Array of column definitions for the months within the quarter
 * @param year - The year for the column definitions
 * @param headerFor - The header type (e.g., HEADER_TYPES.ACTUAL, 'Forecast')
 */
export const generateMonthsWithQuarterColumnDefinition = (
  quarterWithMonthlyHeaderInfo: ColDef<any, any>[],
  quarter: string,
  monthColumnDefinitions: ColDef<any, any>[],
  year: string,
  headerFor: string
): void => {
  // Add the monthly columns first
  quarterWithMonthlyHeaderInfo.push(...monthColumnDefinitions);

  // Define the quarterly total column
  const quarterlyTotalColumn: ColDef<any, any> = {
    editable: false, // Total columns are not editable
    field: `${year}-${quarter}-${headerFor}-Total`,
    headerName: `${quarter}-${year}`,
    wrapHeaderText: true,
    width: ForecastGridConstants.ColumnWidths.QUARTER_COLUMN,
    suppressMovable: true,
    lockPinned: headerFor === HEADER_TYPES.ACTUAL,
    cellClass: headerFor === HEADER_TYPES.ACTUAL ? ['actual-quarter-cell'] : ['forecast-quarter-cell'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false
    },
    cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    valueGetter: (params: ValueGetterParams) => sumUpColumns(params, monthColumnDefinitions),
    aggFunc: 'sum',
    columnGroupShow: 'open'
  };

  // Add the quarterly total column for the 'open' state
  quarterWithMonthlyHeaderInfo.push({
    ...quarterlyTotalColumn,
    columnGroupShow: 'open'
  });

  // Add the quarterly total column for the 'closed' state (if required in future)
  // quarterWithMonthlyHeaderInfo.push({
  //   ...quarterlyTotalColumn,
  //   columnGroupShow: 'closed'
  // });
};

/**
 * Generates column definitions for a given year including quarterly and yearly total columns
 * @param year - The year for which the column definitions are generated
 * @param quarterWithMonthlyHeaderInfo - Array of quarter with monthly header information
 * @param headerFor - The header type (e.g., 'Actual', 'Forecast')
 * @returns ColDef - The column definition for the given year
 */
export const generateYearColumnDefinitions = (
  year: string,
  quarterWithMonthlyHeaderInfo: ColDef<any, any>[],
  headerFor: string
): ColDef<any, any> => {
  // Define the yearly total column
  const yearlyTotalColumn: ColDef = {
    editable: false, // Total columns are not editable
    headerName: `${year} Total`,
    wrapHeaderText: true,
    width: ForecastGridConstants.ColumnWidths.YEAR_COLUMN,
    field: `${year}-${headerFor}-YearlyTotal`,
    suppressMovable: true,
    lockPinned: headerFor === HEADER_TYPES.ACTUAL,
    marryChildren: true,
    cellClass: headerFor === HEADER_TYPES.ACTUAL ? ['actual-year-cell'] : ['forecast-year-cell'],
    cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer
    },
    valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    valueGetter: (params: ValueGetterParams) => sumUpColumns(params, quarterWithMonthlyHeaderInfo),
    aggFunc: 'sum'
  } as ColDef;

  // Create the year column definition including the quarterly and yearly total columns
  return {
    headerName: `${year}`,
    marryChildren: true,
    children: [
      ...quarterWithMonthlyHeaderInfo,
      {
        ...yearlyTotalColumn,
        columnGroupShow: 'open'
      },
      {
        ...yearlyTotalColumn,
        columnGroupShow: 'closed'
      }
    ]
  } as ColDef<any, any>;
};

/**
 * Maps a BusinessSegmentDataType to the corresponding Ag-Grid editor type.
 * @param segmentDataType - The business segment data type.
 * @returns The corresponding Ag-Grid editor type.
 */
export const segmentDataTypeMatchWithAgGridEditorType = (segmentDataType: BusinessSegmentDataType): AgGridEditorType => {
  switch (segmentDataType) {
    case 'text':
      return AgGridEditorType.TEXT;
    case 'number':
      return AgGridEditorType.NUMERIC;
    case 'dropdown':
      return AgGridEditorType.RICH_SELECT;
    default:
      return AgGridEditorType.TEXT;
  }
};

/**
 * Calculates the sum of all numeric values from columns within each quarter that do not end in 'total'.
 * This function is designed to aggregate numeric data across multiple months within a year,
 * skipping any aggregated total columns.
 * It returns null if no numeric data is found; otherwise, it returns the sum.
 *
 * @param {ValueGetterParams} params - Contains data for the current row in the grid.
 * @param {ColDef[]} quarterWithMonthlyHeaderInfo - An array of column definitions, each representing a quarter, containing child columns for each month.
 * @returns {number|null} The sum of all numeric monthly values within the year, or null if there are no numbers.
 */
export const sumUpYearlyColumns = (params: ValueGetterParams, quarterWithMonthlyHeaderInfo: ColDef[]): number | null => {
  try {
    let sum = 0;
    let containsNumberOrZero = false; // Flag to track if any number or zero has been added to the sum.

    quarterWithMonthlyHeaderInfo.forEach((quarterMonth: any) => {
      quarterMonth.children?.forEach((monthly: any) => {
        if (monthly.field && !monthly.field.toLowerCase().endsWith('total')) {
          // Skip 'total' columns
          const value = params.data[monthly.field];
          if (value !== null && value !== undefined) {
            if (value === '0' || !isNaN(Number(value))) {
              containsNumberOrZero = true;
              sum += Number(value); // Convert to Number to ensure correct arithmetic operation.
            }
          }
        }
      });
    });

    return containsNumberOrZero ? sum : null; // Return the calculated sum or null if no valid numbers were processed.
  } catch (error: any) {
    return null;
  }
};

/**
 * Sums up numeric values from specified columns in a row of data.
 * If there are no numeric values or the data is absent, returns null.
 * This function considers zero as a valid number contributing to the sum.
 *
 * @param {ValueGetterParams} params - Parameters containing the row data.
 * @param {ColDef[]} columnDefinitions - Definitions of columns to sum up.
 * @returns {number | null} The sum of the column values, or null if no valid numbers are found.
 */
export const sumUpColumns = (params: ValueGetterParams, columnDefinitions: ColDef<any, any>[]): number | null => {
  try {
    // Check if params.data is available
    if (!params.data) {
      return null;
    }

    let sum = 0;
    let containsNumberOrZero = false; // Flag to track if any number or zero has been added to the sum.

    columnDefinitions.forEach((column: any) => {
      if (column.field && !column.field.toLowerCase().endsWith('total')) {
        const value = params.data[column.field];

        // Only process values that are not null or undefined
        if (value !== null && value !== undefined) {
          if (value === '0' || !isNaN(Number(value))) {
            containsNumberOrZero = true;
            sum += Number(value); // Convert to number to ensure correct addition
          }
        }
      }
    });

    // Return sum if any valid number or zero is present, otherwise return null
    return containsNumberOrZero ? sum : null;
  } catch (error) {
    return null;
  }
};

/**
 * Determines if the current user, identified by 'userAlias', has authorization to edit the row.
 *
 * @param {any} rowData - The data of the row to check.
 * @param {string} userAlias - The alias of the current user.
 * @param {boolean} isAdmin - Flag indicating if the current user is an Admin.
 * @returns {boolean} - Returns true if the user is authorized (either the Budget Owner or an Admin), otherwise returns false.
 */
export const isUserAuthorizedToEditThisRow = (rowData: any, userAlias: string, isAdmin: boolean): boolean => {
  try {
    return rowData[ForecastGridConstants.ForecastGridFixedFields.BudgetOwner.value] === userAlias || isAdmin;
  } catch (error: any) {
    // If rowData is not available or undefined, assume the user is not authorized.
    return false;
  }
};

export const isNewFERow = (rowData: any): boolean => {
  try {
    return rowData[ForecastGridConstants.ForecastGridFixedFields.IsNewFERow.value];
  } catch (error: any) {
    return false;
  }
};

export const isNewRow = (rowData: any): boolean => {
  try {
    return rowData[ForecastGridConstants.ForecastGridFixedFields.IsNew.value];
  } catch (error: any) {
    return false;
  }
};

export const isBusinessSegmentEditable = (data: any, userAlias: string, isAdminUser: boolean, isIdField: boolean) => {
  const isUseOwnsRecord = isUserAuthorizedToEditThisRow(data, userAlias, isAdminUser);
  const isEditable = isNewFERow(data) || isNewRow(data) ? isUseOwnsRecord : !isIdField && isUseOwnsRecord;
  return isEditable;
};

export const isCorpSegmentEditable = (data: any, userAlias: string, isAdminUser: boolean) => {
  return (isNewFERow(data) || isNewRow(data)) && isUserAuthorizedToEditThisRow(data, userAlias, isAdminUser);
};
