import { createSelector } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { ExpenseTypeEntity } from 'src/models/xPTMappingModels';
import { RootState } from '../store';

/**
 * Selector to get expense type mappings from the state.
 */
const selectExpenseTypeMappings = (state: RootState) => state.xPTMappingStore.expenseTypeMappings;

/**
 * Selector to get the current business group from the state.
 */
const selectCurrentBusinessGroup = (state: RootState) => state.businessGroupStore.currentBusinessGroup;

/**
 * Utility function to get expense types from an array of ExpenseTypeEntity.
 * Adds "N/A" to the beginning of the list if it's not already included.
 */
const getExpenseTypes = (expenseTypeMappings: ExpenseTypeEntity[]) => {
  const expenseTypes = expenseTypeMappings.map((et) => et.expense_type);

  if (!expenseTypes.includes('N/A')) {
    expenseTypes.unshift('N/A');
  }

  return expenseTypes;
};

/**
 * Selector to filter expense types based on the current business group's data classification.
 */
export const selectExpenseTypesForCurrentGroup = createSelector(
  [selectCurrentBusinessGroup, selectExpenseTypeMappings],
  (currentBusinessGroup, expenseTypeMappings) => {
    if (!currentBusinessGroup) return [];

    const classificationId = currentBusinessGroup.data_classification.data_classification_id;

    const filteredExpenseTypes = expenseTypeMappings.filter((expenseType) =>
      expenseType.group_info.some((groupInfo) => groupInfo.data_classification_id === classificationId && groupInfo.group_value === true)
    );

    logger.info(
      `Filtered ${filteredExpenseTypes.length} expense types out of ${expenseTypeMappings.length} based on business group's data classification: ${currentBusinessGroup.data_classification.data_classification_short_description}.`
    );

    return getExpenseTypes(filteredExpenseTypes);
  }
);

/**
 * Selector to get all unique expense types from the state.
 */
export const selectAllExpenseTypes = createSelector([selectExpenseTypeMappings], getExpenseTypes);

/**
 * Selector to get all unique expense types with CAPEX budget type from the state.
 */
export const selectAllExpenseTypesWithCAPEX = createSelector([selectExpenseTypeMappings], (expenseTypeMappings) =>
  getExpenseTypes(expenseTypeMappings.filter((expenseType) => expenseType.budget_type === 'CAPEX'))
);
