import { SelectProps } from '@amzn/awsui-components-react';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity, DropdownModel } from 'src/models/AppContextModels';
import { AdminPlanningCycleBusinessGroupsForm, AdminPlanningCycleForm, NonAdminPlanningCycleForm } from 'src/models/PlanningCycleFormModel';
import { PlanningCycle, PlanningCycleFlatTable } from 'src/models/PlanningCycleModel';
import {
  convertPacificTimeToUTC,
  convertsUTCtoUserLocalAndReturnsReadableFormat,
  convertToUserLocalAndSplitDateTime,
  getCurrentUTCTimeInISO,
  getReadableFormatOfMonthDateYear,
  getReadableFormatOfMonthYear,
  getYearFromDate,
  getYearWithOffset,
  isDateInThePast
} from 'src/utils/date-time-utilities';
import { compareNullableNumbers } from 'src/utils/generic-utilities';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { getBusinessGroupByName } from '../business-group/BusinessGroupUtils';

export enum ReportingFilterTags {
  PLANNING_CYCLE = `Planning Cycle`,
  SNAPSHOT = `Snapshot`
}

/**
 * Transforms a PlanningCycle object into a selection option format suitable for dropdown menus.
 *
 * @param {PlanningCycle | null | undefined} cycle - The PlanningCycle object to transform.
 * @returns {SelectProps.Option} An object representing a dropdown option with label and value properties.
 */
export const toPlanningCycleSelectOption = (cycle: PlanningCycle | null | undefined): SelectProps.Option => {
  const defaultLabel = 'Select a cycle';
  return {
    label: cycle?.scenario.scenario_name || defaultLabel,
    value: cycle ? String(cycle.scenario_seq_id) : '',
    description: cycle?.scenario.planning_cycle_name || ''
  };
};

/**
 *
 * @param {SelectProps.Option | null | undefined} option - A dropdown option
 * @param {PlanningCycle[]} cycles - Planning Cycles
 * @returns {PlanningCycle} Planning Cycle from selected Dropdown
 */
export const fromPlanningCycleSelectOption = (option: SelectProps.Option | null | undefined, cycles: PlanningCycle[]): PlanningCycle =>
  cycles.find((cycle) => String(cycle.scenario_seq_id) === option?.value)!;

/**
 * Converts "PlanningCycle" to "AdminPlanningCycleForm"
 * @param planningCycles[] Planning Cycles
 * @returns AdminPlanningCycleForm
 */
export const convertToAdminPlanningCycleForm = (planningCycles: PlanningCycle[]): AdminPlanningCycleForm => {
  const scenario: DropdownModel = {
    label: planningCycles[0]?.scenario.scenario_name,
    value: `${planningCycles[0]?.scenario.scenario_id}`
  };

  const planningCycleDropdown: DropdownModel = {
    label: planningCycles[0]?.scenario.planning_cycle_name,
    value: `${planningCycles[0]?.scenario.planning_cycle_name}`
  };

  const business_groups: AdminPlanningCycleBusinessGroupsForm[] = planningCycles.map((planningCycle) => ({
    scenario_seq_id: planningCycle?.scenario_seq_id,

    data_classification: {
      label: planningCycle.data_classification.data_classification_name,
      value: `${planningCycle.data_classification.data_classification_id}`
    },

    baseline_scenario: {
      label: `${planningCycle.baseline_scenario?.baseline_scenario_name || ''}`, // Handle historical data where baseline_scenario_name might be null by using an empty string as fallback
      value: `${planningCycle.baseline_scenario?.baseline_scenario_seq_id || ''}`
    },

    actuals_date_range: {
      type: 'absolute',
      startDate: planningCycle.actuals_start_month_id,
      endDate: planningCycle.actuals_end_month_id
    },

    forecast_date_range: {
      type: 'absolute',
      startDate: planningCycle.forecast_start_month_id,
      endDate: planningCycle.forecast_end_month_id
    },

    planning_cycle_date_time_range: {
      type: 'absolute',
      startDate: planningCycle.planning_cycle_open_date,
      endDate: planningCycle.planning_cycle_lock_date
    },

    forecast_data_available: planningCycle.forecast_data_available,

    planning_cycle_status: isDateInThePast(planningCycle.planning_cycle_lock_date) ? 'Locked' : 'Unlocked',

    budget_owner_lock_date: planningCycle.budget_owner_lock_date,

    is_active: planningCycle.is_active,
    created_at: planningCycle.created_at,
    created_by: planningCycle.created_by,
    updated_at: planningCycle.updated_at,
    updated_by: planningCycle.updated_by
  }));

  return {
    scenario,
    scenario_year: planningCycles[0]?.scenario_year,
    planning_cycle_name: planningCycleDropdown,
    business_groups
  };
};

/**
 * Converts "AdminPlanningCycleForm" to "PlanningCycle"
 * note: scenario_year is a custom field that AdminPlanningCycleForm has. Do not include while submitting
 * @param form AdminPlanningCycleForm
 * @param userAlias userAlias
 * @param businessGroups BusinessGroupEntity[]
 * @returns PlanningCycle
 */
export const convertToPlanningCycle = (form: AdminPlanningCycleForm, userAlias: string, businessGroups: BusinessGroupEntity[]) => {
  const parsedPlanningCycle = form.business_groups.map((businessGroupForm) => {
    const _businessGroups: BusinessGroupEntity | undefined = getBusinessGroupByName(businessGroupForm.data_classification.label, businessGroups);
    const formAction = businessGroupForm.scenario_seq_id ? 'Edit' : 'Create'; // To Determine if this group is being added or edited based on the scenario_seq_id

    if (_businessGroups?.data_classification) {
      const baselineScenarioSeqId = businessGroupForm.baseline_scenario.value ? Number(businessGroupForm.baseline_scenario.value) || null : null;
      return {
        scenario_seq_id: businessGroupForm.scenario_seq_id,
        planning_cycle_year: getYearFromDate(businessGroupForm.forecast_date_range.startDate),

        scenario: {
          scenario_id: +form.scenario.value,
          scenario_name: form.scenario.label,
          planning_cycle_name: form.planning_cycle_name.label
        },

        baseline_scenario: {
          baseline_scenario_name: businessGroupForm.baseline_scenario.label || '',
          baseline_scenario_seq_id: baselineScenarioSeqId
        },
        data_classification: _businessGroups.data_classification,

        actuals_start_month_id: businessGroupForm.actuals_date_range.startDate,
        actuals_end_month_id: businessGroupForm.actuals_date_range.endDate,

        forecast_start_month_id: businessGroupForm.forecast_date_range.startDate,
        forecast_end_month_id: businessGroupForm.forecast_date_range.endDate,

        forecast_data_available: businessGroupForm.forecast_data_available,
        export_s3_bucket: getForecastS3BucketName().bucketName,

        planning_cycle_open_date: convertPacificTimeToUTC(businessGroupForm.planning_cycle_date_time_range.startDate),
        budget_owner_lock_date: businessGroupForm.budget_owner_lock_date ? convertPacificTimeToUTC(businessGroupForm.budget_owner_lock_date) : null,
        planning_cycle_lock_date: convertPacificTimeToUTC(businessGroupForm.planning_cycle_date_time_range.endDate),

        is_active: businessGroupForm.is_active,
        created_at: formAction === 'Create' ? getCurrentUTCTimeInISO() : businessGroupForm.created_at,
        created_by: formAction === 'Create' ? userAlias : businessGroupForm.created_by,
        updated_at: getCurrentUTCTimeInISO(),
        updated_by: userAlias
      } as PlanningCycle;
    } else {
      logger.error(`Business group not found for: ${businessGroupForm.data_classification.label}`);
      return null;
    }
  });

  return parsedPlanningCycle?.filter((cycle) => cycle !== null) as PlanningCycle[];
};

/**
 * Converting Planning Cycle to Non Admin (BL) Planning Cycle form
 * @param planningCycle
 * @returns NonAdminPlanningCycleForm
 */
export const convertPlanningCycleAPIToNonAdminPlanningCycleForm = (planningCycle: PlanningCycle): NonAdminPlanningCycleForm => {
  return {
    scenario_seq_id: planningCycle.scenario_seq_id,
    scenario_year: planningCycle.scenario_year,
    planning_cycle_date_time_range: {
      type: 'absolute',
      startDate: planningCycle.planning_cycle_open_date,
      endDate: planningCycle.planning_cycle_lock_date
    },
    datePickerValue: planningCycle.budget_owner_lock_date ? convertToUserLocalAndSplitDateTime(planningCycle.budget_owner_lock_date).date : '',
    timePickerValue: planningCycle.budget_owner_lock_date ? convertToUserLocalAndSplitDateTime(planningCycle.budget_owner_lock_date).time : '',
    budget_owner_lock_date: planningCycle.budget_owner_lock_date || '',
    last_updated_at: planningCycle.updated_at,
    last_updated_by: planningCycle.updated_by,

    is_prophecy_enabled: planningCycle.is_prophecy_enabled,
    prophecy_created_at: planningCycle.prophecy_created_at,
    prophecy_created_by: planningCycle.prophecy_created_by,
    last_synced_to_prophecy: planningCycle.last_synced_to_prophecy
  };
};

/**
 * Converting BL Planning Cycle form to Planning Cycle.
 * Also, scenario_year is removing as it belongs to only frontend. It is not part of the API
 * @param planningCycle
 * @returns NonAdminPlanningCycleForm
 */
export const convertNonAdminPlanningCycleFormToAPI = (
  currentPlanningCycle: PlanningCycle,
  planningCycleForm: NonAdminPlanningCycleForm,
  userAlias: string
): PlanningCycle => {
  // Destructure scenario_year out and capture the rest of the properties
  const { scenario_year, ...restCurrentPlanningCycle } = currentPlanningCycle;

  let prophecy_created_at = null;
  let prophecy_created_by = null;

  if (currentPlanningCycle.is_prophecy_enabled !== planningCycleForm.is_prophecy_enabled) {
    prophecy_created_at = getCurrentUTCTimeInISO();
    prophecy_created_by = userAlias;
  } else {
    prophecy_created_at = currentPlanningCycle.prophecy_created_at;
    prophecy_created_by = currentPlanningCycle.prophecy_created_by;
  }

  return {
    ...restCurrentPlanningCycle,
    is_prophecy_enabled: planningCycleForm.is_prophecy_enabled,
    prophecy_created_at: prophecy_created_at,
    prophecy_created_by: prophecy_created_by,

    last_synced_to_prophecy: planningCycleForm.last_synced_to_prophecy,
    export_s3_bucket: getForecastS3BucketName().bucketName,
    budget_owner_lock_date: convertPacificTimeToUTC(planningCycleForm.budget_owner_lock_date),
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias
  } as PlanningCycle;
};

/**
 * Converts PlanningCycle to PlanningCycleFlatTable for Widgets & other table with default Property Filtering
 */
export const convertPlanningsToFlat = (planningCycles: PlanningCycle[]): PlanningCycleFlatTable[] => {
  return planningCycles.map((cycle) => {
    const planningCycleWindow = `${getReadableFormatOfMonthDateYear(cycle.planning_cycle_open_date)} 
      - 
      ${convertsUTCtoUserLocalAndReturnsReadableFormat(cycle.planning_cycle_lock_date)}`;

    return {
      scenario_seq_id: cycle.scenario_seq_id,
      scenario_name: cycle.scenario.scenario_name,
      scenario_year: cycle.scenario_year,
      data_classification_id: cycle.data_classification.data_classification_id,
      data_classification_name: cycle.data_classification.data_classification_name,
      actuals_date_range: getActualsDateRange(cycle),
      forecast_date_range: getForecastDateRange(cycle),
      budget_owner_lock_date: cycle.budget_owner_lock_date ? convertsUTCtoUserLocalAndReturnsReadableFormat(cycle.budget_owner_lock_date) : null,
      planning_cycle_window: planningCycleWindow,
      planning_cycle_bo_lock_status: cycle.budget_owner_lock_date ? (isDateInThePast(cycle.budget_owner_lock_date) ? 'Locked' : 'Unlocked') : null,
      planning_cycle_lock_status: isDateInThePast(cycle.planning_cycle_lock_date) ? 'Locked' : 'Unlocked',
      is_active: cycle.is_active,
      created_at: cycle.created_at,
      created_by: cycle.created_by,
      updated_at: cycle.updated_at,
      updated_by: cycle.updated_by
    };
  });
};

export const getActualsDateRange = (cycle: PlanningCycle): string => {
  return `${getReadableFormatOfMonthYear(cycle.actuals_start_month_id)} - ${getReadableFormatOfMonthYear(cycle.actuals_end_month_id)}`;
};

export const getForecastDateRange = (cycle: PlanningCycle): string => {
  return `${getReadableFormatOfMonthYear(cycle.forecast_start_month_id)} - ${getReadableFormatOfMonthYear(cycle.forecast_end_month_id)}`;
};

export const isValidScenarioYear = (scenarioYear: string, planningCycles: PlanningCycle[]) => {
  return planningCycles.some((planningCycle) => planningCycle.scenario_year === scenarioYear);
};

// Filters planning cycles by scenario year, aiding in narrowing down cycles for a given year.
export const selectPlanningCyclesByScenarioYear = (scenarioYear: string, planningCycles: PlanningCycle[]) => {
  return planningCycles.filter((planningCycle) => planningCycle.scenario_year === scenarioYear);
};

// Factory selector for finding a planning cycle based on scenario year and data classification name.
export const selectPlanningCycleByScenarioYearAndDataClassification = (
  scenarioYear: string,
  dataClassificationName: string,
  planningCycles: PlanningCycle[]
) => {
  return planningCycles.find(
    (cycle) => cycle.scenario_year === scenarioYear && cycle.data_classification.data_classification_name === dataClassificationName
  );
};

// Selector for filtering planning cycles by data classification ID.
export const selectPlanningCyclesByDataClassificationId = (dataClassificationId: number, activePlanningCycles: PlanningCycle[]) => {
  return activePlanningCycles.filter((cycle) => cycle.data_classification.data_classification_id === dataClassificationId);
};

export const findPlanningCyclesByScenarioSeqId = (scenarioSeqId: number, activePlanningCycles: PlanningCycle[]) => {
  return activePlanningCycles.find((cycle) => cycle.scenario_seq_id === scenarioSeqId);
};

// Selector for filtering planning cycles by data classification Name.
export const selectPlanningCyclesByDataClassificationName = (dataClassificationName: string, planningCycles: PlanningCycle[]) => {
  return planningCycles.filter((cycle) => cycle.data_classification.data_classification_name === dataClassificationName);
};

export const atLeastOnePlanningCycleIsActive = (dataClassificationId: number, activePlanningCycles: PlanningCycle[]): boolean => {
  return activePlanningCycles.some((cycle) => cycle.data_classification.data_classification_id === dataClassificationId);
};

/**
 * Filters and returns the latest planning cycles for the given business group name,
 * limited to those from the current and previous year.
 *
 * @param groupName - The name of the business group.
 * @param allPlanningCycles - Array of all planning cycles.
 * @returns An array of options for the Select component, each containing the scenario year and sequence ID.
 */
export const getBaseLineScenarioOptions = (groupName: string, allPlanningCycles: PlanningCycle[]): SelectProps.Option[] => {
  const currentYear = getYearWithOffset();
  const previousYear = getYearWithOffset(-1);

  return allPlanningCycles
    .filter(
      ({ data_classification, planning_cycle_year }) =>
        data_classification.data_classification_name === groupName && (planning_cycle_year === currentYear || planning_cycle_year === previousYear)
    )
    .sort((a, b) => compareNullableNumbers(a.scenario_seq_id, b.scenario_seq_id, 'desc'))
    .map(({ scenario_seq_id, scenario_year }) => ({
      label: `${scenario_year}`,
      value: `${scenario_seq_id}`
    }));
};
