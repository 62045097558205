import { Box, Button, Header, KeyValuePairs, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { logger } from 'src/analytics/KatalLogger';
import { getAnnouncements } from 'src/api/app-sync-services';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptWidgetTable from 'src/components/common/xpt-table/XptWidgetTable';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { Announcement } from 'src/models/AppContextModels';
import { dateTimeComparator, formatDateString } from 'src/utils/date-time-utilities';
import { WidgetContainer } from '../WidgetContainer';
import { AnnouncementsForm } from './AnnouncementForm';
import { ANNOUNCEMENT_DATE_DISPLAY_FORMAT, ANNOUNCEMENT_DATE_INITIAL_FORMAT, announcementsHeaderConfig } from './AnnouncementUtils';

interface AnnouncementWidgetProps {
  dataClassificationId?: number;
}

const AnnouncementWidget: React.FC<AnnouncementWidgetProps> = ({ dataClassificationId }) => {
  const headerText = 'Announcements';
  const userAuthDetails = useAuth();
  const enableEditButton = userAuthDetails.isAdmin || userAuthDetails.isDev;

  const quillRef = useRef<ReactQuill | null>(null);

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<Announcement | null>(null);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<number | null>(null);

  useEffect(() => {
    loadAnnouncements();
  }, [dataClassificationId]);

  const loadAnnouncements = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchAnnouncement = await getAnnouncements();
      const sortedAnnouncements = fetchAnnouncement.sort((a, b) => dateTimeComparator(a.announcement_date, b.announcement_date, false));
      setAnnouncements(sortedAnnouncements);
    } catch (err: any) {
      setAnnouncements([]);
      setError('Failed to load announcements');
      logger.error('Failed to load announcements widget data', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelCreateAnnouncement = () => {
    setShowModal(false);
    setSelectedAnnouncementId(null);
  };

  const onConfirmingCreateAnnouncement = () => {
    loadAnnouncements();
    setShowModal(false);
    setSelectedAnnouncementId(null);
  };

  const widgetHeader = () => {
    return (
      <Header
        variant="h3"
        actions={
          <SpaceBetween size="s" direction="horizontal">
            {enableEditButton && (
              <Button iconName="add-plus" onClick={() => setShowModal(true)}>
                {'New'}
              </Button>
            )}
          </SpaceBetween>
        }
      >
        {headerText}
      </Header>
    );
  };

  return (
    <>
      <AnnouncementsForm
        showModal={showModal}
        onCancel={onCancelCreateAnnouncement}
        onConfirm={onConfirmingCreateAnnouncement}
        announcements={announcements}
        selectedAnnouncementId={selectedAnnouncementId}
      />
      <WidgetContainer header={widgetHeader()}>
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <StatusIndicator type="error">{error}</StatusIndicator>
        ) : (
          <>
            <XptWidgetTable
              variant="embedded"
              loadingStatus={false}
              allItems={announcements}
              columnDefinitions={announcementsHeaderConfig(setShowModal, setSelectedAnnouncementId, setModalContent, enableEditButton)}
              hasPagination={false}
              itemsPerPage={5}
            />
            <Modal onDismiss={() => setModalContent(null)} visible={!!modalContent} size="large" header={modalContent?.announcement_title || ''}>
              <Box padding={{ top: 'm', bottom: 'm' }}>
                <SpaceBetween size="m" direction="vertical">
                  {modalContent && (
                    <SpaceBetween size="m" direction="vertical">
                      <Box>
                        <SpaceBetween size="m" direction="horizontal">
                          <KeyValuePairs
                            columns={3}
                            items={[
                              {
                                label: 'Business group',
                                value: modalContent.data_classification.map((dc) => dc.data_classification_name).join(', ')
                              },
                              {
                                label: 'Published date',
                                value: formatDateString(
                                  modalContent.announcement_date,
                                  ANNOUNCEMENT_DATE_INITIAL_FORMAT,
                                  ANNOUNCEMENT_DATE_DISPLAY_FORMAT
                                )
                              },
                              { label: 'Author', value: modalContent.item_metadata.updated_by }
                            ]}
                          />
                        </SpaceBetween>
                      </Box>
                      <Box>
                        <ReactQuill
                          ref={quillRef}
                          value={modalContent.announcement_message || ''}
                          readOnly={true}
                          theme="snow"
                          modules={{ toolbar: false }}
                        />
                      </Box>
                    </SpaceBetween>
                  )}
                </SpaceBetween>
              </Box>
            </Modal>
          </>
        )}
      </WidgetContainer>
    </>
  );
};

export default AnnouncementWidget;
