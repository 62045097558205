import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  DatePicker,
  Flashbar,
  Form,
  FormField,
  Header,
  Popover,
  SpaceBetween,
  SplitPanel,
  TimeInput,
  Toggle
} from '@amzn/awsui-components-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logger } from 'src/analytics/KatalLogger';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import ValueWithLabel from 'src/components/common/ValueWithLabel';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { splitPanelI18nStrings } from 'src/i18n-strings';
import { PlanningCycle, PlanningCycleFlatTable } from 'src/models/PlanningCycleModel';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { convertsUTCtoUserLocalAndReturnsReadableFormat, getElapsedTimeDetails, isGivenDateTimeIsValid } from 'src/utils/date-time-utilities';
import { v4 as uuidv4 } from 'uuid';
import { NonAdminPlanningCycleForm } from '../../models/PlanningCycleFormModel';
import { NonAdminPlanningCycleFormSchema } from './PlanningCycleFormSchema';
import { selectAllPlanningCyclesForCurrentGroup } from './planningCycleSelector';
import { submitPlanningCycle } from './planningCycleSlice';
import { convertNonAdminPlanningCycleFormToAPI, convertPlanningCycleAPIToNonAdminPlanningCycleForm } from './planningCycleUtils';

const NON_ADMIN_PLANNING_CYCLE_INITIAL_FORM: NonAdminPlanningCycleForm = {
  scenario_seq_id: null,
  scenario_year: '',
  planning_cycle_date_time_range: { type: 'absolute', startDate: '', endDate: '' },
  budget_owner_lock_date: '',
  datePickerValue: '',
  timePickerValue: '',
  last_updated_at: '',
  last_updated_by: '',

  is_prophecy_enabled: null,
  prophecy_created_at: '',
  prophecy_created_by: '',
  last_synced_to_prophecy: ''
};

interface PlanningCycleManageFormNonAdminProps {
  selectedPlanningCycle?: PlanningCycleFlatTable;
  reloadPlanningCycles: () => void;
}

const PlanningCycleManageFormNonAdmin: React.FC<PlanningCycleManageFormNonAdminProps> = ({ selectedPlanningCycle, reloadPlanningCycles }) => {
  const navigate = useNavigate();
  const userAuth = useAuth();
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  const dispatch = useAppDispatch();
  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const businessGroupName = currentBusinessGroup?.data_classification.data_classification_name;
  const relevantPlanningCycles = useSelector(selectAllPlanningCyclesForCurrentGroup);

  const [formInitializing, setFormInitializing] = useState<boolean>(true);
  const planningCycleFormRef = useRef<FormikProps<NonAdminPlanningCycleForm>>(null);
  const [planningCycle, setPlanningCycle] = useState<PlanningCycle>();
  const [planningCycleForm, setPlanningCycleForm] = useState<NonAdminPlanningCycleForm>(NON_ADMIN_PLANNING_CYCLE_INITIAL_FORM);

  const closedPlanningCycle = !selectedPlanningCycle?.is_active;

  useEffect(() => {
    if (businessGroupName && selectedPlanningCycle) handleFormInitialization();
  }, [businessGroupName, selectedPlanningCycle]);

  const handleFormInitialization = () => {
    if (selectedPlanningCycle) {
      setFormInitializing(true);

      // Find the planning cycle entity based on the selected planning cycle
      const planningCycleEntity = relevantPlanningCycles.find((cycle) => cycle.scenario_seq_id === selectedPlanningCycle.scenario_seq_id)!;
      setPlanningCycle(planningCycleEntity);

      // Convert the planning cycle entity to the form model
      const newFormValues = convertPlanningCycleAPIToNonAdminPlanningCycleForm(planningCycleEntity);
      setPlanningCycleForm(newFormValues);

      // Reset and reinitialize the form with the new values
      planningCycleFormRef.current?.resetForm({ values: newFormValues });

      setFormInitializing(false);
    }
  };

  const handleDateTimeChange = (datePickerValue: string, timePickerValue: string) => {
    planningCycleFormRef?.current?.setFieldValue('datePickerValue', datePickerValue);
    planningCycleFormRef?.current?.setFieldValue('timePickerValue', timePickerValue);
    const dateTimeString = `${datePickerValue}T${timePickerValue}:00`;
    if (isGivenDateTimeIsValid(dateTimeString)) {
      planningCycleFormRef?.current?.setFieldValue('budget_owner_lock_date', dateTimeString);
    } else {
      planningCycleFormRef?.current?.setFieldValue('budget_owner_lock_date', '');
    }
  };

  const handleSubmit = async (formValues: NonAdminPlanningCycleForm, formikHelpers: FormikHelpers<NonAdminPlanningCycleForm>) => {
    const isFormValid = planningCycleFormRef.current?.isValid;
    if (!isFormValid) {
      return;
    }

    formikHelpers.setSubmitting(true);
    const inProgressMessageId = uuidv4();
    displayFlashMessage('Request in progress', 'in-progress', false, inProgressMessageId);

    if (planningCycle) {
      const mutationObject = convertNonAdminPlanningCycleFormToAPI(planningCycle, formValues, userAuth.Alias);
      try {
        await dispatch(submitPlanningCycle([mutationObject])).unwrap();
        reloadPlanningCycles();
        const successMessage = `Successfully updated ${formValues?.scenario_year}`;
        logger.info(successMessage);
        displayFlashMessage(successMessage, 'success', true);
        navigate(`/${businessGroupName}/planning-cycle`);
      } catch (error: any) {
        const errorMessage = `Unable to updated ${formValues?.scenario_year}`;
        logger.error(errorMessage, { error: error });
        displayFlashMessage(errorMessage, 'error', true);
      } finally {
        formikHelpers.setSubmitting(false);
        clearSpecificFlashMessage(inProgressMessageId);
      }
    }
  };

  const getPanelHeader = () => {
    if (planningCycle) {
      return `${planningCycle.scenario.scenario_name} - ${planningCycle.scenario_year}`;
    }

    return 'Edit planning cycle';
  };

  return (
    <SplitPanel header={getPanelHeader()} closeBehavior={'hide'} hidePreferencesButton={true} i18nStrings={splitPanelI18nStrings}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        {formInitializing && <LoadingSpinner />}
        {!formInitializing && (
          <SpaceBetween size="m" direction="vertical">
            {selectedPlanningCycle && closedPlanningCycle && (
              <Alert statusIconAriaLabel="Info" header="Planning cycle closed by admin">
                {`This cycle closed on ${convertsUTCtoUserLocalAndReturnsReadableFormat(selectedPlanningCycle?.updated_at!)}`}
              </Alert>
            )}
            <Flashbar items={flashbarItems} stackItems={true} />
            <Formik<NonAdminPlanningCycleForm>
              innerRef={planningCycleFormRef}
              enableReinitialize={true}
              initialValues={planningCycleForm}
              validationSchema={NonAdminPlanningCycleFormSchema}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={handleSubmit}
            >
              {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
                return (
                  <>
                    <Form
                      header={
                        selectedPlanningCycle?.is_active && (
                          <Header
                            variant="h1"
                            actions={
                              <SpaceBetween size="m" direction="horizontal">
                                <Button variant="primary" onClick={() => handleSubmit()} disabled={isSubmitting || !dirty}>
                                  {'Submit'}
                                </Button>
                              </SpaceBetween>
                            }
                          ></Header>
                        )
                      }
                    >
                      <ColumnLayout columns={2} borders="vertical" variant="text-grid">
                        <SpaceBetween size="m" direction="vertical">
                          <ValueWithLabel label="Planning cycle window">
                            <>{`${convertsUTCtoUserLocalAndReturnsReadableFormat(
                              values.planning_cycle_date_time_range.startDate
                            )} -  ${convertsUTCtoUserLocalAndReturnsReadableFormat(values.planning_cycle_date_time_range.endDate)}`}</>
                          </ValueWithLabel>

                          {closedPlanningCycle ? (
                            <>
                              <ValueWithLabel
                                label="Budget owner lock date"
                                children={
                                  values.budget_owner_lock_date ? convertsUTCtoUserLocalAndReturnsReadableFormat(values.budget_owner_lock_date) : '-'
                                }
                              />
                            </>
                          ) : (
                            <FormField
                              label={`Budget owner lock date`}
                              description={`Budget owners can only select a lock date within the Planning cycle window start and end dates`}
                              constraintText="Enter time in the 24-hour format (hh:mm), based on the America/Los Angeles time zone. "
                              errorText={touched.budget_owner_lock_date && (errors as unknown as NonAdminPlanningCycleForm)?.budget_owner_lock_date}
                            >
                              <SpaceBetween size="s" direction="horizontal">
                                <DatePicker
                                  expandToViewport={true}
                                  onChange={({ detail }) => handleDateTimeChange(detail.value, values.timePickerValue)}
                                  value={values.datePickerValue}
                                  nextMonthAriaLabel="Next month"
                                  previousMonthAriaLabel="Previous month"
                                  todayAriaLabel="Today"
                                  placeholder="YYYY/MM/DD"
                                />
                                <TimeInput
                                  onChange={({ detail }) => handleDateTimeChange(values.datePickerValue, detail.value)}
                                  value={values.timePickerValue}
                                  format="hh:mm"
                                  placeholder="hh:mm"
                                  use24Hour={true}
                                />
                              </SpaceBetween>
                            </FormField>
                          )}
                        </SpaceBetween>

                        <SpaceBetween size="m" direction="vertical">
                          <FormField
                            label={`Push to Prophecy`}
                            description={`If enabled, Prophecy would be able to pull the data from xPT.`}
                            errorText={touched.is_prophecy_enabled && (errors as unknown as NonAdminPlanningCycleForm)?.is_prophecy_enabled}
                          >
                            <Toggle
                              disabled={closedPlanningCycle}
                              onChange={({ detail }) => planningCycleFormRef?.current?.setFieldValue('is_prophecy_enabled', detail.checked)}
                              checked={values.is_prophecy_enabled || false}
                            />
                          </FormField>

                          <ValueWithLabel label="Last modified by" children={values.prophecy_created_by || '-'} />

                          <ValueWithLabel
                            label="Last modified at"
                            children={values.prophecy_created_at ? convertsUTCtoUserLocalAndReturnsReadableFormat(values.prophecy_created_at) : '-'}
                          />

                          <ValueWithLabel
                            label="Last synched with Prophecy"
                            children={
                              values.last_synced_to_prophecy ? (
                                <Popover
                                  dismissButton={false}
                                  position="top"
                                  size="large"
                                  triggerType="text"
                                  content={
                                    <Box color={'text-label'}>
                                      {values.last_synced_to_prophecy
                                        ? `${convertsUTCtoUserLocalAndReturnsReadableFormat(values.last_synced_to_prophecy)}`
                                        : `-`}
                                    </Box>
                                  }
                                >
                                  {getElapsedTimeDetails(values.last_synced_to_prophecy)}
                                </Popover>
                              ) : (
                                '-'
                              )
                            }
                          />
                        </SpaceBetween>
                      </ColumnLayout>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </SpaceBetween>
        )}
      </ErrorBoundary>
    </SplitPanel>
  );
};

export default PlanningCycleManageFormNonAdmin;
