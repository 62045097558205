import { CellEditingStoppedEvent, ProcessCellForExportParams, ProcessDataFromClipboardParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep } from 'lodash';

/**
 * Enum for the Ag-Grid editor types
 */
export enum AgGridEditorType {
  TEXT = 'agTextCellEditor',
  NUMERIC = 'agNumericCellEditor',
  RICH_SELECT = 'agRichSelectCellEditor'
}

/**
 * Enum for the data types of cell values in ag-Grid.
 */
export enum CellDataType {
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATE_STRING = 'dateString',
  OBJECT = 'object'
}

/**
 * Formats a number to USD currency format, with optional formatting for negative numbers.
 * @param value The number to be formatted.
 * @param useParensForNegative If true, formats negative numbers with parentheses instead of a minus sign.
 * @returns The formatted currency string.
 */
export const formatCurrencyUSD = (value: number, useParensForNegative: boolean): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedValue = formatter.format(Math.abs(value));

  if (value < 0) {
    return useParensForNegative ? `(${formattedValue})` : `-${formattedValue}`;
  }

  return formattedValue;
};

/**
 * Custom value formatter for displaying currency in the grid.
 * @param value The value to format.
 * @param useParensForNegative If true, formats negative numbers with parentheses instead of a minus sign.
 * @returns The formatted currency string or "-" if the value is not a number.
 */
export const forecastCurrencyFormatter = (value: any, useParensForNegative: boolean = true): string => {
  // Return a dash for null, undefined, empty string, or NaN values
  if (value === null || value === undefined || value === '' || isNaN(Number(value))) {
    return '-';
  }

  // Format the value if it is a number
  return formatCurrencyUSD(+value, useParensForNegative);
};

/**
 * Custom value formatter for displaying percentage values in the grid.
 * @param value The value to format.
 * @param numDecimalPlaces The number of decimal places to display.
 * @param useParensForNegative Whether to use parentheses for negative values.
 * @returns The formatted percentage string or "-" if the value is not a number.
 */
export const percentFormatter = (value: any, numDecimalPlaces: number = 2, useParensForNegative: boolean = true): string => {
  // Return a dash for null, undefined, empty string, or NaN values
  if (value === null || value === undefined || value === '' || isNaN(Number(value))) {
    return '-';
  }

  // Format the value if it is a number
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: numDecimalPlaces,
    maximumFractionDigits: numDecimalPlaces
  });

  // Since value is already a percentage, format it as a percentage without multiplying by 100 again
  const formattedValue = formatter.format(Math.abs(value)) + '%';

  // Handle negative values appropriately
  if (value < 0) {
    return useParensForNegative ? `(${formattedValue})` : `-${formattedValue}`;
  }

  return formattedValue;
};

/**
 * Custom value formatter for displaying currency in the grid.
 * @param params Parameters provided by Ag-Grid containing the value to format.
 * @returns The formatted currency string or "-" if the value is not a number.
 */
export const poTaggingCurrencyFormatter = (value: any, useParensForNegative: boolean = true): string => {
  if (value === null || value === undefined || value === '') {
    return '-';
  }
  return !isNaN(value) ? formatCurrencyUSD(+value, useParensForNegative) : value.toString();
};

// Define the number of decimal places to round to
export const DECIMAL_PLACES = 2;

/**
 * Rounds a number to a specified number of decimal places.
 * @param value The number to round.
 * @param precision The number of decimal places to round to.
 * @returns The rounded number.
 */
export const roundToPrecision = (value: number, precision = DECIMAL_PLACES): number => {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
};

/**
 * Processes cell values for clipboard operations, ensuring empty strings are treated as null,
 * converting string representations of numbers to actual numbers, and rounding numbers to the configured decimal places.
 * @param value The cell value to process.
 * @returns The processed cell value.
 */
export const cellValueParser = (value: any): any => {
  if (value == null || value === undefined) {
    return null;
  }

  // Check if the value is an empty string
  if (typeof value === 'string' && value.trim() === '') {
    return null;
  }

  // Check if the value is a string that can be converted to a number
  if (typeof value === 'string' && !isNaN(Number(value))) {
    // Convert to a number and round it
    value = roundToPrecision(Number(value), DECIMAL_PLACES);
  }

  // If the value is already a number, round it to the specified decimal places
  if (typeof value === 'number') {
    return roundToPrecision(value, DECIMAL_PLACES);
  }

  return value;
};

/**
 * Parses a given value to an integer.
 *
 * @param {any} params - The parameters containing the new value to be parsed.
 * @param {string} params.newValue - The new value to be parsed as an integer.
 * @returns {number | null} - The parsed integer value, or null if the value is not a valid number.
 */
export const integerValueParser = (params: any): any => {
  const value = parseInt(params.newValue, 10);
  return isNaN(value) ? null : value;
};

/**
 * Processes cell values for copying to the clipboard, applying the same logic as the cellValueParser.
 * @param params Parameters provided by Ag-Grid containing the value to process.
 * @returns The processed cell value.
 */
export const processCellForClipboard = (params: ProcessCellForExportParams): any => {
  return cellValueParser(params.value);
};

/**
 * Processes cell values pasted from the clipboard. This is currently a pass-through function but can
 * be extended to include validation or formatting logic.
 * @param params Parameters provided by Ag-Grid containing the value to process.
 * @returns The original value without modifications.
 */
export const processCellFromClipboard = (params: ProcessCellForExportParams): any => {
  const colDef = params.column.getColDef();

  // Prevent copying cells with agRichSelectCellEditor
  if (colDef.cellEditor === 'agRichSelectCellEditor') {
    return null;
  }

  const cellDataType = colDef.cellDataType || CellDataType.TEXT;

  try {
    switch (cellDataType) {
      case CellDataType.NUMBER:
        const numberValue = +params.value;
        return isNaN(numberValue) ? null : numberValue;
      case CellDataType.BOOLEAN:
        if (params.value.toLowerCase() === 'true') return true;
        if (params.value.toLowerCase() === 'false') return false;
        return null; // Return null for invalid boolean strings
      case CellDataType.DATE:
      case CellDataType.DATE_STRING:
        const dateValue = new Date(params.value);
        return isNaN(dateValue.getTime()) ? null : dateValue;
      case CellDataType.OBJECT:
        try {
          return JSON.parse(params.value);
        } catch (error) {
          return null; // Return null for invalid JSON
        }
      case CellDataType.TEXT:
      default:
        return params.value;
    }
  } catch (error) {
    console.error('Error processing cell value:', error);
    return null;
  }
};

/**
 * Handles the cell editing stopped event to set cell values to null
 * if the new value is an empty string.
 *
 * @param event - The CellEditingStoppedEvent from ag-Grid
 */
export const handleCellEditingStopped = (event: CellEditingStoppedEvent) => {
  if (event.newValue === '' && event.colDef.field) {
    event.data[event.colDef.field] = null;
    event.api.refreshCells({ rowNodes: [event.node], columns: [event.column], force: true });
  }
};

/**
 * Creates a deep clone of the given object to prevent direct mutations.
 * This ensures Ag Grid's data remains immutable, which is crucial for optimal performance.
 *
 * @param object The object to be cloned deeply.
 * @returns A deep clone of the object.
 */
export function deepClone<T>(object: T): T {
  return cloneDeep(object);
}

export const resetAllFilters = (gridRef: React.RefObject<AgGridReact<any>>) => {
  if (!gridRef?.current?.api) {
    return;
  }

  gridRef.current?.api?.setFilterModel(null);
  gridRef.current?.api?.onFilterChanged();
};

// Status bar configuration for AgGrid
export const StatusBarConfig = {
  statusPanels: [
    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
    { statusPanel: 'agFilteredRowCountComponent' },
    { statusPanel: 'agSelectedRowCountComponent' },
    { statusPanel: 'agAggregationComponent' }
  ]
};

/**
 * Adds a new row to the grid.
 * @param gridRef - Reference to the ag-Grid instance.
 * @param newRow - The new row data to be added.
 * @param addIndex - The index at which to add the new row.
 * @returns The transaction result of the add operation.
 */
export const addNewRowToGrid = (gridRef: any, newRow: any, addIndex: number = 0) => {
  if (!gridRef.current) {
    return null;
  }
  return gridRef.current.api.applyTransaction({ add: [newRow], addIndex });
};

/**
 * Ensures the specified row is visible and sets focus to the first displayed cell.
 * @param gridRef - Reference to the ag-Grid instance.
 * @param rowIndex - Index of the row to make visible and focus.
 */
export const ensureRowVisibleAndFocusFirstCell = (gridRef: any, rowIndex: number, columnIndex = 0) => {
  if (gridRef.current) {
    gridRef.current.api.ensureIndexVisible(rowIndex, 'top');

    // Delay setting focus to allow cell rendering
    setTimeout(() => {
      const columns = gridRef.current.api.getAllDisplayedColumns() || [];
      if (columns.length > 0) {
        const firstColumnId = columns[columnIndex].getColId();
        gridRef.current.api.setFocusedCell(rowIndex, firstColumnId);
      }
    }, 100);
  }
};
