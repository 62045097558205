import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Textarea
} from '@amzn/awsui-components-react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { createScenarioSnapshotFormMutation } from 'src/api/app-sync-services';
import { selectPendingEntityStatus } from 'src/app/AppMetadataSelector';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { selectActivePlanningCycles } from 'src/features/planning-cycle/planningCycleSelector';
import { selectPlanningCyclesByDataClassificationId } from 'src/features/planning-cycle/planningCycleUtils';
import { PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import {
  convertToUserLocalAndSplitDateTime,
  getCurrentTime,
  getCurrentUTCTimeInISO,
  getReadableFormatOfMonthDateYear
} from 'src/utils/date-time-utilities';
import { characterCountConstraintMessage } from 'src/utils/generic-utilities';
import { generateScenarioSnapshotName } from './SnapshotConfig';
import { SCENARIO_SNAPSHOT_NOTES_MAX_LENGTH, prepareForSubmitSnapshotMutation, scenarioSnapshotSchema } from './SnapshotFormUtils';
import { RootState } from 'src/store/store';

interface CreateSnapshotModalProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  dataClassificationId: number | undefined | null;
  planningCycleSnapshots: PlanningCycleSnapshot[];
}

// Initial empty state for the form
const INITIAL_DATA: PlanningCycleSnapshot = {} as PlanningCycleSnapshot;

// Component to create a snapshot for a scenario
const CreateSnapshotModal: React.FC<CreateSnapshotModalProps> = ({
  showModal,
  onCancel,
  onConfirm,
  dataClassificationId,
  planningCycleSnapshots
}) => {
  const userAuth = useAuth();

  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const currentBusinessGroupDataClassificationId = currentBusinessGroup?.data_classification.data_classification_id!;
  const currentBusinessGroupShortDesc = currentBusinessGroup?.data_classification.data_classification_short_description!;

  // State for dropdown options
  const [planningCyclesDropdown, setPlanningCyclesDropdown] = useState<SelectProps.Options>([]);

  // Ref to Formik instance for programmatic control
  const scenarioSnapshotFormRef = useRef<FormikProps<PlanningCycleSnapshot>>(null);

  // State to manage form data and UI
  const [initialFormData, setInitialFormData] = useState<PlanningCycleSnapshot>(INITIAL_DATA);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const activePlanningCycles = useSelector(selectActivePlanningCycles);
  const pendingEntityStatus = useSelector(selectPendingEntityStatus);

  // Load dropdown data when the modal is opened
  useEffect(() => {
    if (dataClassificationId) {
      loadRequiredInformation(dataClassificationId);
    }
  }, [dataClassificationId]);

  // Reset form when modal visibility changes
  useEffect(() => {
    if (showModal) {
      resetFormState();
    }
  }, [showModal]);

  // Reset form state to initial
  const resetFormState = () => {
    setFlashbarItems([]);
    setInitialFormData(INITIAL_DATA);
    scenarioSnapshotFormRef.current?.resetForm({ values: INITIAL_DATA });
  };

  // Fetch and set planning cycle dropdown options
  const loadRequiredInformation = useCallback((dataClassificationId: number) => {
    try {
      const planningCycles = selectPlanningCyclesByDataClassificationId(dataClassificationId, activePlanningCycles);
      const dropdownOptions = planningCycles.map(
        (cycle: { planning_cycle_year: number; scenario: { scenario_name: any; planning_cycle_name: string }; scenario_seq_id: any }) => ({
          label: cycle.scenario.scenario_name,
          value: `${cycle.scenario_seq_id}`,
          description: `${cycle.scenario.planning_cycle_name}_${cycle.planning_cycle_year}`
        })
      );
      setPlanningCyclesDropdown(dropdownOptions);
    } catch (error: any) {
      logger.error('Failed to load planning cycles:', error);
      setFlashbarItems([{ type: 'error', content: 'Failed to load scenarios. Please try again.' }]);
    }
  }, []);

  // Update form fields based on scenario selection
  const updateFormFieldsForSelectedScenario = useCallback(
    (form: FormikProps<PlanningCycleSnapshot>, scenarioName: string, scenarioValue: string, description: string) => {
      const scenarioSequenceId = isNaN(Number(scenarioValue)) ? undefined : Number(scenarioValue);
      if (!scenarioSequenceId) {
        form.setFieldError('scenario_name', 'Invalid Scenario');
        return;
      }

      const scenarioSnapshotName = generateScenarioSnapshotName(description, planningCycleSnapshots);

      form.setFieldValue('scenario_seq_id', scenarioSequenceId);

      form.setFieldValue('scenario_name', scenarioName);
      form.setFieldValue('planning_cycle_name', description.split('_')[0]);
      form.setFieldValue('planning_cycle_year', +description.split('_')[1]);

      form.setFieldValue('scenario_snapshot_name', scenarioSnapshotName);
      form.setFieldValue('scenario_snapshot_time', getCurrentUTCTimeInISO());

      form.setFieldTouched('scenario_name', true, true);
      form.setFieldTouched('planning_cycle_name', true, true);
      form.setFieldTouched('planning_cycle_year', true, true);

      form.setFieldTouched('scenario_snapshot_name', true, true);
      form.setFieldTouched('scenario_snapshot_time', true, true);

      setTimeout(() => form.validateForm());
    },
    [planningCycleSnapshots]
  );

  // Handle blur event on scenario select
  const handleBlurOnScenarioName = useCallback(
    (scenarioName: string | undefined, scenarioValue: string | undefined, description: string | undefined) => {
      const form = scenarioSnapshotFormRef.current;
      if (!form || !scenarioName || !scenarioValue || !description) return;

      // Setting form fields based on selected scenario
      updateFormFieldsForSelectedScenario(form, scenarioName, scenarioValue, description);
    },
    [updateFormFieldsForSelectedScenario]
  );

  // Function to count snapshots for a specific date
  const countSnapshotsOnDate = (snapshots: PlanningCycleSnapshot[], localDate: string) => {
    return snapshots.filter((snapshot) => convertToUserLocalAndSplitDateTime(snapshot.scenario_snapshot_time).date === localDate).length;
  };

  // Handle form submission
  const handleSubmit = async (values: PlanningCycleSnapshot, formikHelpers: FormikHelpers<PlanningCycleSnapshot>) => {
    const currentDate = getCurrentTime().format('YYYY-MM-DD');
    const snapshotCount = countSnapshotsOnDate(planningCycleSnapshots, currentDate);

    if (snapshotCount >= 5) {
      setFlashbarItems([{ type: 'error', content: 'Maximum limit of 5 snapshots per day reached.' }]);
      return; // Stop the submission process
    }

    setFlashbarItems([{ type: 'in-progress', content: 'Request in progress' }]);
    try {
      const createSnapshotMutation = prepareForSubmitSnapshotMutation(
        values,
        userAuth.Alias,
        pendingEntityStatus,
        currentBusinessGroupShortDesc,
        currentBusinessGroupDataClassificationId,
        values.scenario_seq_id
      );
      const response = await createScenarioSnapshotFormMutation(createSnapshotMutation);
      setFlashbarItems([]);
      setShowConfirmationDialog(true);
    } catch (error: any) {
      logger.error('Failed to create snapshot:', error);
      setFlashbarItems([{ type: 'error', content: 'Failed to create snapshot. Please try again.' }]);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <>
      {!showConfirmationDialog && (
        <Formik
          innerRef={scenarioSnapshotFormRef}
          enableReinitialize={true}
          initialValues={initialFormData}
          validationSchema={scenarioSnapshotSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Modal
                onDismiss={onCancel}
                visible={showModal}
                header={<Header>{`Create Snapshot`}</Header>}
                footer={
                  <Box textAlign="center">
                    <Flashbar items={flashbarItems} />
                    <Box float="right" textAlign="center" margin={{ top: 's' }}>
                      <SpaceBetween direction="horizontal" size="xs" alignItems="center">
                        <Button variant="normal" formAction="none" disabled={formikProps.isSubmitting} onClick={onCancel}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          formAction="submit"
                          onClick={() => formikProps.handleSubmit()}
                          disabled={formikProps.isSubmitting || !formikProps.dirty}
                        >
                          Submit
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </Box>
                }
              >
                <SpaceBetween size="m" direction="vertical">
                  <FormField label="Scenario" stretch={true} errorText={formikProps.touched.scenario_name && formikProps.errors.scenario_name}>
                    <Select
                      disabled={formikProps.isSubmitting}
                      filteringType="auto"
                      options={planningCyclesDropdown}
                      selectedOption={{ label: formikProps.values.scenario_name, value: formikProps.values.scenario_name }}
                      onChange={({ detail }) =>
                        handleBlurOnScenarioName(detail.selectedOption.label, detail.selectedOption.value, detail.selectedOption.description)
                      }
                    />
                  </FormField>
                  <ColumnLayout columns={2}>
                    <FormField
                      label="Snapshot name"
                      stretch={true}
                      constraintText={`We recommend not changing this.`}
                      errorText={formikProps.touched.scenario_snapshot_name && formikProps.errors.scenario_snapshot_name}
                    >
                      <Input
                        value={formikProps.values.scenario_snapshot_name}
                        onChange={({ detail }) => formikProps.setFieldValue('scenario_snapshot_name', detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Snapshot date"
                      stretch={true}
                      errorText={formikProps.touched.scenario_snapshot_time && formikProps.errors.scenario_snapshot_time}
                    >
                      <Input disabled={true} value={getReadableFormatOfMonthDateYear(formikProps.values.scenario_snapshot_time)} />
                    </FormField>
                  </ColumnLayout>
                  <FormField
                    label="Note - optional"
                    stretch={true}
                    errorText={formikProps.touched.scenario_snapshot_note && formikProps.errors.scenario_snapshot_note}
                    constraintText={characterCountConstraintMessage(
                      SCENARIO_SNAPSHOT_NOTES_MAX_LENGTH,
                      formikProps.values.scenario_snapshot_note?.length || 0
                    )}
                  >
                    <Textarea
                      disabled={formikProps.isSubmitting}
                      placeholder="Enter additional info to identify reason for snapshot (e.g., 1st pass, 2nd pass)"
                      value={formikProps.values.scenario_snapshot_note}
                      onChange={({ detail }) => formikProps.setFieldValue('scenario_snapshot_note', detail.value)}
                      onBlur={() => formikProps.setFieldTouched('scenario_snapshot_note', true)}
                    />
                  </FormField>
                </SpaceBetween>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
      {showConfirmationDialog && (
        <Modal
          visible={showConfirmationDialog}
          header={<Header>Snapshot Created Successfully</Header>}
          footer={
            <Box textAlign="center">
              <Button
                variant="primary"
                onClick={() => {
                  setShowConfirmationDialog(false);
                  onConfirm();
                }}
              >
                OK
              </Button>
            </Box>
          }
        >
          <Alert type="success" header="Snapshot has been successfully created!" dismissible={false}>
            Your data has been saved and is now accessible in the report sections.
          </Alert>
          <Box margin={{ top: 'm' }} variant="p">
            You can view the Snapshot data in the <strong>Reports</strong> &rarr; <strong>Forecast</strong> section.
          </Box>
          <Box variant="p">
            To compare data, please go to the <strong>Reports</strong> &rarr; <strong>Variance</strong> section.
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CreateSnapshotModal;
