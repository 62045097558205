import { useCollection } from '@amzn/awsui-collection-hooks';
import { CollectionPreferencesProps, Pagination, PropertyFilter, PropertyFilterProps, Table, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { BLANK_SEARCH_AND, TableEmptyState, TableNoMatchState, XptTablePreferences, getMatchesCountText } from '../TableCommons';
import { extractFieldNamesForDefaultVisibleContent, generateVisibleContentOptions, xptTableSortingComparator } from './XptTableConfig';
import { ColumnDataType, DataEntity, DynamicColumnDetails } from '../../../models/XptTableModel';

interface XptTableProps {
  xptTableVariant: TableProps.Variant;
  loadingStatus: boolean;
  entityName: string;
  xptTableHeader: React.ReactNode;
  allItems: any[];
  allColumns: DynamicColumnDetails;
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  itemsPerPage: number;
  selectionType?: TableProps.SelectionType;
  selectedItems: any[];
  setSelectedItems: (selectedItems: any[]) => {};
}

const XptTable: React.FC<XptTableProps> = ({
  xptTableVariant,
  loadingStatus,
  entityName,
  xptTableHeader,
  allItems,
  allColumns,
  columnDefinitions,
  itemsPerPage,
  selectionType,
  selectedItems,
  setSelectedItems
}) => {
  const [tableRowData, setTableRowData] = React.useState<any[]>([]);
  const [tableColumnDefinitions, setTableColumnDefinitions] = React.useState<TableProps.ColumnDefinition<any>[]>([]);

  const [tableDefaultPreferences, setTableDefaultPreferences] = React.useState<CollectionPreferencesProps.Preferences>({});
  const [tableVisibleCOntentOptions, setTableVisibleContentOptions] = React.useState<CollectionPreferencesProps.VisibleContentOptionsGroup[]>([]);
  const [filteringProperties, setFilteringProperties] = React.useState<PropertyFilterProps.FilteringProperty[]>([]);
  const [query, setQuery] = React.useState(BLANK_SEARCH_AND);

  React.useEffect(() => {
    document.body.style.lineHeight = 'normal';
  }, []);

  React.useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  // generating Table column definitions from allColumns
  React.useEffect(() => {
    setTableColumnDefinitions(columnDefinitions || []);
    setTableRowData(allItems || []);
  }, [columnDefinitions, allItems]);

  // generating Table default preferences from allColumns
  React.useEffect(() => {
    if (allColumns) {
      const defaultPreferences = {
        pageSize: itemsPerPage,
        visibleContent: extractFieldNamesForDefaultVisibleContent(allColumns),
        wrapLines: false,
        stripedRows: false,
        custom: false
      } as CollectionPreferencesProps.Preferences;
      setTableDefaultPreferences(defaultPreferences);
      setTableVisibleContentOptions(generateVisibleContentOptions(allColumns));
    }
  }, [allColumns, itemsPerPage]);

  const onClearFilter = () => {
    setQuery(BLANK_SEARCH_AND);
  };

  // generating filtering properties from allColumns
  React.useEffect(() => {
    if (allColumns) {
      const properties = generateFilteringProperties(allColumns);
      setFilteringProperties(properties);
    }
  }, [allColumns]);

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(tableRowData, {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName={``} />,
      noMatch: <TableNoMatchState onClearFilter={onClearFilter} />
    },
    pagination: {
      pageSize: tableDefaultPreferences.pageSize
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions?.find((col) => col.id == allColumns.columnInfo.sortingColumn) || columnDefinitions[0],
        isDescending: allColumns?.columnInfo?.isDescending
      }
    }
  });

  return (
    <>
      <Table
        variant={xptTableVariant}
        header={xptTableHeader}
        loading={loadingStatus}
        loadingText="Loading"
        stickyHeader={true}
        columnDefinitions={tableColumnDefinitions}
        items={items}
        selectionType={selectionType}
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
        onRowClick={({ detail }) => setSelectedItems([detail.item])}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />}
        {...collectionProps}
        trackBy={allColumns?.columnInfo?.trackBy}
        enableKeyboardNavigation
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            expandToViewport={true}
            i18nStrings={propertyFilterI18nStrings(entityName)}
            countText={getMatchesCountText(filteredItemsCount!)}
            query={query}
            onChange={(event) => {
              setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
            }}
          />
        }
        visibleColumns={tableDefaultPreferences.visibleContent}
        resizableColumns={tableDefaultPreferences.custom}
        wrapLines={tableDefaultPreferences.wrapLines}
        stripedRows={tableDefaultPreferences.stripedRows}
        contentDensity={tableDefaultPreferences.contentDensity}
        preferences={
          <XptTablePreferences
            preferences={tableDefaultPreferences}
            setPreferences={setTableDefaultPreferences}
            visibleContentOptions={tableVisibleCOntentOptions}
          />
        }
      />
    </>
  );
};

export default XptTable;

export const DefaultDateFormat = 'YYYY-MM-DD';
export const DefaultDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function generateFilteringProperties(dynamicColumnDetails: DynamicColumnDetails): any[] {
  const filteringProperties: any[] = dynamicColumnDetails.data
    .filter((items: DataEntity) => items.isFilterable)
    .map((dataEntity: DataEntity) => {
      const dataType: ColumnDataType = dataEntity.metadata.type;
      let operators: any[] = [];

      if (dataType === 'string') {
        operators = [':', '!:', '=', '!='];
      } else if (dataType === 'number') {
        operators = ['=', '!=', '<', '<=', '>', '>='];
      } else if (dataType === 'date') {
        operators = [':', '!:', '=', '!='];
      } else if (dataType === 'dateTime') {
        operators = [':', '!:', '=', '!='];
      } else {
        operators = [':', '!:', '=', '!='];
      }

      return {
        key: dataEntity.fieldName,
        propertyLabel: dataEntity.displayName,
        groupValuesLabel: `${dataEntity.displayName} values`,
        operators
      } as any;
    });

  return filteringProperties;
}
