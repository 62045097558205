import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export let awsRum: AwsRum;

export const configureCloudwatchRUM = (userAlias: string) => {
  const awsCloudwatchRumConfig = getCloudwatchRUMConfig();
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: awsCloudwatchRumConfig.guestRoleArn,
      identityPoolId: awsCloudwatchRumConfig.identityPoolId,
      endpoint: awsCloudwatchRumConfig.endpoint,
      telemetries: awsCloudwatchRumConfig.telemetries,
      allowCookies: true,
      enableXRay: true,
      enableRumClient: true,
      disableAutoPageView: false,
      pageIdFormat: 'PATH_AND_HASH'
    };

    const APPLICATION_ID: string = awsCloudwatchRumConfig.applicationId;
    const APPLICATION_VERSION: string = awsCloudwatchRumConfig.applicationVersion;
    const APPLICATION_REGION: string = awsCloudwatchRumConfig.applicationRegion;

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

    awsRum.addSessionAttributes({
      userAlias: userAlias
    });
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }

  return awsRum;
};

const getCloudwatchRUMConfig = () => {
  const rumConfiguration = {
    applicationId: 'ec6f7d3d-3480-42d6-9041-9ad3680c1466',
    applicationVersion: '1.0.0',
    applicationRegion: 'us-west-2',
    guestRoleArn: 'arn:aws:iam::631849255022:role/XPT-FE-Cognito-alpha-CognitoUnauthenticatedRoleCF6A-sSneglNwZrPD',
    identityPoolId: 'us-west-2:5c0c091b-0c1b-4e2a-bd93-0c7d17789458',
    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
    telemetries: ['performance', 'errors', 'http']
  };
  return rumConfiguration;
};

export const recordAWSRumEvent = (eventName: string, eventObject: any) => {
  awsRum.recordEvent(eventName, {
    ...eventObject
  });
};
