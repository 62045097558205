import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Header, RadioGroup } from '@amzn/awsui-components-react';
import { useAuth } from './AuthContextProvider';
import { UserAuthContext } from 'src/models/AuthContextModels';
import { logger } from 'src/analytics/KatalLogger';

export interface ModifyAuthContextProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

/**
 * Intention of this component is to provide an option for Dev team to test the application by changing the user role.
 * This component is rendered only if the user is a Dev user
 */
export const ModifyAuthContext: React.FC<ModifyAuthContextProps> = ({ showModal, onCancel, onConfirm }) => {
  const userAuthDetails = useAuth();
  const { updateUserAuthDetails, resetToDefaultAuthDetails } = useAuth();
  const [selectedRoleOption, setSelectedRoleOption] = React.useState<string | null>(null);

  const Role_Options = [
    { value: 'dev', label: 'Developer' },
    { value: 'admin', label: 'Finance Admin' },
    { value: 'bl', label: 'Finance Owner' },
    { value: 'bo', label: 'Budget Owner' },
    { value: 'ro', label: 'Read-Only User' },
    { value: 'no-access', label: 'No Access (Not part of any LDAP group)' }
  ];

  React.useEffect(() => {
    if (showModal) {
      const initialRole = userAuthDetails.isDev
        ? 'dev'
        : userAuthDetails.isAdmin
        ? 'admin'
        : userAuthDetails.isBusinessLeader
        ? 'bl'
        : userAuthDetails.isBudgetOwner
        ? 'bo'
        : userAuthDetails.isReadOnlyUser
        ? 'ro'
        : userAuthDetails.noAccess
        ? 'no-access'
        : null;
      setSelectedRoleOption(initialRole);
    }
  }, [showModal, userAuthDetails]);

  const handleSaveChanges = () => {
    const updates: Partial<UserAuthContext> = {
      isDev: false,
      isAdmin: false,
      isBusinessLeader: false,
      isBudgetOwner: false,
      isReadOnlyUser: false,
      noAccess: false,
      modifiedRoleAs: Role_Options?.find((_) => _.value === selectedRoleOption)?.label || '',
      isUserRoleModified: true
    };

    switch (selectedRoleOption) {
      case 'dev':
        updates.isDev = true;
        break;
      case 'admin':
        updates.isAdmin = true;
        break;
      case 'bl':
        updates.isBusinessLeader = true;
        break;
      case 'bo':
        updates.isBudgetOwner = true;
        break;
      case 'ro':
        updates.isReadOnlyUser = true;
        break;
      case 'no-access':
        updates.noAccess = true;
        break;
      default:
        logger.warn(`Unexpected role option: ${selectedRoleOption}`);
        handleReset();
        break;
    }

    updateUserAuthDetails(updates, true);
    onCancel();
    window.location.reload();
  };

  const handleReset = () => {
    resetToDefaultAuthDetails();
    onCancel();
    window.location.reload();
  };

  return (
    <Modal
      data-testid="modify-auth-context"
      onDismiss={onCancel}
      visible={showModal}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button variant="normal" onClick={handleReset}>
              Reset to Default
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={<Header description="Modify your role to test different application behaviors.">View as</Header>}
    >
      <Box padding="l">
        <RadioGroup
          data-testid="radio-auth-role-options"
          onChange={({ detail }) => setSelectedRoleOption(detail.value)}
          value={selectedRoleOption}
          items={Role_Options}
          ariaLabel="Select a role to view the application as"
        />
      </Box>
    </Modal>
  );
};
