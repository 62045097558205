import { ColDef, SideBarDef } from 'ag-grid-community';

export enum ReportTypes {
  FORECAST = 'Forecast',
  VARIANCE = 'Variance'
}

// Default widths for different types of columns in the grid
export enum ColumnWidths {
  DEFAULT = 120,
  LINE_ITEM_ID = 180,
  USER_ALIAS_COLUMN = 120,
  BUDGET_TYPE_COLUMN = 120,
  PLANNING_CYCLE_YEAR_COLUMN = 120,
  CORP_SEGMENT_CLOSED_COLUMN = 130,
  CORP_SEGMENT_COLUMN = 95,
  CORP_SEGMENT_DESCRIPTION_COLUMN = 180,
  BUSINESS_SEGMENT_CLOSED_COLUMN = 160,
  BUSINESS_SEGMENT_COLUMN = 160,
  TOTAL_LIFE_TIME_SPEND = 165,
  MONTH_COLUMN = 120,
  QUARTER_COLUMN = 130,
  YEAR_COLUMN = 130,
  LAST_UPDATED_AT = 190,
  VARIANCE_PERIOD_COLUMN = 170
}

// Default column definition for AgGrid
export const DefaultColDef: ColDef = {
  editable: false,
  resizable: true,
  filter: true,
  sortable: true,
  floatingFilter: false,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  enableRowGroup: true,
  enablePivot: true
};

const toolPanelBaseWidth = {
  minWidth: 180,
  maxWidth: 400,
  width: 250
};

export const forecastDataGridIcons = {
  // The key 'custom-stats' represents a custom icon for stats.
  'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>'
};

export const ReportSideBarConfig: SideBarDef = {
  position: 'right',
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressPivotMode: true // This disables the Pivot Mode toggle.
      },
      ...toolPanelBaseWidth
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: false
      },
      ...toolPanelBaseWidth
    }
  ]
};

export const RowGroupPanelShowOption: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
export const DEFAULT_PIVOT_MODE = true;
export const DEFAULT_GROUP_EXPANSION_LEVEL: number = -1;
export const DEFAULT_GROUP_DISPLAY_TYPE = 'multipleColumns';
export const TOTAL_ROW_POSITION = 'bottom';

export const XptReportGridFixedFields = {
  ScenarioSeqId: { value: 'scenario_seq_id', displayName: 'Scenario Seq Id' },
  XptLineItemSeqId: { value: 'xpt_line_item_seq_id', displayName: 'Line Item Seq Id' },
  XptLineItemId: { value: 'xpt_line_item_id', displayName: 'Line Item Id' },
  BudgetOwner: { value: 'budget_owner', displayName: 'Budget Owner' },
  BudgetType: { value: 'budget_type', displayName: 'Budget Type' },
  ScenarioYear: { value: 'scenario_year', displayName: 'Scenario Year' },
  UpdatedAt: { value: 'updated_at', displayName: 'Updated At' },
  UpdatedBy: { value: 'updated_by', displayName: 'Updated By' }
} as const;
