import { createSelector } from 'reselect';
import { RootState } from '../store';

const getCorpSegmentsStore = (state: RootState) => state.corpSegmentsStore;

export const masterCorpSegments = createSelector(getCorpSegmentsStore, (corpSegmentsStore) => corpSegmentsStore.masterCorpSegments);

export const masterCorpSegmentStatus = createSelector(getCorpSegmentsStore, (corpSegmentsStore) => corpSegmentsStore.masterCorpSegmentsStatus);

export const masterBusinessSegments = createSelector(getCorpSegmentsStore, (corpSegmentsStore) => corpSegmentsStore.masterBusinessSegments);
