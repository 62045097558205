import { Alert, Button, Container } from '@amzn/awsui-components-react';
import {
  CellValueChangedEvent,
  ColDef,
  ColumnGroupOpenedEvent,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowEditingStoppedEvent
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { eErrorMessages, OperationType } from 'src/constants/generic-constants';
import { useGridState } from 'src/hooks/useGridState';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { POTaggingEntity } from 'src/models/POTaggingModel';
import { AppDispatch, RootState } from 'src/store/store';
import { deepClone, handleCellEditingStopped, processCellForClipboard, processCellFromClipboard, StatusBarConfig } from 'src/utils/ag-grid-utils';
import { compareObjects } from 'src/utils/comparison-utils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import exportExcelWithDropdowns, { GenerateAndDownloadExcelFileParams } from 'src/utils/export-excel-with-dropdowns';
import { generateUniqueId } from 'src/utils/generic-utilities';
import { useAuth } from '../auth/AuthContextProvider';
import { currentBusinessGroup } from '../business-group/businessGroupSelectors';
import { DefaultColDefActuals } from './POGridConstants';
import { getPOTaggingHeaderInfo, poTaggingColumnGenerator, poTaggingExcelStyles } from './POTaggingColumnGenerator';
import { usePOTaggingContext } from './POTaggingContext';
import { POTaggingFileUpload } from './POTaggingFileUpload';
import { POTaggingGridHeader } from './POTaggingGridHeader';
import { fetchActualTaggingData, setIsGridDirty } from './POTaggingSlice';
import { getLineItemIdsBasedOnCC, getPOTaggingExportFileName, submitPOTaggingData } from './POTaggingUtils';

export const POTaggingGrid: React.FC = () => {
  const userAuth = useAuth();
  const dispatch = useDispatch<AppDispatch>();

  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);
  const businessGroup = useSelector(currentBusinessGroup);
  const dataClassificationId = businessGroup?.data_classification?.data_classification_id;
  const businessGroupShortDesc = businessGroup?.data_classification?.data_classification_short_description || 'default';

  const { displayFlashMessage, clearSpecificFlashMessage } = usePOTaggingContext();
  const {
    selectedActualMonth,
    selectedCostCenter,
    actualsFilterDropdownValues,
    actualTaggingDataStatus,
    filteredActualsTaggingData,
    showAllData,
    poTaggingLineItemDetailsStatus,
    poTaggingLineItemDetails
  } = useSelector((state: RootState) => state.poTaggingStore);

  const gridRef = useRef<AgGridReact>(null);
  const gridStateKey = `UniqueGridStateKey-ActualsTagging-${businessGroupShortDesc}`;

  const { saveGridState, restoreGridState, clearGridState } = useGridState(gridRef, gridStateKey);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [gridRowData, setGridRowData] = useState<POTaggingEntity[]>([]);
  const originalRowData = useRef<POTaggingEntity[]>([]);
  const dirtyRows = useRef<Set<number>>(new Set());

  const [lineItemIdsBasedOnCC, setLineItemIdsBasedOnCC] = useState<string[]>([]);
  const [showFileImportModal, setShowFileImportModal] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

  const restoreGrid = useCallback(() => {
    setTimeout(() => {
      restoreGridState();
      gridRef.current?.api.refreshCells();
    }, 0);
  }, [restoreGridState]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      restoreGrid();
      autoSizeAll(false);
    },
    [restoreGrid]
  );

  const onColumnGroupOpened = useCallback(
    (params: ColumnGroupOpenedEvent) => {
      autoSizeAll(false);
    },
    [restoreGrid]
  );

  const autoSizeAll = useCallback((skipHeader: boolean) => {
    const allColumnIds: string[] = [];
    gridRef?.current?.api
      .getColumns()
      ?.filter((column) => column.getId() !== 'po_line_description')
      ?.forEach((column) => {
        allColumnIds.push(column.getId());
      });
    gridRef?.current?.api.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const loadColumnDefinitions = useCallback(async () => {
    if (
      businessGroup &&
      selectedCostCenter &&
      actualTaggingDataStatus !== LoadingStatus.Loading &&
      poTaggingLineItemDetailsStatus !== LoadingStatus.Loading
    ) {
      const lineItemIdsBasedOnCostCenter = getLineItemIdsBasedOnCC(poTaggingLineItemDetails.po_tagging_line_item_ids, selectedCostCenter);
      setLineItemIdsBasedOnCC(lineItemIdsBasedOnCostCenter);
      const colDefs = await poTaggingColumnGenerator(lineItemIdsBasedOnCostCenter, userAuth.isReadOnlyUser);
      setColumnDefinitions(colDefs);
    } else {
      setColumnDefinitions([]);
    }
    restoreGrid();
  }, [businessGroup, selectedCostCenter, actualTaggingDataStatus, poTaggingLineItemDetailsStatus, restoreGrid]);

  const loadActualsTaggingData = useCallback(() => {
    if (businessGroup && actualTaggingDataStatus !== LoadingStatus.Loading) {
      const appliedToggle = showAllData ? filteredActualsTaggingData : filteredActualsTaggingData.filter((row) => row.po_number !== 'N/A');
      const mutableActualsRowData = deepClone(appliedToggle);
      originalRowData.current = deepClone(appliedToggle);
      setGridRowData(mutableActualsRowData);
      dispatch(setIsGridDirty(false));
      dirtyRows.current.clear();
    }
  }, [businessGroup, actualTaggingDataStatus, showAllData, filteredActualsTaggingData]);

  useEffect(() => {
    loadActualsTaggingData();
  }, [showAllData, loadActualsTaggingData]);

  const handleRefresh = useCallback(() => {
    if (dataClassificationId && businessGroupShortDesc && selectedActualMonth && selectedCostCenter) {
      dispatch(
        fetchActualTaggingData({
          dataClassificationId,
          businessGroupShortDesc,
          selectedActualMonth,
          selectedCostCenter
        })
      )
        .unwrap()
        .then(() => {
          dispatch(setIsGridDirty(false));
          dirtyRows.current.clear();
        })
        .catch((error: string) => {
          logger.error(`Error: ${error}`);
          const errorMessage =
            error === eErrorMessages.NO_DATA_FOUND
              ? `No data found for the selected month (${selectedActualMonth}) and cost center (${selectedCostCenter})`
              : `Unable to load PO tagging data`;
          displayFlashMessage(errorMessage, 'error', true);
        });
    }
  }, [dataClassificationId, businessGroupShortDesc, selectedActualMonth, selectedCostCenter, dispatch, displayFlashMessage]);

  const handleSubmit = useCallback(async () => {
    const dirtyRowsData = gridRowData
      .filter((row) => dirtyRows.current.has(row.actuals_item_id))
      .map((row) => ({
        ...row,
        updated_at: getCurrentUTCTimeInISO(),
        updated_by: userAuth.Alias
      }));

    if (dirtyRowsData.length > 0) {
      setSubmitInProgress(true);
      const inProgressMessageId = generateUniqueId();
      displayFlashMessage('PO Tagging data submit in progress', 'info', false, inProgressMessageId);

      try {
        await submitPOTaggingData(
          dirtyRowsData,
          businessGroupShortDesc,
          dataClassificationId!,
          selectedActualMonth!,
          selectedCostCenter!,
          userAuth.Alias,
          OperationType.CRUD
        );

        displayFlashMessage('PO Tagging data successfully updated!', 'success', true);
        dispatch(setIsGridDirty(false));
        dirtyRows.current.clear();
        handleRefresh();
      } catch (error: any) {
        logger.error('Error during the PO tagging data submission process', error);
        displayFlashMessage('Error during the PO tagging data submission process', 'error', true);
      } finally {
        clearSpecificFlashMessage(inProgressMessageId);
        setSubmitInProgress(false);
      }
    }
  }, [
    gridRowData,
    businessGroupShortDesc,
    dataClassificationId,
    selectedActualMonth,
    selectedCostCenter,
    userAuth.Alias,
    displayFlashMessage,
    clearSpecificFlashMessage,
    dispatch,
    handleRefresh
  ]);

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const actualsItemId = event.data.actuals_item_id;
      const originalRow = originalRowData.current.find((row) => row.actuals_item_id === actualsItemId);
      const currentRow = event.data;

      if (!originalRow) return;

      const isRowDirty = !compareObjects(originalRow, currentRow);

      if (isRowDirty) {
        dirtyRows.current.add(actualsItemId);
      } else {
        dirtyRows.current.delete(actualsItemId);
      }

      dispatch(setIsGridDirty(dirtyRows.current.size > 0));
    },
    [dispatch]
  );

  const onRowEditingStopped = useCallback(
    (event: RowEditingStoppedEvent) => {
      dispatch(setIsGridDirty(true));
    },
    [dispatch]
  );

  useEffect(() => {
    if (actualTaggingDataStatus === LoadingStatus.Failed) {
      setGridRowData([]);
    } else if (actualTaggingDataStatus === LoadingStatus.Completed) {
      loadColumnDefinitions();
      loadActualsTaggingData();
    }
  }, [actualTaggingDataStatus, selectedActualMonth, selectedCostCenter, loadColumnDefinitions, loadActualsTaggingData]);

  useEffect(() => {
    loadActualsTaggingData();
  }, [filteredActualsTaggingData, loadActualsTaggingData]);

  const handleFileActions = useCallback(
    (id: string) => {
      if (selectedActualMonth && selectedCostCenter) {
        const { fileName, sheetName } = getPOTaggingExportFileName(businessGroupShortDesc, selectedActualMonth, selectedCostCenter);

        switch (id) {
          case 'discard_all_changes':
            handleRefresh();
            break;
          case 'ag_grid_export_to_excel':
            agGridExportToExcel(fileName, sheetName);
            break;
          case 'import_from_excel':
            setShowFileImportModal(true);
            break;
          case 'restore-view':
            restoreGridState();
            break;
          case 'save-view':
            saveGridState();
            break;
          case 'reset-view':
            clearGridState();
            break;
          default:
            logger.warn(`No handler defined for action ${id}`);
        }
      }
    },
    [selectedActualMonth, selectedCostCenter, businessGroupShortDesc]
  );

  const agGridExportToExcel = useCallback(
    (fileName: string, sheetName: string) => {
      if (businessGroup && selectedActualMonth && selectedCostCenter) {
        const exportedData: string | Blob | undefined = gridRef.current?.api.getDataAsExcel({
          fileName,
          sheetName,
          author: userAuth.Alias,
          allColumns: true,
          skipColumnGroupHeaders: true,
          skipRowGroups: true,
          columnKeys: getPOTaggingHeaderInfo().poTaggingFileHeader.map((column) => column.field),
          rowHeight: 20,
          processCellCallback: (params: ProcessCellForExportParams) => {
            if (params.value === '-' || params.value === null || params.value === '') {
              return ''; // Exporting empty string instead of '-'
            }
            return params.value;
          },
          shouldRowBeSkipped: (params) => {
            return params.node.footer || false;
          }
        });

        const excelDownloadParams: GenerateAndDownloadExcelFileParams = {
          data: exportedData,
          sheetName,
          fileNameWithoutExtension: fileName,
          validations: []
        };
        exportExcelWithDropdowns(excelDownloadParams);
      }
    },
    [businessGroup, selectedActualMonth, selectedCostCenter, userAuth.Alias]
  );

  const onCancelFileImport = useCallback(() => {
    setShowFileImportModal(false);
  }, []);

  const onConfirmingFileImport = useCallback(() => {
    setShowFileImportModal(false);
    handleRefresh();
  }, [handleRefresh]);

  const processCellValueFromClipboard = (params: ProcessCellForExportParams): any => {
    if (!selectedCostCenter) return null;
    const colDef = params.column.getColDef();

    // For non drop down cells, use generic utility "processCellFromClipboard" to process clipboard cells.
    if (colDef.cellEditor !== 'agRichSelectCellEditor') {
      processCellFromClipboard(params);
      return null;
    }

    const copiedValue = params.value;
    if (!copiedValue) {
      return null;
    }

    // Validates the copied cell value against a predefined list of valid line item IDs.
    // Ensures that only valid line item IDs are allowed when pasting data into the grid.
    const lineItemIdsBasedOnCostCenter = getLineItemIdsBasedOnCC(poTaggingLineItemDetails.po_tagging_line_item_ids, selectedCostCenter);
    const isValidLineItemId = lineItemIdsBasedOnCostCenter.includes(copiedValue);
    return isValidLineItemId ? copiedValue : null;
  };

  return (
    <>
      {actualTaggingDataStatus === LoadingStatus.Loading && <LoadingSpinner />}
      {actualTaggingDataStatus !== LoadingStatus.Loading && (
        <Container
          className="ag-grid-container"
          disableContentPaddings
          header={<POTaggingGridHeader onClickOfSubmit={handleSubmit} onClickOfFileActions={handleFileActions} submitInProgress={submitInProgress} />}
        >
          <POTaggingFileUpload
            showModal={showFileImportModal}
            onCancel={onCancelFileImport}
            onSuccessConfirm={onConfirmingFileImport}
            lineItemIdsBasedOnCC={lineItemIdsBasedOnCC}
          />
          <div className={themeClassName} style={{ height: '100%', width: '100%' }}>
            <ErrorBoundary
              FallbackComponent={() => (
                <Alert
                  type="error"
                  dismissible={false}
                  visible={true}
                  header="Error"
                  action={<Button onClick={() => window.location.reload()}>Reload</Button>}
                >
                  {'Unable to load grid data, try reloading once'}
                </Alert>
              )}
            >
              <AgGridReact
                ref={gridRef}
                onGridReady={onGridReady}
                onRowEditingStopped={onRowEditingStopped}
                onCellValueChanged={onCellValueChanged}
                onCellEditingStopped={handleCellEditingStopped}
                defaultColDef={DefaultColDefActuals}
                columnDefs={columnDefinitions}
                headerHeight={80}
                rowData={gridRowData}
                statusBar={StatusBarConfig}
                enableAdvancedFilter={false}
                excelStyles={poTaggingExcelStyles}
                alwaysMultiSort={true}
                onColumnGroupOpened={onColumnGroupOpened}
                rowBuffer={10}
                rowModelType="clientSide"
                rowHeight={30}
                suppressContextMenu
                suppressLastEmptyLineOnPaste
                processCellForClipboard={processCellForClipboard}
                processCellFromClipboard={processCellValueFromClipboard}
                enableRangeHandle={true}
                enableRangeSelection={true}
                grandTotalRow="bottom"
              />
            </ErrorBoundary>
          </div>
        </Container>
      )}
    </>
  );
};

export default POTaggingGrid;
