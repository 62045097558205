import { Button, Flashbar, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { PlanningCycleFlatTable } from 'src/models/PlanningCycleModel';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { deepClone } from 'src/utils/ag-grid-utils';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../business-group/businessGroupSelectors';
import BusinessGroupSideNavigation from '../xpt-layout/XptBusinessGroupSideNavigation';
import { NON_ADMIN_PLANNING_TABLE_CONFIG, getNonAdminPlanningBreadcrumbItems, getNonAdminPlanningCycleTableDefinitions } from './PlanningCycleConfig';
import PlanningCycleManageFormNonAdmin from './PlanningCycleManageFormNonAdmin';
import { fetchForecastPlanningCycles, fetchPlanningCycles } from './planningCycleSlice';
import { convertPlanningsToFlat } from './planningCycleUtils';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { STAGES } from 'src/constants/generic-constants';
import { RootState } from 'src/store/store';
import { selectForecastPlanningCycles } from './planningCycleSelector';

const PlanningCycleHomePageNonAdmin: React.FC = () => {
  const appLayout = useRef<any>();

  const { flashbarItems, displayFlashMessage } = useFlashbar();

  const dispatch = useAppDispatch();
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const forecastPlanningCycles = useSelector(selectForecastPlanningCycles);

  const [loadingPlanningCycles, setLoadingPlanningCycles] = useState<boolean>(false);
  const [flattenPlanningCycles, setFlattenPlanningCycles] = useState<PlanningCycleFlatTable[]>([]);
  const [selectedPlanningCycle, setSelectedPlanningCycle] = useState<PlanningCycleFlatTable>();

  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPlanningCycle) {
      setSplitPanelOpen(true);
    }
  }, [selectedPlanningCycle]);

  useEffect(() => {
    const initializePlanningCycleOptions = async () => {
      setLoadingPlanningCycles(true);
      try {
        setFlattenPlanningCycles(convertPlanningsToFlat(forecastPlanningCycles));
      } catch (error: any) {
        logger.error(`Unable to load Planning Cycles.`, error);
        displayFlashMessage(`Unable to load Planning Cycles.`, 'error', true);
      } finally {
        setLoadingPlanningCycles(false);
      }
    };
    initializePlanningCycleOptions();
  }, [businessGroupName, forecastPlanningCycles]);

  // Function to reload planning cycles data
  const reloadPlanningCycles = async () => {
    setLoadingPlanningCycles(true);
    try {
      await dispatch(fetchPlanningCycles()).unwrap();
      await dispatch(fetchForecastPlanningCycles()).unwrap();
      reselectTheSelectedOneToTriggerSplitPanel();
    } catch (error) {
      logger.error('Unable to load planning cycles', { error });
      displayFlashMessage('Unable to load planning cycles', 'error', true);
    } finally {
      setLoadingPlanningCycles(false);
    }
  };

  const reselectTheSelectedOneToTriggerSplitPanel = () => {
    if (selectedPlanningCycle) {
      const _selectedPlanningCycle = deepClone(selectedPlanningCycle);
      setSelectedPlanningCycle(undefined);
      setTimeout(() => {
        setSelectedPlanningCycle(_selectedPlanningCycle);
      }, 500);
    }
  };

  // Table header with a refresh button
  const PlanningCycleTableHeader = () => (
    <Header
      actions={
        <SpaceBetween size="l" direction="horizontal">
          <Button iconName="refresh" onClick={reloadPlanningCycles}></Button>
        </SpaceBetween>
      }
      counter={getHeaderCounter([], flattenPlanningCycles)}
    >
      {`Planning Cycles ${ENVIRONMENT_VARIABLES.env.Stage === STAGES.DEV ? `- ${selectedPlanningCycle?.scenario_seq_id}` : ``}`}
    </Header>
  );

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<BusinessGroupSideNavigation />}
      toolsHide={true}
      breadcrumbs={<XPTBreadcrumbs items={getNonAdminPlanningBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType={'table'}
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={({ detail }) => {
        setSplitPanelOpen(detail.open);
        if (!detail.open) {
          setSelectedPlanningCycle(undefined);
        }
      }}
      splitPanelPreferences={{ position: 'bottom' }}
      splitPanel={<PlanningCycleManageFormNonAdmin selectedPlanningCycle={selectedPlanningCycle} reloadPlanningCycles={reloadPlanningCycles} />}
      content={
        <div className="xpt-app-layout-content">
          <XptTable
            xptTableVariant="full-page"
            loadingStatus={loadingPlanningCycles}
            entityName={NON_ADMIN_PLANNING_TABLE_CONFIG.columnInfo.entityName}
            xptTableHeader={<PlanningCycleTableHeader />}
            allItems={flattenPlanningCycles}
            allColumns={NON_ADMIN_PLANNING_TABLE_CONFIG}
            columnDefinitions={getNonAdminPlanningCycleTableDefinitions()}
            selectionType="single"
            selectedItems={selectedPlanningCycle ? [selectedPlanningCycle] : []}
            setSelectedItems={async (items) => setSelectedPlanningCycle(items[0])} // selectionType="single"
            itemsPerPage={20}
          />
        </div>
      }
    />
  );
};

export default PlanningCycleHomePageNonAdmin;
