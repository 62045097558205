import { createSelector } from '@reduxjs/toolkit';
import { MasterScenarios, PlanningCycle, PlanningCycleGroupedByScenarioYear } from 'src/models/PlanningCycleModel';
import { isDateInThePast } from 'src/utils/date-time-utilities';
import { compareNullableNumbers } from 'src/utils/generic-utilities';
import { RootState } from '../../store/store';

// Selectors for planning cycles and scenarios

/**
 * Selects all available planning cycles from the Redux state.
 * This is a base selector used to derive more specific or transformed data.
 */
export const selectAllPlanningCycles = (state: RootState): PlanningCycle[] => state.planningCycleStore.planningCycles;

/**
 * Selects all scenarios from the Redux state.
 * Scenarios are used throughout the application to categorize or filter planning cycles.
 */
export const selectAllScenarios = (state: RootState): MasterScenarios[] => state.planningCycleStore.scenarios;

/**
 * Selector for filtering active planning cycles.
 * Active cycles are those currently in progress or not marked as completed/locked.
 */
export const selectActivePlanningCycles = createSelector([selectAllPlanningCycles], (planningCycles) =>
  planningCycles.filter((cycle) => cycle.is_active)
);

export const selectAllPlanningCyclesForCurrentGroup = createSelector(
  [(state: RootState) => state.planningCycleStore.planningCycles, (state: RootState) => state.businessGroupStore.currentBusinessGroup],
  (planningCycles, currentBusinessGroup) => {
    // Filter planningCycles based on the currentBusinessGroup
    if (!currentBusinessGroup) return [];

    const allPlanningCycleForCurrentGroup: PlanningCycle[] = planningCycles
      .filter((cycle) => cycle.data_classification.data_classification_name === currentBusinessGroup.data_classification.data_classification_name)
      .sort((a, b) => compareNullableNumbers(a.scenario_seq_id, b.scenario_seq_id, 'desc'));

    return allPlanningCycleForCurrentGroup;
  }
);

export const selectScenarioSnapshotPerBusinessGroup = createSelector(
  [
    (state: RootState) => state.planningCycleStore.planningCycles,
    (state: RootState) => state.planningCycleStore.planningCycleSnapshots,
    (state: RootState) => state.businessGroupStore.currentBusinessGroup
  ],
  (planningCycles, planningCycleSnapshots, currentBusinessGroup) => {
    // Filter planningCycles based on the currentBusinessGroup
    if (!currentBusinessGroup) return [];

    const allPlanningCycleForCurrentGroup: PlanningCycle[] = planningCycles.filter(
      (cycle) => cycle.data_classification.data_classification_name === currentBusinessGroup.data_classification.data_classification_name
    );

    const snapshotsPerBusinessGroup = planningCycleSnapshots.filter((snapshot) =>
      allPlanningCycleForCurrentGroup.some((cycle) => cycle.scenario_seq_id === snapshot.scenario_seq_id)
    );

    return snapshotsPerBusinessGroup;
  }
);

export const selectForecastPlanningCycles = createSelector(
  [
    (state: RootState) => state.planningCycleStore.forecastPlanningCycles,
    (state: RootState) => state.planningCycleStore.planningCycles,
    (state: RootState) => state.businessGroupStore.currentBusinessGroup
  ],
  (forecastPlanningCycles, planningCycles, currentBusinessGroup) => {
    // Filter planningCycles based on the currentBusinessGroup
    if (!currentBusinessGroup) return [];

    return planningCycles
      .filter((cycle) => cycle.data_classification.data_classification_id === currentBusinessGroup.data_classification.data_classification_id)
      .filter((cycle) =>
        forecastPlanningCycles.some(
          (id) => cycle.data_classification.data_classification_id === id.data_classification_id && cycle.scenario_seq_id === id.scenario_seq_id
        )
      );
  }
);

/**
 * Groups planning cycles by scenario year, organizing them for better accessibility in the UI.
 * This transformation aids in categorizing cycles for display or analysis.
 * Admin Planning Cycle
 */
export const selectPlanningCyclesGroupedByScenarioYear = createSelector(
  [selectAllPlanningCycles],
  (planningCycles): PlanningCycleGroupedByScenarioYear[] => {
    const grouped: {
      [key: string]: {
        cycle: PlanningCycleGroupedByScenarioYear;
        dataClassificationNames: Set<string>;
        lockedCount: number;
        openCount: number;
        totalCount: number;
      };
    } = {};

    planningCycles.forEach((cycle) => {
      const scenarioYear = cycle.scenario_year;
      const isLocked = isDateInThePast(cycle.planning_cycle_lock_date);
      const isOpen = cycle.is_active;

      if (!grouped[scenarioYear]) {
        grouped[scenarioYear] = {
          cycle: {
            scenario_id: cycle.scenario.scenario_id,
            scenario_name: cycle.scenario.scenario_name,
            scenario_year: scenarioYear,
            baseline_scenario_name: cycle.baseline_scenario?.baseline_scenario_name || null,
            baseline_scenario_seq_id: cycle.baseline_scenario?.baseline_scenario_seq_id || null,
            data_classification_name_csv: '',
            lock_status_summary: '',
            allPlanningCyclesClosed: false
          },
          dataClassificationNames: new Set([cycle.data_classification.data_classification_name]),
          lockedCount: isLocked ? 1 : 0,
          openCount: isOpen ? 1 : 0,
          totalCount: 1
        };
      } else {
        grouped[scenarioYear].dataClassificationNames.add(cycle.data_classification.data_classification_name);
        if (isLocked) {
          grouped[scenarioYear].lockedCount += 1;
        }
        if (isOpen) {
          grouped[scenarioYear].openCount += 1;
        }
        grouped[scenarioYear].totalCount += 1;
      }
    });

    // Convert Set to array and join with comma
    Object.values(grouped).forEach((group) => {
      group.cycle.data_classification_name_csv = Array.from(group.dataClassificationNames).sort().join(', ');
      const unlockedCount = group.totalCount - group.lockedCount;
      group.cycle.lock_status_summary = `${unlockedCount}/${group.totalCount} Unlocked`;
      group.cycle.allPlanningCyclesClosed = group.openCount === 0; // Corrected line
    });
    return Object.values(grouped).map((group) => group.cycle);
  }
);

/**
 * Extracts scenario years from grouped planning cycles for dropdowns or filters in the UI.
 * This helps in providing a user interface element to select cycles based on the year.
 */
export const selectPlanningCycleScenarioYears = createSelector([selectPlanningCyclesGroupedByScenarioYear], (groupedPlanningCycles) =>
  groupedPlanningCycles.map((cycle) => cycle.scenario_year)
);

// Factory selectors

/**
 * Factory selector for fetching a specific planning cycle by its ID.
 * This dynamic selector can be used in components to select a cycle based on a prop or state value.
 */
export const makeSelectPlanningCycleById = (id: number) =>
  createSelector([selectAllPlanningCycles], (planningCycles): PlanningCycle | undefined =>
    planningCycles.find((cycle) => cycle.scenario_seq_id === id)
  );

/**
 * Checks if a provided scenario ID is valid by verifying its existence in the list of scenarios.
 * Useful for validation checks before performing operations dependent on a valid scenario ID.
 */
export const makeSelectIsValidScenarioId = (scenarioId: number) =>
  createSelector([selectAllScenarios], (scenarios) => scenarios.some((scenario) => scenario.scenario_id === scenarioId));

/**
 * Verifies the validity of a scenario year by checking against all planning cycles.
 * This can prevent user errors or guide user inputs in UI forms or filters.
 */
export const makeSelectIsValidScenarioYear = (scenarioYear: string) =>
  createSelector([selectAllPlanningCycles], (planningCycles) => planningCycles.some((planningCycle) => planningCycle.scenario_year === scenarioYear));
