import { BreadcrumbGroupProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { eEntityStatus } from 'src/constants/generic-constants';
import { PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { getReadableFormatOfMonthDateYear } from 'src/utils/date-time-utilities';
import { getStatusIndicatorColorOverride, getStatusIndicatorType } from 'src/utils/generic-utilities';

export const getScenarioSnapshotTableBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Snapshots',
      href: `/${currentBusinessGroupName}/snapshots`
    }
  ];
};

export const Scenario_Snapshot_TABLE_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Snapshots',
    trackBy: 'scenario_snapshot_id',
    sortingColumn: 'updated_at',
    isDescending: true
  },
  data: [
    {
      fieldName: 'scenario_name',
      displayName: 'Planning Cycle',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_year',
      displayName: 'Scenario',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_snapshot_name',
      displayName: 'Snapshot name',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_snapshot_status',
      displayName: 'Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_snapshot_time',
      displayName: 'Snapshot Date',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_snapshot_note',
      displayName: 'Notes',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'updated_by',
      displayName: 'Taken By',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const Scenario_Snapshot_TABLE_DEFINITIONS: TableProps.ColumnDefinition<PlanningCycleSnapshot>[] = [
  {
    id: 'scenario_snapshot_id',
    header: 'Id',
    cell: (item: PlanningCycleSnapshot) => item.scenario_snapshot_id,
    sortingField: 'scenario_snapshot_id'
  },
  {
    id: 'scenario_name',
    header: 'Planning Cycle',
    cell: (item: PlanningCycleSnapshot) => item.scenario_name,
    sortingField: 'scenario_name',
    isRowHeader: true
  },
  {
    id: 'scenario_year',
    header: 'Scenario',
    cell: (item: PlanningCycleSnapshot) => `${item.planning_cycle_name}_${item.planning_cycle_year}`,
    sortingField: 'scenario_name'
  },
  {
    id: 'scenario_snapshot_name',
    header: 'Snapshot name',
    cell: (item: PlanningCycleSnapshot) => item.scenario_snapshot_name,
    sortingField: 'scenario_snapshot_name'
  },
  {
    id: 'scenario_snapshot_status',
    header: 'Status',
    cell: (item: PlanningCycleSnapshot) => {
      if (!item.scenario_snapshot_status) {
        // Handling the null case, e.g., by displaying a default value or a specific UI element
        return <div>-</div>;
      }
      return (
        <StatusIndicator
          type={getStatusIndicatorType(item.scenario_snapshot_status.entity_status as eEntityStatus)}
          colorOverride={getStatusIndicatorColorOverride(item.scenario_snapshot_status.entity_status as eEntityStatus)}
        >
          {item.scenario_snapshot_status.entity_status}
        </StatusIndicator>
      );
    },
    sortingField: 'scenario_snapshot_status'
  },
  {
    id: 'scenario_snapshot_time',
    header: 'Snapshot Date',
    cell: (item: PlanningCycleSnapshot) => getReadableFormatOfMonthDateYear(item.scenario_snapshot_time),
    sortingField: 'scenario_snapshot_time'
  },
  {
    id: 'updated_by',
    header: 'Taken By',
    cell: (item: PlanningCycleSnapshot) => item.updated_by,
    sortingField: 'updated_by'
  },
  {
    id: 'scenario_snapshot_note',
    header: 'Notes',
    minWidth: 200,
    cell: (item: PlanningCycleSnapshot) => item.scenario_snapshot_note,
    sortingField: 'scenario_snapshot_note'
  }
];

// Utility function to generate scenario snapshot name
export const generateScenarioSnapshotName = (scenarioName: string, planningCycleSnapshots: PlanningCycleSnapshot[]): string => {
  const baseName = scenarioName.replace(/ /g, '_');

  // Extract version numbers from existing snapshot names
  const versions = planningCycleSnapshots
    .filter((snapshot) => snapshot.scenario_snapshot_name.startsWith(baseName))
    .map((snapshot) => {
      const match = snapshot.scenario_snapshot_name.match(/_v(\d+)$/);
      return match ? parseInt(match[1], 10) : 0;
    });

  const highestVersion = versions.length > 0 ? Math.max(...versions) : 0;
  return `${baseName}_v${highestVersion + 1}`;
};
