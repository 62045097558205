import { createSelector, createStructuredSelector } from 'reselect';
import { RootState } from 'src/store/store';

const getFilterSelection = (state: RootState) => state.forecastTemplateStore.filterSelection;

export const planningCycleSelector = createSelector(
  [
    getFilterSelection,
    (state: RootState, businessGroupShortDesc: string, planningCycleSeqId: string) => businessGroupShortDesc,
    (state: RootState, businessGroupShortDesc: string, planningCycleSeqId: string) => planningCycleSeqId
  ],
  (filterSelection, businessGroupShortDesc, planningCycleSeqId) => filterSelection[businessGroupShortDesc]?.[planningCycleSeqId]?.planningCycle
);

export const corpSegmentSelectionsSelector = createSelector(
  [
    getFilterSelection,
    (state: RootState, businessGroupShortDesc: string, planningCycleSeqId: string) => businessGroupShortDesc,
    (state: RootState, businessGroupShortDesc: string, planningCycleSeqId: string) => planningCycleSeqId
  ],
  (filterSelection, businessGroupShortDesc, planningCycleSeqId) =>
    filterSelection[businessGroupShortDesc]?.[planningCycleSeqId]?.corpSegmentSelections
);

export const selectorsGroup = createStructuredSelector({
  planningCycle: planningCycleSelector,
  corpSegmentSelections: corpSegmentSelectionsSelector
});
