import { Alert, Button, Container, ContentLayout, Flashbar, Header, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { CellValueChangedEvent, ColDef, GetRowIdParams, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { updateProjectMapping } from 'src/api/app-sync-services';
import { ConfirmDiscardModal } from 'src/components/common/ConfirmDiscardModal';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { eCorpSegmentNames } from 'src/constants/corp-segment-constants';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { convertToDropdownValues } from 'src/features/business-group/forecast-template-v2/forecast-utils/CorpSegmentsUtils';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { MasterCorpSegments } from 'src/models/AppContextModels';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { ProjectMappingEntity } from 'src/models/xPTMappingModels';
import { fetchCorpSegments } from 'src/store/slices/corpSegmentSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { processCellForClipboard, processCellFromClipboard, StatusBarConfig } from 'src/utils/ag-grid-utils';
import { generateUniqueId } from 'src/utils/generic-utilities';
import AdminConsoleSideNavigation from '../AdminConsoleSideNavigation';
import {
  convertToProjectMutation,
  defaultProjectColDef,
  getProjectBreadcrumbItems,
  isProjectTypeRowModified,
  projectMappingColumnDefinition,
  ProjectMappingValidationMessages,
  validateProjectMapping
} from './ProjectMappingUtils';

export const ProjectMapping: React.FC = () => {
  const appLayout = useRef<any>();
  const { Alias } = useAuth();
  const dispatch = useAppDispatch();
  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);

  const { masterCorpSegmentsStatus, masterCorpSegments } = useSelector((state: RootState) => state.corpSegmentsStore);

  const gridRef = useRef<AgGridReact<ProjectMappingEntity>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [projectMappingColDef, setProjectMappingColDef] = useState<ColDef[]>(projectMappingColumnDefinition);
  const [projectMappingData, setProjectMappingData] = useState<ProjectMappingEntity[]>([]);
  const [originalData, setOriginalData] = useState<Map<string, ProjectMappingEntity>>(new Map());

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitClickCount, setSubmitClickCount] = useState(0);
  const [validationResults, setValidationResults] = useState<ValidationStatusEntity[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const hasChanges = useMemo(() => projectMappingData.some((item) => item.is_modified), [projectMappingData]);

  const allValidationsPassed = useMemo(() => validationResults.every((result) => result.validationStatus === 'success'), [validationResults]);

  const validationErrors = validationResults.filter((result) => result.validationStatus === 'error');
  const hasValidationErrors = validationErrors.length > 0;

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const getRowId = (params: GetRowIdParams) => params.data.row_id;

  useEffect(() => {
    if (masterCorpSegmentsStatus.status === LoadingStatus.Completed) {
      debouncedInitializeProjectMappingData();
    }
  }, [masterCorpSegmentsStatus, masterCorpSegments]);

  const initializeProjectMappingData = useCallback(async () => {
    try {
      logger.info(`Initializing Project Mapping by ${Alias}`);
      setIsLoading(true);
      const project = masterCorpSegments.find((corpSegment: MasterCorpSegments) => corpSegment.segment_name === eCorpSegmentNames.PROJECT);
      if (project) {
        const projectHierarchyData = project.segment_hierarchy_data_from_s3;
        const dropdownValues = convertToDropdownValues(projectHierarchyData, project.segment_name);
        const projectMappingData = dropdownValues.masterCorpSegmentDropdownValues.map(
          (dropdownValue, index) =>
            ({
              row_id: `${index}`,
              project_code: dropdownValue.label,
              project_description: dropdownValue.description,
              is_modified: false
            } as ProjectMappingEntity)
        );
        setProjectMappingData(projectMappingData);
        setOriginalData(new Map(projectMappingData.map((data) => [data.row_id!, { ...data }])));
      }
      setIsLoading(false);
    } catch (error: any) {
      logger.error(`Error initializing project mapping data for user ${Alias}. Error message: ${error.message}`, {
        alias: Alias,
        stack: error.stack,
        error: error
      });
      setIsLoading(false);
      displayFlashMessage(`Unable to load project mappings`, 'error', true);
    }
  }, [masterCorpSegments, displayFlashMessage]);

  const debouncedInitializeProjectMappingData = debounce(initializeProjectMappingData, 500);

  // Function to synchronize state with grid data
  const synchronizeGridData = useCallback(() => {
    const api = gridRef.current?.api;
    const updatedRowData: ProjectMappingEntity[] = [];
    api?.forEachNode((node) => {
      node.data && updatedRowData.push(node.data);
    });
    setProjectMappingData(updatedRowData);
  }, []);

  const debouncedSynchronizedData = debounce(synchronizeGridData, 500);

  const reloadDataAndClearAllStates = useCallback(async () => {
    setIsLoading(true);
    await dispatch(fetchCorpSegments()).unwrap();
    initializeProjectMappingData();
    setSubmitClickCount(0);
  }, [dispatch, initializeProjectMappingData]);

  const handleRefreshClick = () => {
    if (hasChanges) {
      setIsModalVisible(true);
    } else {
      reloadDataAndClearAllStates();
    }
  };

  const handleConfirmDiscard = () => {
    setIsModalVisible(false);
    reloadDataAndClearAllStates();
  };

  const handleCancelDiscard = () => {
    setIsModalVisible(false);
  };

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const { data: newData } = event;
      const originalRow = originalData.get(newData.row_id);
      if (originalRow) {
        const isModified = isProjectTypeRowModified(originalRow, newData);
        const updatedData: ProjectMappingEntity[] = projectMappingData
          .filter((item) => item.row_id === newData.row_id)
          .map((i) => {
            return { ...i, is_modified: isModified };
          });
        gridRef.current?.api?.applyTransaction({ update: updatedData });
      }

      debouncedSynchronizedData();
    },
    [projectMappingData, originalData]
  );

  const handlePaste = useCallback(() => {
    setProjectMappingData((prevData) => {
      return prevData.map((item) => {
        if (item.row_id !== null) {
          const originalRow = originalData.get(item.row_id);
          if (originalRow) {
            const isModified = isProjectTypeRowModified(originalRow, item);
            return { ...item, is_modified: isModified };
          }
        }
        return item;
      });
    });
  }, [originalData]);

  const validateData = useCallback(async () => {
    console.time('validateProjectMapping');
    const modifiedData = projectMappingData.filter((data) => data.is_modified);
    const results = await validateProjectMapping(modifiedData);
    setValidationResults(results);
    console.timeEnd('validateProjectMapping');
  }, [projectMappingData]);

  useEffect(() => {
    validateData();
  }, [projectMappingData, validateData]);

  const handleSubmit = useCallback(async () => {
    setSubmitClickCount((prevCount) => prevCount + 1);
    await validateData();
    if (!allValidationsPassed) {
      return;
    }

    setIsSubmitting(true);
    const inProgressMessageId = generateUniqueId();
    displayFlashMessage(ProjectMappingValidationMessages.SUBMISSION_IN_PROGRESS, 'info', false, inProgressMessageId);

    const modifiedRecords = projectMappingData.filter((row) => row.is_modified);

    if (modifiedRecords.length === 0) {
      displayFlashMessage('No changes to submit.', 'info', true);
      clearSpecificFlashMessage(inProgressMessageId);
      setIsSubmitting(false);
      return;
    }

    try {
      logger.info(`User ${Alias} is submitting changes for Project Mapping. Number of modified records: ${modifiedRecords.length}.`);
      const finalDataToSubmit = convertToProjectMutation(modifiedRecords, Alias);
      const response = await updateProjectMapping(finalDataToSubmit);
      const noOfRecordsUpdated = response?.updateAndExportProjectDetails?.numberOfRecordsUpdated as number;
      displayFlashMessage(ProjectMappingValidationMessages.SUBMISSION_SUCCESS(noOfRecordsUpdated), 'success', true);
      reloadDataAndClearAllStates();
    } catch (error: any) {
      logger.error(`User ${Alias} encountered an error while submitting project mapping data. Error message: ${error.message}`, {
        alias: Alias,
        stack: error.stack,
        error: error
      });
      displayFlashMessage(ProjectMappingValidationMessages.SUBMISSION_FAILED, 'error', true);
    } finally {
      setIsSubmitting(false);
      clearSpecificFlashMessage(inProgressMessageId);
    }
  }, [Alias, projectMappingData, validateData, allValidationsPassed, displayFlashMessage, clearSpecificFlashMessage, reloadDataAndClearAllStates]);

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<AdminConsoleSideNavigation />}
      breadcrumbs={<XPTBreadcrumbs items={getProjectBreadcrumbItems()} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <div className="xpt-app-layout-content">
          <ErrorBoundary
            FallbackComponent={() => (
              <Alert
                type="error"
                dismissible={false}
                visible={true}
                header="Error"
                action={<Button onClick={() => window.location.reload()}>Reload</Button>}
              >
                {'Unable to load data, try reloading once'}
              </Alert>
            )}
          >
            <ContentLayout header={<Header>{`Project Mappings`}</Header>}>
              <Container
                className="xpt-mapping-ag-grid-container"
                disableContentPaddings
                header={
                  <Header
                    actions={
                      <SpaceBetween size="m" direction="horizontal">
                        <Button iconName="refresh" onClick={handleRefreshClick} disabled={isSubmitting} />
                        <Button variant="primary" onClick={handleSubmit} disabled={isLoading || isSubmitting || !hasChanges}>
                          {'Submit'}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <SpaceBetween size="m" direction="horizontal">
                      {submitClickCount === 0 && hasChanges && (
                        <StatusIndicator type="info" colorOverride="blue">
                          {'Changes detected. Submit when done.'}
                        </StatusIndicator>
                      )}
                      {submitClickCount > 0 && hasValidationErrors
                        ? validationErrors.map((error, index) => (
                            <Popover
                              key={index}
                              dismissButton={false}
                              triggerType="text"
                              size="large"
                              content={
                                <div className="popover-content">
                                  {error.validationDefaultMessage && (
                                    <div>
                                      <strong>{error.validationDefaultMessage}</strong>
                                    </div>
                                  )}
                                </div>
                              }
                            >
                              <StatusIndicator type="error" colorOverride="red">
                                {error.validationMessage}
                              </StatusIndicator>
                            </Popover>
                          ))
                        : submitClickCount > 0 &&
                          hasChanges && (
                            <StatusIndicator type="info" colorOverride="blue">
                              {'Changes detected. Submit when done.'}
                            </StatusIndicator>
                          )}
                    </SpaceBetween>
                  </Header>
                }
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className={themeClassName} style={{ height: '100%', width: '100%' }}>
                    <AgGridReact<ProjectMappingEntity>
                      ref={gridRef}
                      rowData={projectMappingData}
                      columnDefs={projectMappingColDef}
                      defaultColDef={defaultProjectColDef}
                      onGridReady={onGridReady}
                      getRowId={getRowId}
                      onCellValueChanged={onCellValueChanged}
                      onPasteEnd={handlePaste}
                      suppressRowClickSelection={true}
                      enableAdvancedFilter={false}
                      alwaysMultiSort={true}
                      statusBar={StatusBarConfig}
                      rowBuffer={10}
                      rowModelType="clientSide"
                      rowHeight={30}
                      suppressContextMenu
                      enterNavigatesVerticallyAfterEdit
                      enterNavigatesVertically
                      suppressLastEmptyLineOnPaste
                      processCellForClipboard={processCellForClipboard}
                      processCellFromClipboard={processCellFromClipboard}
                    />
                  </div>
                )}
              </Container>
            </ContentLayout>
            <ConfirmDiscardModal visible={isModalVisible} onConfirm={handleConfirmDiscard} onCancel={handleCancelDiscard} />
          </ErrorBoundary>
        </div>
      }
    />
  );
};
