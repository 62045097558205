import { AppLayout, ContentLayout, Flashbar, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  businessGroupBaseBreadcrumbs,
  currentBusinessGroup,
  currentBusinessGroupName,
  currentBusinessGroupShortDesc,
  currentDataClassificationId
} from '../businessGroupSelectors';
import { getForecastTemplateBreadcrumbItems } from './forecast-utils/ForecastTemplateConfig';
import { ForecastTemplateContext } from './ForecastTemplateContext';
import ForecastTemplateFilterSelection from './ForecastTemplateFilterSelection';
import ForecastTemplateGrid from './ForecastTemplateGrid';
import ForecastTemplateStatusIndicator from './ForecastTemplateStatusIndicator';
import { fetchLineItemIdsArchived, setUnExpectedErrorOccurred } from './redux/forecastTemplateSlice';

export const ForecastTemplateMainPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);
  const dataClassificationId = useSelector(currentDataClassificationId);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  const [selectedPlanningCycleSeqId, setSelectedPlanningCycleSeqId] = useState<string | null>(null);

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages } = useFlashbar();

  // Memoized values to prevent unnecessary re-renders
  const memoizedDataClassificationId = useMemo(() => dataClassificationId, [dataClassificationId]);
  const memoizedBusinessGroupShortDesc = useMemo(() => businessGroupShortDesc, [businessGroupShortDesc]);
  const memoizedSelectedPlanningCycleSeqId = useMemo(() => selectedPlanningCycleSeqId, [selectedPlanningCycleSeqId]);

  const handleFetchArchivedLineItemIds = useCallback(() => {
    if (!memoizedDataClassificationId || !memoizedBusinessGroupShortDesc) {
      return;
    }

    dispatch(
      fetchLineItemIdsArchived({
        dataClassificationShortDesc: memoizedBusinessGroupShortDesc,
        dataClassificationId: memoizedDataClassificationId
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setUnExpectedErrorOccurred(false));
      })
      .catch(() => {
        dispatch(setUnExpectedErrorOccurred(true));
        displayFlashMessage(
          'Unable to load complete set of line item IDs. Try reloading the page or contact Support if the issue continues.',
          'error',
          false
        );
      });
  }, [dispatch, memoizedDataClassificationId, memoizedBusinessGroupShortDesc, memoizedSelectedPlanningCycleSeqId, displayFlashMessage]);

  useEffect(() => {
    handleFetchArchivedLineItemIds();
  }, [handleFetchArchivedLineItemIds, memoizedDataClassificationId, memoizedBusinessGroupShortDesc]);

  const breadcrumbsItems = useMemo(
    () => getForecastTemplateBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName),
    [businessGroupBaseBreadcrumb, businessGroupName]
  );

  const contextValue = useMemo(
    () => ({
      selectedPlanningCycleSeqId,
      setSelectedPlanningCycleSeqId,
      notificationMessage: displayFlashMessage,
      clearSpecificFlashMessage,
      clearAllMessages
    }),
    [selectedPlanningCycleSeqId, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages]
  );

  if (!businessGroup) {
    return <LoadingSpinner />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ForecastTemplateContext.Provider value={contextValue}>
        <AppLayout
          headerSelector="#h"
          navigation={<BusinessGroupSideNavigation />}
          toolsHide={true}
          breadcrumbs={<XPTBreadcrumbs items={breadcrumbsItems} />}
          notifications={<Flashbar stackItems items={flashbarItems} />}
          maxContentWidth={Number.MAX_VALUE}
          contentType="default"
          content={
            <ContentLayout
              header={
                <Header
                  actions={
                    <SpaceBetween size="xxxs" direction="horizontal">
                      {selectedPlanningCycleSeqId && <ForecastTemplateStatusIndicator />}
                    </SpaceBetween>
                  }
                >
                  {`Forecast Input`}
                </Header>
              }
            >
              <SpaceBetween size="xs" direction="vertical">
                <ForecastTemplateFilterSelection />
                <ForecastTemplateGrid />
              </SpaceBetween>
            </ContentLayout>
          }
        />
      </ForecastTemplateContext.Provider>
    </ErrorBoundary>
  );
};
