import { FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import { ColDef, GridReadyEvent, RowDataUpdatedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import {
  PlanningCycleInfo,
  XptReportExportFileDetails,
  XptReportRowDataStructured,
  XptVarianceReportGridData,
  XptVarianceReportRowData
} from 'src/models/XptReportingModels';
import { RootState } from 'src/store/store';
import { customCorpSegmentSort } from '../../admin-console/onboarding-business-groups/OnboardingFormUtils';
import { ReportGrid } from '../XptReportGrid';
import { DEFAULT_PIVOT_MODE, XptReportGridFixedFields } from '../XptReportGridConstants';
import { generateVarianceReportColumnDefinitions } from './XptVarianceReportColumnGenerator';
import { fetchCycleData, flattenXptVarianceReportRowData, generateVarianceReport, ROLL_UP_PERIOD } from './XptVarianceReportUtils';

const COLUMN_DEFINITION_DELAY_MS = 100;

interface XptVarianceReportGridProps {
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  reportGenerating: (isReportGenerating: boolean) => void;
  isGeneratingReport: boolean;
  currentPlanningCycleInfo: PlanningCycleInfo;
  comparisonPlanningCycleInfo: PlanningCycleInfo;
  selectedRollupPeriod: ROLL_UP_PERIOD;
  selectedPeriods: SelectProps.Options;
  exportFileDetails: XptReportExportFileDetails;
}

// Main component for rendering the variance report grid
export const XptVarianceReportGrid: React.FC<XptVarianceReportGridProps> = (props) => {
  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup)!;
  const dataClassificationShortDesc = currentBusinessGroup?.data_classification.data_classification_short_description;
  const gridStateKey = `UniqueGridStateKey-XptVarianceReport-${dataClassificationShortDesc}`;
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [xptReportData, setXptReportData] = useState<XptVarianceReportGridData[]>([]);

  const [generatingColumns, setGeneratingColumns] = useState<boolean>(false);

  const handleGridReady = (params: GridReadyEvent) => {
    params.api.refreshCells({ force: true });
  };

  const handleRowDataUpdated = (params: RowDataUpdatedEvent) => {
    params.api.refreshCells({ force: true });
  };

  const fetchVarianceReportData = async () => {
    const { currentPlanningCycleInfo, comparisonPlanningCycleInfo } = props;

    try {
      props.reportGenerating(true);

      logger.info(
        `Fetching variance report data for ${currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${currentPlanningCycleInfo.isSnapshot}) & ${comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${comparisonPlanningCycleInfo.isSnapshot})`
      );

      const corpSegmentOptionalFields = [...currentBusinessGroup.corp_segments]
        .sort(customCorpSegmentSort)
        .filter((corpSegment) => !corpSegment.corp_segment_required)
        .flatMap((segment) => [`${segment.corp_segment_name}`, `${segment.corp_segment_name} Description`]);

      const currentPlanningCycleAggregatedData: XptReportRowDataStructured[] = await fetchCycleData(
        currentPlanningCycleInfo,
        props.notificationMessage,
        props.selectedRollupPeriod
      );

      const comparisonPlanningCycleAggregatedData: XptReportRowDataStructured[] = await fetchCycleData(
        comparisonPlanningCycleInfo,
        props.notificationMessage,
        props.selectedRollupPeriod
      );

      const varianceReport: XptVarianceReportRowData[] = generateVarianceReport(
        currentPlanningCycleAggregatedData,
        comparisonPlanningCycleAggregatedData
      );

      const flattenedVarianceReport: XptVarianceReportGridData[] = flattenXptVarianceReportRowData(varianceReport, corpSegmentOptionalFields);
      setXptReportData(flattenedVarianceReport);
      gridRef?.current?.api.refreshCells({ force: true });
      props.reportGenerating(false);
    } catch (error: any) {
      logger.error(
        `Error while fetching variance report data for ${currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${currentPlanningCycleInfo.isSnapshot}) & ${comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${comparisonPlanningCycleInfo.isSnapshot})`,
        error
      );
      props.reportGenerating(false);
      props.notificationMessage('Unable to load variance report. Please try again later.', 'error', true);
    }
  };

  useEffect(() => {
    fetchVarianceReportData();
  }, [props.currentPlanningCycleInfo, props.comparisonPlanningCycleInfo, props.selectedRollupPeriod, props.selectedPeriods]);

  const fetchVarianceReportColumnDefinitions = async () => {
    try {
      setGeneratingColumns(true);

      logger.info(
        `Fetching variance report col defs for ${props.currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.currentPlanningCycleInfo.isSnapshot}) & ${props.comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.comparisonPlanningCycleInfo.isSnapshot})`
      );

      const colDefs = await generateVarianceReportColumnDefinitions(
        currentBusinessGroup,
        props.currentPlanningCycleInfo,
        props.comparisonPlanningCycleInfo,
        props.selectedRollupPeriod,
        props.selectedPeriods
      );
      setColumnDefinitions(colDefs);
    } catch (error: any) {
      logger.error(
        `Error while fetching variance report col defs for ${props.currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.currentPlanningCycleInfo.isSnapshot}) & ${props.comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.comparisonPlanningCycleInfo.isSnapshot})`,
        error
      );
      props.notificationMessage('Unable to load variance report columns. Please try again later.', 'error', true);
    } finally {
      setGeneratingColumns(false);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      await fetchVarianceReportColumnDefinitions();
    }, COLUMN_DEFINITION_DELAY_MS);
  }, [xptReportData]);

  const refreshReport = async () => {
    await fetchVarianceReportData();
    setTimeout(async () => {
      await fetchVarianceReportColumnDefinitions();
    }, COLUMN_DEFINITION_DELAY_MS);
  };

  return (
    <ReportGrid
      gridRef={gridRef}
      columnDefs={columnDefinitions}
      rowData={xptReportData}
      uniqueIdField={XptReportGridFixedFields.XptLineItemSeqId.value}
      isLoading={props.isGeneratingReport || generatingColumns}
      localStorageKey={gridStateKey}
      refreshReport={refreshReport}
      exportFileDetails={props.exportFileDetails}
      notificationMessage={props.notificationMessage}
      enablePivotMode={DEFAULT_PIVOT_MODE}
      onGridReady={handleGridReady}
      onRowDataUpdated={handleRowDataUpdated}
    />
  );
};
