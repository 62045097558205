import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { useRoleModifier } from 'src/features/auth/RoleModifier';
import { selectActiveBusinessGroups, selectBusinessGroupByName } from 'src/features/business-group/businessGroupSelectors';
import { fetchBusinessGroups, setCurrentBusinessGroup } from 'src/features/business-group/businessGroupSlice';
import { clearActualsDataAndFilterDropdownValues } from 'src/features/po-tagging/POTaggingSlice';
import { fetchMasterCorpSegmentDropdownValues } from 'src/store/slices/corpSegmentSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';

const XPTBusinessGroupWrapper: React.FC = () => {
  const { businessGroupName } = useParams<{ businessGroupName: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const businessGroupsLoaded = useSelector(
    (state: RootState) => state.businessGroupStore.businessGroups.filter((businessGroup) => businessGroup.item_metadata.is_active).length > 0
  );

  const activeBusinessGroups = useSelector(selectActiveBusinessGroups);
  const businessGroup = useSelector((state: RootState) => (businessGroupName ? selectBusinessGroupByName(businessGroupName)(state) : null));
  const userAuth = useAuth();
  const { setBusinessLeader, setBudgetOwnerRole } = useRoleModifier();

  useEffect(() => {
    if (!businessGroupsLoaded) {
      dispatch(fetchBusinessGroups());
    }
  }, [businessGroupsLoaded, dispatch]);

  useEffect(() => {
    const fetchDataAndSetMetadata = async () => {
      if (!businessGroupsLoaded || !businessGroupName || !businessGroup) {
        navigate(`/`, { replace: true });
        return;
      }

      try {
        const dataClassificationId = businessGroup.data_classification?.data_classification_id;
        if (!dataClassificationId) {
          throw new Error('No data classification ID found for the business group');
        }

        // Check if user is part of Business Leader group of selected Business Group
        const isBusinessLeader = userAuth.userLDAPGroups.includes(businessGroup.business_leaders_group.group_name);
        setBusinessLeader(isBusinessLeader);

        // Check if user is part of Budget Owner group of selected Business Group
        const isBudgetOwner = userAuth.userLDAPGroups.includes(businessGroup.business_owners_group.group_name);
        setBudgetOwnerRole(isBudgetOwner);

        dispatch(setCurrentBusinessGroup(businessGroup));
        dispatch(clearActualsDataAndFilterDropdownValues());
        dispatch(fetchMasterCorpSegmentDropdownValues());
      } catch (error) {
        console.error('Failed to fetch or set business group metadata:', error);
        navigate(`/`, { replace: true }); // Using replace: true to avoid navigating back to the error state
      }
    };

    fetchDataAndSetMetadata();

    return () => {
      dispatch(setCurrentBusinessGroup(null));
      setBudgetOwnerRole(false);

      // Determine if the user is part of at least 1 business leader groups
      const businessLeadersGroupNames = activeBusinessGroups.map((group) => group.business_leaders_group.group_name);
      const isBusinessLeader = businessLeadersGroupNames.some((groupName) => userAuth.userLDAPGroups.includes(groupName));
      setBusinessLeader(isBusinessLeader);
    };
  }, [businessGroupName, businessGroup, businessGroupsLoaded, dispatch, navigate]);

  if (!businessGroupsLoaded) {
    // Optionally, render a loading indicator while checking or an error message
    return <LoadingSpinner />;
  }

  return <Outlet />;
};

export default XPTBusinessGroupWrapper;
