import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FileUpload,
  FormField,
  Header,
  Modal,
  Popover,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { AgGridReact } from 'ag-grid-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { OperationType } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { fileUploadI18nStrings } from 'src/i18n-strings/file-upload';
import { ForecastTemplateColumns, ForecastTemplateDataValidationStatus } from 'src/models/ForecastModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { selectAllExpenseTypes, selectExpenseTypesForCurrentGroup } from 'src/store/selectors/xPTMapperSelector';
import { RootState } from 'src/store/store';
import { readExcelFileWithHeader } from 'src/utils/file-utils';
import { currentBusinessGroup, currentBusinessGroupShortDesc } from '../businessGroupSelectors';
import { ForecastGridFixedFields } from './forecast-utils/ForecastGridConstants';
import { submitForecastData } from './forecast-utils/ForecastTemplateDataSubmission';
import * as DataValidations from './forecast-utils/ForecastTemplateDataValidations';
import { getForecastTemplateHeaderInfo, prepareForecastTemplateUploadData } from './forecast-utils/ForecastTemplateUtils';
import ForecastValidationMessages from './forecast-utils/ValidationMessages';
import { useForecastTemplateContext } from './ForecastTemplateContext';
import ForecastTemplatePlanningCycleInfo from './ForecastTemplatePlanningCycleInfo';
import { planningCycleSelector } from './redux/forecastTemplateSelectors';
import { generateRequestId } from 'src/utils/generic-utilities';

const AcceptedFileFormForExcel = {
  ConstraintMessage: 'Upload Excel (.xlsx) file only.',
  FileFormats: '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

interface ForecastTemplateFileUploadProps {
  gridRef: React.RefObject<AgGridReact<any>>;
  showModal: boolean;
  onCancel: () => void;
  onSuccessConfirm: () => void;
}

export const ForecastTemplateFileUpload: React.FC<ForecastTemplateFileUploadProps> = ({ gridRef, showModal, onCancel, onSuccessConfirm }) => {
  const userAuth = useAuth();
  const userAlias = userAuth.Alias;
  const isAdminUser = userAuth.isDev || userAuth.isAdmin || userAuth.isBusinessLeader;

  const { selectedPlanningCycleSeqId } = useForecastTemplateContext();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileModifiedData, setUploadedFileModifiedData] = useState<any[]>([]);
  const [isUploadedFileValid, setIsUploadedFileValid] = useState<boolean>(false);
  const [fileValidationStatus, setFileValidationStatus] = useState<ForecastTemplateDataValidationStatus>(DataValidations.INITIAL_VALIDATION_STATUS);
  const [fileSubmitStatus, setFileSubmitStatus] = useState<ValidationStatusEntity>(DataValidations.INITIAL_SUBMIT_STATUS);

  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc) || 'default';
  const selectedPlanningCycle = useSelector((state: RootState) =>
    planningCycleSelector(state, businessGroupShortDesc, selectedPlanningCycleSeqId || '')
  );
  const forecastTemplateCompleteData = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateCompleteData);
  const corpSegmentDropdownValues = useSelector((state: RootState) => state.corpSegmentsStore.masterCorpSegmentDropdownValues);
  const lineItemIdsArchived = useSelector((state: RootState) => state.forecastTemplateStore.lineItemIdsArchived);

  const masterBusinessSegments = useSelector((state: RootState) => state.corpSegmentsStore.masterBusinessSegments);
  const expenseTypesForCurrentGroup = useSelector(selectExpenseTypesForCurrentGroup);
  const expenseTypesCompleteList = useSelector(selectAllExpenseTypes);

  const { accountBudgetTypeMapping, accountBudgetTypeMappingStatus } = useSelector((state: RootState) => state.xPTMappingStore);

  const [forecastTemplateColumns, setForecastTemplateColumns] = useState<ForecastTemplateColumns>({} as ForecastTemplateColumns);
  const [requestId, setRequestId] = useState<string>();

  // Reset uploaded file and validation status when modal visibility changes
  useEffect(() => {
    resetFileAndValidationStatus();
    setUploadedFile(null);
  }, [showModal]);

  useEffect(() => {
    handleOnFileUpload();
  }, [uploadedFile]);

  useEffect(() => {
    if (businessGroup && selectedPlanningCycle) {
      const forecastTemplateColumnDetails: ForecastTemplateColumns = getForecastTemplateHeaderInfo(businessGroup, selectedPlanningCycle);
      setForecastTemplateColumns(forecastTemplateColumnDetails);
    }
  }, [businessGroup, selectedPlanningCycle]);

  const resetFileAndValidationStatus = () => {
    logger.debug('Resetting file upload state & validations.');
    setFileValidationStatus(DataValidations.INITIAL_VALIDATION_STATUS);
    setUploadedFileModifiedData([]);
    setIsUploadedFileValid(false);
  };

  const handleOnFileUpload = async () => {
    if (uploadedFile && businessGroup && selectedPlanningCycle && corpSegmentDropdownValues) {
      const uploadRequestId = generateRequestId();
      setRequestId(uploadRequestId);

      logger.debug(`Step 1: File upload initiated for ${selectedPlanningCycle.scenario_year} in ${businessGroupShortDesc}`);
      try {
        const { headerRow, fileData } = await readExcelFileWithHeader(uploadedFile);

        logger.info(
          `Step 2: User ${userAlias} uploaded a file for ${selectedPlanningCycle.scenario_year} in ${businessGroupShortDesc} with total number of records: ${fileData.length}`
        );
        validateFileData(headerRow, fileData);
      } catch (error: any) {
        logger.error('Error while reading the file:', error);
        setFileValidationStatus({
          ...DataValidations.INITIAL_VALIDATION_STATUS,
          HeadersMatching: {
            colorOverride: 'red',
            validationStatus: 'error',
            validationMessage: ForecastValidationMessages.ERROR_READING_FILE,
            validationDefaultMessage: ForecastValidationMessages.ERROR_READING_FILE,
            validationErrorDetails: [{ message: ForecastValidationMessages.ERROR_READING_FILE }]
          }
        });
      }
    }
  };

  const validateFileData = async (headerRow: string[], fileData: any[]) => {
    if (uploadedFile && businessGroup && selectedPlanningCycle && corpSegmentDropdownValues && forecastTemplateColumns) {
      resetFileAndValidationStatus();
      try {
        logger.debug(
          `Step 2: Validating file data for ${selectedPlanningCycle.scenario_year} in ${businessGroupShortDesc} with total number of records: ${fileData.length}`
        );
        const headersToBe = forecastTemplateColumns.forecastTemplateImportFileHeader;
        const headerValidation = await validateHeadersStep(headerRow, headersToBe);

        if (headerValidation.validationStatus === 'error') {
          logger.debug(
            `Step 3: Headers validation failed for ${selectedPlanningCycle.scenario_year} in ${businessGroupShortDesc}. No further validations.`
          );
          return;
        }

        logger.debug(
          `Step 3: Headers validation passed for ${selectedPlanningCycle.scenario_year} in ${businessGroupShortDesc}. Proceeding with further validations.`
        );

        console.time('transformedRows');
        const transformedRows = DataValidations.transformUploadFileDisplayHeaderToModel(fileData, forecastTemplateColumns);
        logger.info(`Transformed Rows with ID fields as it is with length ${transformedRows.length}`);
        console.timeEnd('transformedRows');
        if (transformedRows.length !== fileData.length) {
          logger.error('Mismatch in the number of rows after transformation.');
          setFileValidationStatus({
            ...DataValidations.INITIAL_VALIDATION_STATUS,
            HeadersMatching: {
              colorOverride: 'red',
              validationStatus: 'error',
              validationMessage: ForecastValidationMessages.TRANSFORMATION_FAILED,
              validationDefaultMessage: ForecastValidationMessages.TRANSFORMATION_FAILED,
              validationErrorDetails: [{ message: ForecastValidationMessages.TRANSFORMATION_FAILED_DETAILS }]
            }
          });
          return;
        }

        console.time('getModifiedRowsFromExcelFile');
        const modifiedRows = DataValidations.getModifiedRowsFromExcelFile(
          forecastTemplateColumns,
          transformedRows,
          forecastTemplateCompleteData,
          userAlias,
          selectedPlanningCycle.scenario_seq_id,
          accountBudgetTypeMapping,
          lineItemIdsArchived
        );
        logger.info(`Modified Rows with length ${modifiedRows.length}`);
        console.timeEnd('getModifiedRowsFromExcelFile');

        if (modifiedRows.length === 0) {
          logger.debug('No modified rows found. Skipping further validations.');
          skipValidationSteps();
          return;
        }

        logger.info(`Identified ${modifiedRows.length} modified rows. Proceeding with further validations.`);
        const modifiedTransformed = await validateRemainingSteps(forecastTemplateColumns, modifiedRows);
        setUploadedFileModifiedData(modifiedTransformed);
      } catch (error: any) {
        logger.error('Error during file data validation:', error);
        handleValidationError(error);
      }
    }
  };

  const validateHeadersStep = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
    const headerValidation = await DataValidations.validateHeaders(headerRow, expectedHeaders);

    if (headerValidation.validationStatus === 'error') {
      setIsUploadedFileValid(false);
      skipHeader(setFileValidationStatus, headerValidation);
    } else {
      setIsUploadedFileValid(true);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: headerValidation
      }));
    }

    return headerValidation;
  };

  const skipValidationSteps = () => {
    setFileValidationStatus(DataValidations.NO_MODIFIED_ROWS);
  };

  const validateRemainingSteps = async (forecastTemplateColumns: ForecastTemplateColumns, modifiedRows: any[]) => {
    inProgressValidations(setFileValidationStatus);

    const { transformedData: transformedModifiedRows, validationStatus: forecastMonthValidationStatus } =
      await DataValidations.transformForecastMonths(modifiedRows, forecastTemplateColumns);

    const transformedNewRows = transformedModifiedRows.filter((row) => row[ForecastGridFixedFields.IsNewFERow.value]);

    console.time('validateRemainingSteps');
    const validations = await Promise.all([
      DataValidations.transformForecastMonths(modifiedRows, forecastTemplateColumns),
      DataValidations.validateMandatoryFields(transformedModifiedRows, forecastTemplateColumns),
      DataValidations.validateNonEditableFields(transformedModifiedRows, forecastTemplateColumns, forecastTemplateCompleteData, isAdminUser),
      DataValidations.validateSegments(
        transformedModifiedRows,
        forecastTemplateColumns,
        corpSegmentDropdownValues,
        masterBusinessSegments,
        expenseTypesForCurrentGroup,
        expenseTypesCompleteList
      ),
      DataValidations.validateUnAuthorizedRows(transformedModifiedRows, userAlias, isAdminUser),
      DataValidations.validateDuplicateRecords(transformedModifiedRows, forecastTemplateColumns),
      DataValidations.validateDuplicateRecordsWhileUpload(transformedNewRows, forecastTemplateColumns, forecastTemplateCompleteData),
      DataValidations.validateLineItemIdsWithArchived(transformedNewRows, forecastTemplateColumns, lineItemIdsArchived)
    ]);

    console.timeEnd('validateRemainingSteps');
    setFileValidationStatus({
      HeadersMatching: {
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      ForecastMonthValidation: validations[0].validationStatus,
      MandatoryFieldValidation: validations[1],
      NonEditableFieldValidations: validations[2],
      SegmentsValidation: validations[3],
      UnAuthorizedRows: validations[4],
      DuplicateRecordValidation: validations[5],
      RepeatedRecordValidation: validations[6],
      ArchivedLineItemIdValidation: validations[7]
    });

    return transformedModifiedRows;
  };

  const handleValidationError = (error: any) => {
    logger.error('Validation error:', error);
    setIsUploadedFileValid(false);
    setFileValidationStatus((prevState) => ({
      ...prevState,
      HeadersMatching: {
        validationStatus: 'error',
        colorOverride: 'red',
        validationMessage: ForecastValidationMessages.VALIDATION_ERROR,
        validationDefaultMessage: ForecastValidationMessages.VALIDATION_ERROR,
        validationErrorDetails: [error.message]
      }
    }));
  };

  const isAllValidationsPassed = () => {
    return (
      fileValidationStatus.HeadersMatching.validationStatus === 'success' &&
      fileValidationStatus.MandatoryFieldValidation.validationStatus === 'success' &&
      fileValidationStatus.UnAuthorizedRows.validationStatus === 'success' &&
      fileValidationStatus.SegmentsValidation.validationStatus === 'success' &&
      fileValidationStatus.DuplicateRecordValidation.validationStatus === 'success' &&
      fileValidationStatus.RepeatedRecordValidation.validationStatus === 'success'
    );
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (businessGroup && selectedPlanningCycle) {
      const forecastTemplateDataForUpload = prepareForecastTemplateUploadData(uploadedFileModifiedData, selectedPlanningCycle, businessGroup);
      setFileSubmitStatus({
        validationMessage: 'Request in progress',
        validationStatus: 'loading',
        colorOverride: 'blue',
        validationDefaultMessage: '',
        validationErrorDetails: []
      });

      try {
        await submitForecastData({
          userAlias,
          businessGroup,
          selectedPlanningCycle,
          forecastTemplateDataForUpload,
          submitOperationType: OperationType.FILE_UPLOAD
        });

        setFileSubmitStatus({
          validationMessage: XptMessages.FORECAST_UPDATE_SUCCESS(selectedPlanningCycle.scenario_year),
          validationStatus: 'success',
          colorOverride: 'green',
          validationDefaultMessage: '',
          validationErrorDetails: []
        });
      } catch (error: any) {
        logger.error('Error during forecast data submission:', error);
        setFileSubmitStatus({
          validationMessage: XptMessages.FORECAST_UPDATE_FAILED,
          validationStatus: 'error',
          colorOverride: 'red',
          validationDefaultMessage: XptMessages.FORECAST_UPDATE_FAILED,
          validationErrorDetails: [error.message]
        });
      }
    } else {
      logger.error('Business group or planning cycle data is missing.');
    }
  };

  return (
    <Modal
      onDismiss={(e) => {
        if (fileSubmitStatus.validationStatus !== 'loading') onCancel();
      }}
      visible={showModal}
      size="large"
      footer={
        <Box>
          <Box float="left">
            {fileSubmitStatus.validationMessage !== 'Not Initiated' && (
              <StatusIndicator type={fileSubmitStatus.validationStatus} colorOverride={fileSubmitStatus.colorOverride}>
                {fileSubmitStatus.validationMessage}
              </StatusIndicator>
            )}
          </Box>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {fileSubmitStatus.validationStatus !== 'success' && (
                <>
                  <Button variant="link" onClick={handleCancel} disabled={fileSubmitStatus.validationStatus === 'loading'}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!isAllValidationsPassed() || fileSubmitStatus.validationStatus === 'loading'}
                  >
                    Submit
                  </Button>
                </>
              )}
              {fileSubmitStatus.validationStatus === 'success' && (
                <Button variant="primary" onClick={onSuccessConfirm}>
                  Close
                </Button>
              )}
            </SpaceBetween>
          </Box>
        </Box>
      }
      header={
        <Header variant="h3">{`Upload Forecast Template for ${selectedPlanningCycle?.scenario.scenario_name} (${selectedPlanningCycle?.scenario_year})`}</Header>
      }
    >
      <ColumnLayout columns={2}>
        <FormField label="" description="">
          {fileSubmitStatus.validationStatus !== 'success' && (
            <FileUpload
              onChange={({ detail }) => setUploadedFile(detail.value[0])}
              value={uploadedFile ? [uploadedFile] : []}
              i18nStrings={fileUploadI18nStrings()}
              invalid={!isUploadedFileValid}
              accept={AcceptedFileFormForExcel.FileFormats}
              constraintText={AcceptedFileFormForExcel.ConstraintMessage}
              ariaRequired
              multiple={false}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={1}
            />
          )}
          <Box padding={{ top: 's' }}>
            <SpaceBetween size="m" direction="vertical">
              {Object.keys(fileValidationStatus).map((key, index) => {
                const validationStatus: ValidationStatusEntity = fileValidationStatus[key as keyof ForecastTemplateDataValidationStatus];
                return (
                  <Fragment key={index}>
                    {validationStatus.validationStatus !== 'error' && (
                      <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                        {validationStatus.validationMessage}
                      </StatusIndicator>
                    )}
                    {validationStatus.validationStatus === 'error' && (
                      <Popover
                        header={validationStatus.validationDefaultMessage}
                        size="large"
                        content={
                          <Box>
                            <ul>
                              {validationStatus.validationErrorDetails.map((details, index) => (
                                <li key={index}>{details.message}</li>
                              ))}
                            </ul>
                          </Box>
                        }
                      >
                        <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                          {validationStatus.validationMessage}
                        </StatusIndicator>
                      </Popover>
                    )}
                  </Fragment>
                );
              })}
            </SpaceBetween>
          </Box>
        </FormField>

        {selectedPlanningCycle && forecastTemplateColumns && (
          <Container variant="stacked">
            <ForecastTemplatePlanningCycleInfo selectedPlanningCycle={selectedPlanningCycle} forecastTemplateColumns={forecastTemplateColumns} />
          </Container>
        )}
      </ColumnLayout>
    </Modal>
  );
};

const skipHeader = (
  setFileValidationStatus: React.Dispatch<React.SetStateAction<ForecastTemplateDataValidationStatus>>,
  headerValidation: ValidationStatusEntity
) => {
  setFileValidationStatus((prevState) => ({
    ...prevState,
    HeadersMatching: {
      ...headerValidation,
      validationStatus: 'error'
    },
    ForecastMonthValidation: {
      ...prevState.ForecastMonthValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    MandatoryFieldValidation: {
      ...prevState.MandatoryFieldValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    NonEditableFieldValidations: {
      ...prevState.NonEditableFieldValidations,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    UnAuthorizedRows: {
      ...prevState.UnAuthorizedRows,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    SegmentsValidation: {
      ...prevState.SegmentsValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    DuplicateRecordValidation: {
      ...prevState.DuplicateRecordValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    RepeatedRecordValidation: {
      ...prevState.RepeatedRecordValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    ArchivedLineItemIdValidation: {
      ...prevState.ArchivedLineItemIdValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    }
  }));
};

const inProgressValidations = (setFileValidationStatus: React.Dispatch<React.SetStateAction<ForecastTemplateDataValidationStatus>>) => {
  setFileValidationStatus((prevState) => ({
    ...prevState,
    HeadersMatching: {
      ...prevState.HeadersMatching,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.HEADER_VALIDATION_N_PROGRESS
    },
    ForecastMonthValidation: {
      ...prevState.ForecastMonthValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_IN_PROGRESS
    },
    MandatoryFieldValidation: {
      ...prevState.MandatoryFieldValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_IN_PROGRESS
    },
    NonEditableFieldValidations: {
      ...prevState.NonEditableFieldValidations,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_IN_PROGRESS
    },
    UnAuthorizedRows: {
      ...prevState.UnAuthorizedRows,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_IN_PROGRESS
    },
    SegmentsValidation: {
      ...prevState.SegmentsValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_IN_PROGRESS
    },
    DuplicateRecordValidation: {
      ...prevState.DuplicateRecordValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.DUPLICATE_RECORDS_VALIDATION_IN_PROGRESS
    },
    RepeatedRecordValidation: {
      ...prevState.RepeatedRecordValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.EXISTING_RECORDS_VALIDATION_IN_PROGRESS
    },
    ArchivedLineItemIdValidation: {
      ...prevState.ArchivedLineItemIdValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.ARCHIVED_LINE_ITEM_ID_VALIDATION_IN_PROGRESS
    }
  }));
};
