import { SelectProps, StatusIndicatorProps } from '@amzn/awsui-components-react';
import { BusinessGroupEntity } from './AppContextModels';
import { PlanningCycle } from './PlanningCycleModel';
import { ForecastGridFixedFields } from 'src/features/business-group/forecast-template-v2/forecast-utils/ForecastGridConstants';
import { ValidationStatusEntity } from './XptGenericModels';

export interface ForecastInputDetails {
  data_classification_id: number;
  scenario_seq_id: number;
  s3_bucket_name: string;
  s3_region: string;
  s3_update_file_key: string;
  updated_at: string;
  updated_by: string;
  operation_type: string;
}

export interface ForecastTemplateContext {
  [businessGroupShortDesc: string]: ForecastTemplatePlanningCycleFilters | null;
}

export interface ForecastTemplatePlanningCycleFilters {
  [planningCycleSeqId: string]: ForecastTemplateFilters | null;
}

export interface ForecastTemplateFilters {
  planningCycle: PlanningCycle | null | undefined;
  corpSegmentSelections: CorpSegmentFilterSelection;
}

export interface CorpSegmentFilterSelection {
  [corpSegmentKey: string]: SelectProps.Options;
}

export type DropdownStatusType = 'pending' | 'loading' | 'finished' | 'error';

export interface ForecastTemplateCorpSegmentDropdowns {
  key: number;
  segmentKey: string;
  displayName: string;
  isRequired: boolean;
  isMultiSelect: boolean;
  fieldDropdownOptions: SelectProps.Options;
  loadingStatus: DropdownStatusType;
}

export interface FetchTemplateColumnDataParams {
  relevantBusinessGroupMetadata: BusinessGroupEntity;
  relevantPlanningCyclesActive: PlanningCycle[];
  filterSelection: ForecastTemplateFilters;
  isLocked: boolean;
}

export interface ForecastRowDataStructured {
  xpt_line_item_seq_id: number;
  xpt_line_item_id: string;
  scenario_seq_id: number;

  is_new: boolean;

  budget_owner: string;
  budget_type: string | null;

  bus_segments: any[];
  corp_segments: any[];

  actual_months: any[];
  forecast_months: any[];

  updated_at: string;
  updated_by: string;
}

export interface LineItemIdArchived {
  xpt_line_item_seq_id: number;
  xpt_line_item_id: string;
  bus_segments: any[];
  corp_segments: any[];
}

export interface ForecastGridRowData {
  // FE metadata fields
  [ForecastGridFixedFields.RowId.value]: string;
  [ForecastGridFixedFields.IsNewFERow.value]: boolean;
  [ForecastGridFixedFields.IsTouched.value]: boolean;
  [ForecastGridFixedFields.IsEdited.value]: boolean;
  [ForecastGridFixedFields.IsActive.value]: boolean;
  [ForecastGridFixedFields.IsSegmentEdited.value]: boolean;

  [ForecastGridFixedFields.XptLineItemId.value]: string | null;
  [ForecastGridFixedFields.XptLineItemSeqId.value]: number | null;
  [ForecastGridFixedFields.ScenarioSeqId.value]: number | null;
  [ForecastGridFixedFields.IsNew.value]: boolean;
  
  [ForecastGridFixedFields.BudgetOwner.value]: string;
  [ForecastGridFixedFields.BudgetType.value]: string | null;
  [ForecastGridFixedFields.UpdatedAt.value]: string;
  [ForecastGridFixedFields.UpdatedBy.value]: string;
  [key: string]: any;
}

export interface ForecastTemplateDataState {
  dataStatus: DataStatus;
}

export type DataStatusType = 'NotLoaded' | 'Latest' | 'Modified' | 'Outdated';

export type DataStatus = {
  status: DataStatusType;
  statusDisplayName: string;
  statusType: StatusIndicatorProps.Type;
  statusColor: StatusIndicatorProps.Color;
  tooltip: string;
};

export interface OptionDropdown {
  label: string;
  value: string;
  description?: string;
}

export interface ForecastTemplateMasterCorpSegmentDropdownValues {
  masterCorpSegmentDisplayName: string;
  masterCorpSegmentDropdownValues: OptionDropdown[];
}

export interface ForecastTemplateColumns {
  forecastTemplateImportFileHeader: string[];
  forecastTemplateExportFileHeader: string[];
  mandatoryFields: string[];
  corpAndBussSegmentsMandatoryFields: string[];
  modifiedCheckFields: string[];
  corpSegmentOptionalFields: string[];
  corpSegmentMandatoryFields: string[];
  corpSegmentColumns: string[];
  businessSegmentColumns: string[];
  businessSegmentMandatoryFields: string[];
  businessSegmentDropdownFields: string[];
  actualMonthColumnIds: string[];
  actualMonthsDisplayFormat: string[];
  forecastMonthColumnsIds: string[];
  forecastMonthsDisplayFormat: string[];
  addNewRowColumns: string[];
}

export interface TransformedRowItem {
  corp_segments: string[];
  bus_segments: string[];
  forecast_months: { [key: string]: any }[];
  [key: string]: any;
}

export const VALIDATION_NOT_INITIATED: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export const VALIDATION_STARTED: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'loading',
  validationMessage: 'Validating',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export interface ForecastTemplateDataValidationStatus extends Record<string, any> {
  HeadersMatching: ValidationStatusEntity;
  ForecastMonthValidation: ValidationStatusEntity;
  MandatoryFieldValidation: ValidationStatusEntity;
  NonEditableFieldValidations: ValidationStatusEntity;
  UnAuthorizedRows: ValidationStatusEntity;
  SegmentsValidation: ValidationStatusEntity;
  DuplicateRecordValidation: ValidationStatusEntity;
  RepeatedRecordValidation: ValidationStatusEntity;
  ArchivedLineItemIdValidation: ValidationStatusEntity;
}
