import { Alert, Button, Container, ContentLayout, Flashbar, Header, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { CellValueChangedEvent, ColDef, GetRowIdParams, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { updateUsefulLifeMappings } from 'src/api/app-sync-services';
import { ConfirmDiscardModal } from 'src/components/common/ConfirmDiscardModal';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { useGridState } from 'src/hooks/useGridState';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { UsefulLifeMappingEntity, UsefulLifeMappingGridEntity } from 'src/models/xPTMappingModels';
import { selectAllExpenseTypesWithCAPEX } from 'src/store/selectors/xPTMapperSelector';
import { fetchCostCenterDropdownValuesOfAllBusinessGroups } from 'src/store/slices/corpSegmentSlice';
import { fetchExpenseTypes, fetchUsefulLifeDetails } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  addNewRowToGrid,
  ensureRowVisibleAndFocusFirstCell,
  processCellForClipboard,
  processCellFromClipboard,
  StatusBarConfig
} from 'src/utils/ag-grid-utils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId } from 'src/utils/generic-utilities';
import AdminConsoleSideNavigation from '../AdminConsoleSideNavigation';
import { UsefulLifeValidationMessages, validateUsefulLifeData } from './UsefulLifeDataValidations';
import { getUsefulLifeBreadcrumbItems, isUsefulLifeRowModified, usefulLifeDetailsColumnDefs } from './UsefulLifeMappingConfig';

export const UsefulLifeMapping: React.FC = () => {
  const appLayout = useRef<any>();
  const { Alias } = useAuth();
  const dispatch = useAppDispatch();

  const usefulLifeMappings = useSelector((state: RootState) => state.xPTMappingStore.usefulLifeMappings);
  const usefulLifeStatus = useSelector((state: RootState) => state.xPTMappingStore.usefulLifeStatus);
  const { masterCostCenterDropdownValuesForAllBusinessGroups } = useSelector((state: RootState) => state.corpSegmentsStore);
  const expenseTypeStatus = useSelector((state: RootState) => state.xPTMappingStore.expenseTypeStatus);
  const expenseTypeMappingsWithCAPEX = useSelector(selectAllExpenseTypesWithCAPEX);

  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);

  const gridRef = useRef<AgGridReact>(null);
  const gridStateKey = 'UniqueGridStateKey-Useful-Life-Mapping';
  const { restoreGridState } = useGridState(gridRef, gridStateKey);

  const [columnDefinition, setColumnDefinition] = useState<ColDef[]>([]);
  const [usefulLifeGridData, setUsefulLifeGridData] = useState<UsefulLifeMappingGridEntity[]>([]);
  const [originalData, setOriginalData] = useState<Map<string, UsefulLifeMappingGridEntity>>(new Map());
  const [selectedRows, setSelectedRows] = useState<UsefulLifeMappingGridEntity[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  const [removedRecords, setRemovedRecords] = useState<UsefulLifeMappingEntity[]>([]);
  const [validationResults, setValidationResults] = useState<ValidationStatusEntity[]>([]);
  const [submitClickCount, setSubmitClickCount] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [expenseTypeList, setExpenseTypeList] = useState<string[]>([]);
  const [costCenterList, setCostCenterList] = useState<string[]>([]);

  const hasChanges = useMemo(() => {
    return usefulLifeGridData.some((item) => item.isModified) || removedRecords.length > 0;
  }, [usefulLifeGridData, removedRecords]);

  const allValidationsPassed = useMemo(() => {
    return validationResults.every((result) => result.validationStatus === 'success');
  }, [validationResults]);

  useEffect(() => {
    fetchUsefulLifeData();
  }, [dispatch]);

  const fetchUsefulLifeData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchUsefulLifeDetails()).unwrap();
      await dispatch(fetchExpenseTypes()).unwrap();
      await dispatch(fetchCostCenterDropdownValuesOfAllBusinessGroups()).unwrap();
    } catch (error: any) {
      logger.error('Unable to load Useful Life Mapping data', error);
      displayFlashMessage(`Unable to load Useful Life Mapping data`, 'error', true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, displayFlashMessage]);

  useEffect(() => {
    setCostCenterList(masterCostCenterDropdownValuesForAllBusinessGroups);
  }, [masterCostCenterDropdownValuesForAllBusinessGroups]);

  useEffect(() => {
    if (expenseTypeStatus === LoadingStatus.Completed) {
      try {
        setExpenseTypeList(expenseTypeMappingsWithCAPEX);
      } catch (error: any) {
        logger.error('Unable to load Expense Types in Useful Life Mappings', error);
        displayFlashMessage(`Unable to load Expense Types`, 'error', true);
        setExpenseTypeList([]);
      }
    }
  }, [expenseTypeStatus, expenseTypeMappingsWithCAPEX]);

  // Update grid data when mappings change
  useEffect(() => {
    if (usefulLifeStatus === LoadingStatus.Completed) {
      const dataWithModifiedFlag = usefulLifeMappings
        .map((item) => ({ ...item, row_id: generateUniqueId(), isModified: false }))
        .sort((a, b) => a.cost_center_code.localeCompare(b.cost_center_code));
      setUsefulLifeGridData(dataWithModifiedFlag);
      setOriginalData(new Map(dataWithModifiedFlag.map((row) => [row.row_id, { ...row }])));
    }
  }, [usefulLifeMappings, usefulLifeStatus]);

  useEffect(() => {
    setColumnDefinition(usefulLifeDetailsColumnDefs(costCenterList, expenseTypeList));
  }, [costCenterList, expenseTypeList]);

  const getRowId = (params: GetRowIdParams) => params.data.row_id;

  // Restores grid state
  const restoreGrid = () => {
    setTimeout(() => {
      restoreGridState();
      gridRef.current?.api.refreshCells();
    }, 0);
  };

  // Handles grid ready event
  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      restoreGrid();
    },
    [restoreGridState]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
      editable: true,
      filterParams: {
        applyMiniFilterWhileTyping: true
      },
      headerClass: 'header-center'
    }),
    []
  );

  // Function to synchronize state with grid data
  const synchronizeGridData = useCallback(() => {
    const api = gridRef.current?.api;
    const updatedRowData: UsefulLifeMappingGridEntity[] = [];
    api?.forEachNode((node) => {
      updatedRowData.push(node.data);
    });
    setUsefulLifeGridData(updatedRowData);
  }, []);

  const debouncedSynchronizedData = debounce(synchronizeGridData, 500);

  // Add a new record
  const handleAddNewRow = useCallback(() => {
    const newUsefulLife: UsefulLifeMappingGridEntity = {
      row_id: generateUniqueId(),
      useful_life_id: null,
      cost_center_code: '',
      expense_type: '',
      useful_life: null,
      is_active: true,
      created_at: getCurrentUTCTimeInISO(),
      created_by: Alias,
      updated_at: getCurrentUTCTimeInISO(),
      updated_by: Alias,
      isModified: true
    };

    // Add the new row at the top of the grid and get the response from the transaction
    const transactionResult = addNewRowToGrid(gridRef, newUsefulLife, 0);

    // Ensure the newly added row is visible and set focus to the first cell
    if (transactionResult?.add) {
      const addedRowNode = transactionResult.add[0];
      if (addedRowNode && addedRowNode.rowIndex != null) {
        ensureRowVisibleAndFocusFirstCell(gridRef, addedRowNode.rowIndex);
      }
    }

    debouncedSynchronizedData();
  }, [Alias]);

  // Remove selected rows
  const handleDeleteSelectedRows = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() as UsefulLifeMappingGridEntity[];

    if (selectedRows && selectedRows.length > 0) {
      const removedRows = selectedRows.map((row) => ({
        ...row,
        isModified: true,
        is_active: false,
        updated_at: getCurrentUTCTimeInISO(),
        updated_by: Alias
      }));

      // Update state to reflect the removed rows
      setRemovedRecords((prevRemovedRows) => [...prevRemovedRows, ...removedRows.filter((row) => row.useful_life_id)]);

      // Refresh the grid
      gridRef.current?.api.applyTransaction({ remove: selectedRows });

      debouncedSynchronizedData();
    }
  };

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    setSelectedRows(event.api.getSelectedRows() as UsefulLifeMappingGridEntity[]);
  }, []);

  // Tracks changes in grid cells
  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const { data: newData } = event;
      const originalRow = originalData.get(newData.row_id);

      if (originalRow) {
        const isModified = isUsefulLifeRowModified(originalRow, newData);

        const updatedData: UsefulLifeMappingGridEntity[] = usefulLifeGridData.map((item) =>
          item.row_id === newData.row_id ? { ...item, isModified: isModified } : item
        );

        gridRef.current?.api?.applyTransaction({ update: updatedData });
      }

      debouncedSynchronizedData();
    },
    [usefulLifeGridData, originalData]
  );

  const handlePasteEnd = useCallback(() => {
    setUsefulLifeGridData((prevData) => {
      return prevData.map((item) => {
        if (item.useful_life_id !== null) {
          const originalRow = originalData.get(item.row_id);
          if (originalRow) {
            const isModified = isUsefulLifeRowModified(originalRow, item);
            return { ...item, isModified: isModified };
          }
        }
        return item;
      });
    });
  }, [originalData]);

  // Validate data
  const validateData = useCallback(async () => {
    const results = await validateUsefulLifeData(usefulLifeGridData);
    setValidationResults(results);
  }, [usefulLifeGridData]);

  useEffect(() => {
    validateData();
  }, [usefulLifeGridData, validateData]);

  const validationErrors = validationResults.filter((result) => result.validationStatus === 'error');

  // Reload data and clear all states
  const reloadDataAndClearAllStates = () => {
    fetchUsefulLifeData();
    setRemovedRecords([]);
    setValidationResults([]);
    setSubmitClickCount(0);
  };

  const handleRefreshClick = () => {
    if (hasChanges) {
      setIsModalVisible(true);
    } else {
      reloadDataAndClearAllStates();
    }
  };

  const handleConfirmDiscard = () => {
    setIsModalVisible(false);
    reloadDataAndClearAllStates();
  };

  const handleCancelDiscard = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    setSubmitClickCount((prevCount) => prevCount + 1);

    // Final validation check before submission
    await validateData();
    if (!allValidationsPassed) {
      return;
    }

    setIsSubmitting(true);
    const inProgressMessageId = generateUniqueId();
    displayFlashMessage(UsefulLifeValidationMessages.SUBMISSION_IN_PROGRESS, 'in-progress', false, inProgressMessageId);

    // Combine modified, new, and removed records
    const modifiedAndNewRecords = usefulLifeGridData.filter((record) => record.isModified || !record.useful_life_id);
    const combinedRecords = [...modifiedAndNewRecords, ...removedRecords];

    const finalDataToSubmit = combinedRecords.map((record) => {
      const { isModified, row_id, ...cleanRecord } = record as UsefulLifeMappingGridEntity; // Type assertion to include isModified
      if (cleanRecord.useful_life_id) {
        return {
          ...cleanRecord,
          updated_at: getCurrentUTCTimeInISO(),
          updated_by: Alias
        };
      } else {
        return {
          ...cleanRecord,
          is_active: true,
          created_at: getCurrentUTCTimeInISO(),
          created_by: Alias,
          updated_at: getCurrentUTCTimeInISO(),
          updated_by: Alias
        };
      }
    });

    try {
      const response = await updateUsefulLifeMappings(finalDataToSubmit);
      const noOfRecordsUpdated = response?.createOrUpdateUsefulLifeDetails?.numberOfRecordsUpdated;
      displayFlashMessage(UsefulLifeValidationMessages.SUBMISSION_SUCCESS(noOfRecordsUpdated), 'success', true);
      reloadDataAndClearAllStates();
    } catch (error: any) {
      logger.error('Unable to submit useful life mapping mutation.', error);
      displayFlashMessage(UsefulLifeValidationMessages.SUBMISSION_FAILED, 'error', true);
    } finally {
      setIsSubmitting(false);
      clearSpecificFlashMessage(inProgressMessageId);
    }
  };

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<AdminConsoleSideNavigation />}
      breadcrumbs={<XPTBreadcrumbs items={getUsefulLifeBreadcrumbItems()} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <div className="xpt-app-layout-content">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <ErrorBoundary
              FallbackComponent={() => (
                <Alert
                  type="error"
                  dismissible={false}
                  visible={true}
                  header="Error"
                  action={<Button onClick={() => window.location.reload()}>Reload</Button>}
                >
                  {'Unable to load data, try reloading once'}
                </Alert>
              )}
            >
              <ContentLayout header={<Header>{'Useful Life Mappings'}</Header>}>
                <Container
                  className="xpt-mapping-ag-grid-container"
                  disableContentPaddings
                  header={
                    <Header
                      actions={
                        <SpaceBetween size="m" direction="horizontal">
                          <Button iconName="refresh" onClick={handleRefreshClick} disabled={isSubmitting}></Button>
                          <Button iconName="insert-row" onClick={handleAddNewRow} disabled={isSubmitting}>
                            {'Add'}
                          </Button>
                          <Button iconName="delete-marker" onClick={handleDeleteSelectedRows} disabled={isSubmitting || selectedRows.length === 0}>
                            {'Delete'}
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting || !hasChanges}>
                            {`Submit`}
                          </Button>
                        </SpaceBetween>
                      }
                    >
                      <SpaceBetween size="s" direction="horizontal">
                        {validationErrors.length > 0 && submitClickCount > 0
                          ? validationErrors.map((error, index) => (
                              <Popover
                                key={index}
                                dismissButton={false}
                                triggerType="text"
                                size="large"
                                content={
                                  <div className="popover-content">
                                    {error.validationDefaultMessage && (
                                      <div>
                                        <strong>{error.validationDefaultMessage}</strong>
                                      </div>
                                    )}
                                  </div>
                                }
                              >
                                <StatusIndicator type="error" colorOverride="red">
                                  {error.validationMessage}
                                </StatusIndicator>
                              </Popover>
                            ))
                          : hasChanges && (
                              <StatusIndicator type="info" colorOverride="blue">
                                {'Changes detected. Submit when done.'}
                              </StatusIndicator>
                            )}
                      </SpaceBetween>
                    </Header>
                  }
                >
                  <div className={themeClassName} style={{ height: '100%', width: '100%' }}>
                    <AgGridReact
                      ref={gridRef}
                      rowData={usefulLifeGridData}
                      columnDefs={columnDefinition}
                      defaultColDef={defaultColDef}
                      getRowId={getRowId}
                      onGridReady={onGridReady}
                      rowSelection="multiple"
                      onCellValueChanged={onCellValueChanged}
                      onSelectionChanged={onSelectionChanged}
                      suppressRowClickSelection={true}
                      enableAdvancedFilter={false}
                      alwaysMultiSort={true}
                      statusBar={StatusBarConfig}
                      rowBuffer={10}
                      rowModelType="clientSide"
                      rowHeight={30}
                      suppressContextMenu
                      enterNavigatesVerticallyAfterEdit
                      enterNavigatesVertically
                      onPasteEnd={handlePasteEnd}
                      suppressLastEmptyLineOnPaste
                      processCellForClipboard={processCellForClipboard}
                      processCellFromClipboard={processCellFromClipboard}
                    />
                  </div>
                </Container>
              </ContentLayout>
              <ConfirmDiscardModal visible={isModalVisible} onConfirm={handleConfirmDiscard} onCancel={handleCancelDiscard} />
            </ErrorBoundary>
          )}
        </div>
      }
    />
  );
};
