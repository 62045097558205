import { AppLayout, Button, Flashbar, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { selectScenarioSnapshotPerBusinessGroup } from 'src/features/planning-cycle/planningCycleSelector';
import { fetchPlanningCycles, fetchSnapshots } from 'src/features/planning-cycle/planningCycleSlice';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import BusinessGroupSideNavigation from '../../xpt-layout/XptBusinessGroupSideNavigation';
import { businessGroupBaseBreadcrumbs } from '../businessGroupSelectors';
import { Scenario_Snapshot_TABLE_CONFIG, Scenario_Snapshot_TABLE_DEFINITIONS, getScenarioSnapshotTableBreadcrumbItems } from './SnapshotConfig';
import CreateSnapshotModal from './SnapshotModal';

const SnapshotsHomePage: React.FC = () => {
  const appLayout = useRef<any>();
  const dispatch = useAppDispatch();

  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const currentBusinessGroupName = currentBusinessGroup?.data_classification.data_classification_name;
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  const { planningCycleSnapshotsStatus } = useSelector((state: RootState) => state.planningCycleStore);
  const scenarioSnapshotPerBusinessGroup = useSelector(selectScenarioSnapshotPerBusinessGroup);

  const [showModal, setShowModal] = useState(false);
  const { flashbarItems, displayFlashMessage } = useFlashbar();

  // Component for rendering the table header, with refresh and create snapshot buttons
  const scenarioSnapshotHeader = () => (
    <Header
      actions={
        <SpaceBetween size="m" direction="horizontal">
          <Button iconName="refresh" onClick={refetch}></Button>
          <Button iconName="add-plus" variant="primary" onClick={handleCreateSnapshot}>
            {`Create snapshot`}
          </Button>
        </SpaceBetween>
      }
      counter={getHeaderCounter([], scenarioSnapshotPerBusinessGroup || [])}
    >
      {`Snapshots`}
    </Header>
  );

  // Handler for opening the Create Snapshot Modal
  const handleCreateSnapshot = () => setShowModal(true);

  // Handler for cancel action in Create Snapshot Modal
  const onCancelCreateSnapshot = () => setShowModal(false);

  // Handler for confirm action in Create Snapshot Modal, triggers data refetch
  const onConfirmingCreateSnapshot = () => {
    setShowModal(false);
    refetch();
  };

  const refetch = async () => {
    try {
      await dispatch(fetchPlanningCycles()).unwrap();
      await dispatch(fetchSnapshots()).unwrap();
    } catch (error: any) {
      logger.error('Unable to load planning cycles & snapshots', { error });
      displayFlashMessage('Unable to load planning cycles & snapshots', 'error', true);
    }
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CreateSnapshotModal
          showModal={showModal}
          onCancel={onCancelCreateSnapshot}
          onConfirm={() => onConfirmingCreateSnapshot()}
          dataClassificationId={currentBusinessGroup?.data_classification?.data_classification_id}
          planningCycleSnapshots={scenarioSnapshotPerBusinessGroup || []}
        />
        <AppLayout
          ref={appLayout}
          headerSelector="#h"
          navigation={<BusinessGroupSideNavigation />}
          toolsHide={true}
          breadcrumbs={<XPTBreadcrumbs items={getScenarioSnapshotTableBreadcrumbItems(businessGroupBaseBreadcrumb, currentBusinessGroupName)} />}
          notifications={<Flashbar items={flashbarItems} stackItems />}
          stickyNotifications={true}
          // maxContentWidth={Number.MAX_VALUE}
          contentType="table"
          content={
            <div className="xpt-app-layout-content">
              <XptTable
                xptTableVariant={'full-page'}
                loadingStatus={planningCycleSnapshotsStatus === LoadingStatus.Loading}
                entityName={Scenario_Snapshot_TABLE_CONFIG.columnInfo.entityName}
                xptTableHeader={scenarioSnapshotHeader()}
                allItems={scenarioSnapshotPerBusinessGroup || []}
                allColumns={Scenario_Snapshot_TABLE_CONFIG}
                columnDefinitions={Scenario_Snapshot_TABLE_DEFINITIONS}
                itemsPerPage={20}
                selectedItems={[]}
                setSelectedItems={async () => {}}
              />
            </div>
          }
        />
      </ErrorBoundary>
    </>
  );
};

export default SnapshotsHomePage;
