import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { POTaggingColumnsInfo, POTaggingDataValidationStatus, POTaggingEntity, POTaggingFileDataEntity } from 'src/models/POTaggingModel';
import { RootState } from 'src/store/store';
import { readExcelFileWithHeader } from 'src/utils/file-utils';
import { useAuth } from '../auth/AuthContextProvider';
import { currentBusinessGroup, currentBusinessGroupShortDesc } from '../business-group/businessGroupSelectors';
import { getPOTaggingHeaderInfo } from './POTaggingColumnGenerator';

import { Box, Button, FileUpload, FormField, Header, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { OperationType } from 'src/constants/generic-constants';
import { fileUploadI18nStrings } from 'src/i18n-strings/file-upload';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import * as ForecastTemplateDataValidation from '../business-group/forecast-template-v2/forecast-utils/ForecastTemplateDataValidations';
import * as POTaggingDataValidation from './POTaggingDataValidations';
import { submitPOTaggingData } from './POTaggingUtils';

const AcceptedFileFormForExcel = {
  ConstraintMessage: 'Upload Excel (.xlsx) file only.',
  FileFormats: '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

interface POTaggingFileUploadProps {
  showModal: boolean;
  onCancel: () => void;
  onSuccessConfirm: () => void;
  lineItemIdsBasedOnCC: string[];
}

export const POTaggingFileUpload: React.FC<POTaggingFileUploadProps> = ({ showModal, onCancel, onSuccessConfirm, lineItemIdsBasedOnCC }) => {
  const userAuth = useAuth();
  const userAlias = userAuth.Alias;

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileModifiedData, setUploadedFileModifiedData] = useState<POTaggingEntity[]>([]);
  const [isUploadedFileValid, setIsUploadedFileValid] = useState<boolean>(false);
  const [fileValidationStatus, setFileValidationStatus] = useState<POTaggingDataValidationStatus>(
    POTaggingDataValidation.INITIAL_PO_VALIDATION_STATUS
  );
  const [fileSubmitStatus, setFileSubmitStatus] = useState<ValidationStatusEntity>(POTaggingDataValidation.INITIAL_SUBMIT_STATUS);

  const businessGroup = useSelector(currentBusinessGroup);
  const dataClassificationId = businessGroup?.data_classification?.data_classification_id;
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc) || 'default';

  const { selectedActualMonth, selectedCostCenter, actualTaggingData } = useSelector((state: RootState) => state.poTaggingStore);

  // Reset uploaded file and validation status when modal visibility changes
  useEffect(() => {
    resetFileAndValidationStatus();
    setUploadedFile(null);
  }, [showModal]);

  const resetFileAndValidationStatus = () => {
    setFileValidationStatus(POTaggingDataValidation.INITIAL_PO_VALIDATION_STATUS);
    setUploadedFileModifiedData([]);
    setIsUploadedFileValid(false);
  };

  useEffect(() => {
    handleOnFileUpload();
  }, [uploadedFile]);

  const handleOnFileUpload = async () => {
    if (uploadedFile && selectedActualMonth && selectedCostCenter) {
      logger.debug(`File upload initiated for ${selectedActualMonth}, ${selectedCostCenter}`);
      try {
        const { headerRow, fileData } = await readExcelFileWithHeader(uploadedFile);

        logger.info(
          `User ${userAlias} uploaded a file for ${selectedActualMonth}, ${selectedCostCenter} with total number of records: ${fileData.length}`
        );

        logger.debug('headerRow:', headerRow);
        logger.debug('File data:', fileData);

        validateFileData(headerRow, fileData);
      } catch (error: any) {
        logger.error('Error while reading the file:', error);
        setFileValidationStatus({
          ...POTaggingDataValidation.INITIAL_PO_VALIDATION_STATUS,
          HeadersMatching: {
            colorOverride: 'red',
            validationStatus: 'error',
            validationMessage: 'Error reading the file',
            validationDefaultMessage: 'Error reading the file',
            validationErrorDetails: [error.message]
          }
        });
      }
    }
  };

  const validateFileData = async (headerRow: string[], poTaggingRelevantRowData: any[]) => {
    if (uploadedFile && businessGroup && selectedActualMonth && selectedCostCenter) {
      resetFileAndValidationStatus();
      try {
        logger.debug(`Validating file data for  ${selectedActualMonth}, ${selectedCostCenter} in ${businessGroupShortDesc}`);
        const poTaggingColumnInfo: POTaggingColumnsInfo = getPOTaggingHeaderInfo();

        const headersToBe = poTaggingColumnInfo.poTaggingFileHeader.map((column) => column.headerName);
        const headerValidation = await validateHeadersStep(headerRow, headersToBe);

        if (headerValidation.validationStatus === 'error') {
          logger.debug(
            `Headers validation failed for ${selectedActualMonth}, ${selectedCostCenter} in ${businessGroupShortDesc}. No further validations.`
          );
          return;
        }

        logger.debug(
          `Headers validation passed for ${selectedActualMonth}, ${selectedCostCenter} in ${businessGroupShortDesc}. Proceeding with further validations.`
        );

        const transformedRows: POTaggingFileDataEntity[] = POTaggingDataValidation.transformUploadedPOTaggingData(
          poTaggingColumnInfo,
          poTaggingRelevantRowData
        );

        const poTaggingCompleteData: POTaggingEntity[] = actualTaggingData.data;

        logger.debug(`Transformed ${poTaggingRelevantRowData.length} to ${transformedRows.length} rows. Proceeding with further validations.`);

        await validateRemainingSteps(transformedRows, poTaggingCompleteData, lineItemIdsBasedOnCC, selectedActualMonth, selectedCostCenter);
      } catch (error: any) {
        logger.error('Error during file data validation:', error);
        handleValidationError(error);
      }
    }
  };

  const validateHeadersStep = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
    const headerValidation = await ForecastTemplateDataValidation.validateHeaders(headerRow, expectedHeaders);

    if (headerValidation.validationStatus === 'error') {
      setIsUploadedFileValid(false);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: {
          ...headerValidation,
          validationStatus: 'error'
        },
        FilterFieldValidation: {
          ...prevState.FilterFieldValidation,
          validationStatus: 'error',
          validationMessage: POTaggingDataValidation.POTaggingValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        },
        ActualIdValidation: {
          ...prevState.ActualIdValidation,
          validationStatus: 'error',
          validationMessage: POTaggingDataValidation.POTaggingValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        },
        LineItemIdValidation: {
          ...prevState.LineItemIdValidation,
          validationStatus: 'error',
          validationMessage: POTaggingDataValidation.POTaggingValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        },
        UnAuthorizedModifications: {
          ...prevState.UnAuthorizedModifications,
          validationStatus: 'error',
          validationMessage: POTaggingDataValidation.POTaggingValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        }
      }));
    } else {
      setIsUploadedFileValid(true);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: headerValidation
      }));
    }

    return headerValidation;
  };

  const skipValidationSteps = () => {
    setFileValidationStatus({
      HeadersMatching: {
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.HEADER_VALIDATION_SUCCESS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      FilterFieldValidation: {
        colorOverride: 'grey',
        validationStatus: 'stopped',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.NO_MODIFIED_ROWS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      ActualIdValidation: {
        colorOverride: 'grey',
        validationStatus: 'stopped',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.NO_MODIFIED_ROWS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      LineItemIdValidation: {
        colorOverride: 'grey',
        validationStatus: 'stopped',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.NO_MODIFIED_ROWS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      UnAuthorizedModifications: {
        colorOverride: 'grey',
        validationStatus: 'stopped',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.NO_MODIFIED_ROWS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      }
    });
  };

  const validateRemainingSteps = async (
    transformedModifiedRows: POTaggingFileDataEntity[],
    poTaggingCompleteData: POTaggingEntity[],
    lineItemIds: string[],
    selectedMonth: string,
    selectedCostCenter: string
  ) => {
    const modifiedRows = POTaggingDataValidation.getModifiedRows(transformedModifiedRows, actualTaggingData.data, userAlias);
    if (modifiedRows.length === 0) {
      logger.debug('No modified rows found. Skipping further validations.');
      skipValidationSteps();
      return;
    } else {
      logger.debug(`Identified ${modifiedRows.length} modified rows.`);
      setUploadedFileModifiedData(modifiedRows);
    }

    const validations = await Promise.all([
      POTaggingDataValidation.poTaggingFilterDataValidations(modifiedRows, selectedMonth, selectedCostCenter),
      POTaggingDataValidation.actualIdValidation(modifiedRows, poTaggingCompleteData),
      POTaggingDataValidation.lineItemIdValuation(modifiedRows, lineItemIds),
      POTaggingDataValidation.validateUnauthorizedModifications(modifiedRows, poTaggingCompleteData)
    ]);

    setFileValidationStatus({
      HeadersMatching: {
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.HEADER_VALIDATION_SUCCESS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      FilterFieldValidation: validations[0],
      ActualIdValidation: validations[1],
      LineItemIdValidation: validations[2],
      UnAuthorizedModifications: validations[3]
    });
  };

  const handleValidationError = (error: any) => {
    logger.error('Validation error:', error);
    setIsUploadedFileValid(false);
    setFileValidationStatus((prevState) => ({
      ...prevState,
      HeadersMatching: {
        validationStatus: 'error',
        colorOverride: 'red',
        validationMessage: POTaggingDataValidation.POTaggingValidationMessages.VALIDATION_ERROR,
        validationDefaultMessage: POTaggingDataValidation.POTaggingValidationMessages.VALIDATION_ERROR,
        validationErrorDetails: [error.message]
      }
    }));
  };

  const isAllValidationsPassed = () => {
    return (
      fileValidationStatus.HeadersMatching.validationStatus === 'success' &&
      fileValidationStatus.FilterFieldValidation.validationStatus === 'success' &&
      fileValidationStatus.ActualIdValidation.validationStatus === 'success' &&
      fileValidationStatus.LineItemIdValidation.validationStatus === 'success' &&
      fileValidationStatus.UnAuthorizedModifications.validationStatus === 'success'
    );
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (dataClassificationId && businessGroup && selectedActualMonth && selectedCostCenter) {
      setFileSubmitStatus({
        validationMessage: 'Request in progress',
        validationStatus: 'loading',
        colorOverride: 'blue',
        validationDefaultMessage: '',
        validationErrorDetails: []
      });

      try {
        await submitPOTaggingData(
          uploadedFileModifiedData,
          businessGroupShortDesc,
          dataClassificationId!,
          selectedActualMonth!,
          selectedCostCenter!,
          userAuth.Alias,
          OperationType.FILE_UPLOAD
        );

        setFileSubmitStatus({
          validationMessage: POTaggingDataValidation.POTaggingValidationMessages.PO_TAGGING_UPLOAD_SUCCESS,
          validationStatus: 'success',
          colorOverride: 'green',
          validationDefaultMessage: '',
          validationErrorDetails: []
        });
      } catch (error: any) {
        logger.error('Error during forecast data submission:', error);
        setFileSubmitStatus({
          validationMessage: POTaggingDataValidation.POTaggingValidationMessages.PO_TAGGING_UPLOAD_FAILED,
          validationStatus: 'error',
          colorOverride: 'red',
          validationDefaultMessage: POTaggingDataValidation.POTaggingValidationMessages.PO_TAGGING_UPLOAD_FAILED,
          validationErrorDetails: [error.message]
        });
      }
    }
  };

  return (
    <Modal
      onDismiss={(e) => {
        if (fileSubmitStatus.validationStatus !== 'loading') onCancel();
      }}
      visible={showModal}
      size="large"
      footer={
        <Box>
          <Box float="left">
            {fileSubmitStatus.validationMessage !== 'Not Initiated' && (
              <StatusIndicator type={fileSubmitStatus.validationStatus} colorOverride={fileSubmitStatus.colorOverride}>
                {fileSubmitStatus.validationMessage}
              </StatusIndicator>
            )}
          </Box>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {fileSubmitStatus.validationStatus !== 'success' && (
                <>
                  <Button variant="link" onClick={handleCancel} disabled={fileSubmitStatus.validationStatus === 'loading'}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!isAllValidationsPassed() || fileSubmitStatus.validationStatus === 'loading'}
                  >
                    Submit
                  </Button>
                </>
              )}
              {fileSubmitStatus.validationStatus === 'success' && (
                <Button variant="primary" onClick={onSuccessConfirm}>
                  Close
                </Button>
              )}
            </SpaceBetween>
          </Box>
        </Box>
      }
      header={<Header>Upload PO Tagging data</Header>}
    >
      <FormField label="" description="">
        <FileUpload
          onChange={({ detail }) => setUploadedFile(detail.value[0])}
          value={uploadedFile ? [uploadedFile] : []}
          i18nStrings={fileUploadI18nStrings()}
          invalid={!isUploadedFileValid}
          accept={AcceptedFileFormForExcel.FileFormats}
          constraintText={AcceptedFileFormForExcel.ConstraintMessage}
          ariaRequired
          multiple={false}
          showFileLastModified
          showFileSize
          showFileThumbnail
          tokenLimit={1}
        />
        <Box padding={{ top: 's' }}>
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidationStatus).map((key, index) => {
              const validationStatus = fileValidationStatus[key as keyof POTaggingDataValidationStatus];
              return (
                <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                  {validationStatus.validationMessage}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </Box>
      </FormField>
    </Modal>
  );
};
