import { SelectProps } from '@amzn/awsui-components-react';
import { CellClassParams, ColDef, IAggFuncParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { PlanningCycleInfo } from 'src/models/XptReportingModels';
import { CellDataType, forecastCurrencyFormatter, percentFormatter } from 'src/utils/ag-grid-utils';
import { generateReportBusinessGroupColumns, generateReportCorpSegmentsRequiredColumns } from '../XptReportColumnGenerator';
import { ColumnWidths, XptReportGridFixedFields } from '../XptReportGridConstants';
import { calculateVariance, ROLL_UP_PERIOD } from './XptVarianceReportUtils';

export const generateVarianceReportColumnDefinitions = async (
  businessGroup: BusinessGroupEntity,
  currentPlanningCycleInfo: PlanningCycleInfo,
  comparisonPlanningCycleInfo: PlanningCycleInfo,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  selectedPeriods: SelectProps.Options
): Promise<ColDef[]> => {
  try {
    const requiredCorpSegments = businessGroup.corp_segments.filter((corpSegment) => corpSegment.corp_segment_required);
    const businessSegments = businessGroup.business_segments;

    return [
      ...generateInitialColumns(),
      ...generateReportCorpSegmentsRequiredColumns(requiredCorpSegments),
      ...generateReportBusinessGroupColumns(businessSegments),
      ...generatePeriodColumnDefinitions(currentPlanningCycleInfo, comparisonPlanningCycleInfo, selectedRollupPeriod, selectedPeriods)
    ];
  } catch (error: any) {
    logger.error('Unable to generate variance column definition', error);
    throw new Error('Unable to generate column definition');
  }
};

export const generateInitialColumns = (): ColDef[] => {
  return [
    {
      field: XptReportGridFixedFields.XptLineItemSeqId.value,
      headerName: XptReportGridFixedFields.XptLineItemSeqId.displayName,
      wrapHeaderText: true,
      hide: true,
      suppressColumnsToolPanel: true,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: false
    },
    {
      field: XptReportGridFixedFields.XptLineItemId.value,
      headerName: XptReportGridFixedFields.XptLineItemId.displayName,
      wrapHeaderText: true,
      hide: true,
      suppressColumnsToolPanel: true,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: false
    }
  ];
};

export const generatePeriodColumnDefinitions = (
  currentPlanningCycleInfo: PlanningCycleInfo,
  comparisonPlanningCycleInfo: PlanningCycleInfo,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  selectedPeriods: SelectProps.Options
): ColDef[] => {

  const selectedPeriodValues: string[] = selectedPeriods.map((selectedPeriod: SelectProps.Option) => selectedPeriod.value || '');

  // Create four strings for each period value
  const variancePeriods: string[] = selectedPeriodValues.flatMap((periodValue: string) => [
    `${periodValue}_current`,
    `${periodValue}_comparison`,
    `${periodValue}_variance`,
    `${periodValue}_variance_percent`
  ]);
  const variancePeriodsSorted = sortVariancePeriods(variancePeriods, selectedRollupPeriod);

  return variancePeriodsSorted.map((variancePeriod) => {
    if (variancePeriod.endsWith('_variance_percent')) {
      return createVariancePeriodColumn(
        currentPlanningCycleInfo.planningCycleSelected.scenario.planning_cycle_name,
        comparisonPlanningCycleInfo.planningCycleSelected.scenario.planning_cycle_name,
        selectedRollupPeriod,
        variancePeriod
      );
    } else {
      return createPeriodColumn(
        currentPlanningCycleInfo.planningCycleSelected.scenario.planning_cycle_name,
        comparisonPlanningCycleInfo.planningCycleSelected.scenario.planning_cycle_name,
        selectedRollupPeriod,
        variancePeriod
      );
    }
  });
};

/**
 * Sorts the variance periods based on the selected rollup period.
 *
 * @param {string[]} variancePeriods - The array of variance periods to sort.
 * @param {ROLL_UP_PERIOD} selectedRollupPeriod - The selected rollup period (YEARLY or QUARTERLY).
 * @returns {string[]} - The sorted array of variance periods.
 */
export const sortVariancePeriods = (variancePeriods: string[], selectedRollupPeriod: ROLL_UP_PERIOD): string[] => {
  const sortedPeriods = [...variancePeriods].sort((a, b) => {
    if (selectedRollupPeriod === ROLL_UP_PERIOD.YEARLY) {
      const yearA = parseInt(a.split('_')[0]);
      const yearB = parseInt(b.split('_')[0]);
      return yearA - yearB;
    } else {
      const yearA = parseInt(a.split('_')[0]);
      const quarterA = a.split('_')[1];
      const yearB = parseInt(b.split('_')[0]);
      const quarterB = b.split('_')[1];

      if (yearA !== yearB) {
        return yearA - yearB;
      } else {
        const quarterOrder = ['Q1', 'Q2', 'Q3', 'Q4'];
        return quarterOrder.indexOf(quarterA) - quarterOrder.indexOf(quarterB);
      }
    }
  });

  return sortedPeriods;
};

export const isPeriodExistsInSelectedPeriods = (selectedPeriodValues: string[], period: string) => {
  const periodPrefix = period.split('_')[0];
  return !selectedPeriodValues.includes(periodPrefix);
};

export const createPeriodColumn = (
  currentPlanningCycleName: string,
  comparisonPlanningCycleName: string,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  period: string
): ColDef => {
  return {
    field: period,
    headerName: getReadableVarianceHeader(currentPlanningCycleName, comparisonPlanningCycleName, selectedRollupPeriod, period),
    wrapHeaderText: true,
    width: ColumnWidths.VARIANCE_PERIOD_COLUMN,
    minWidth: ColumnWidths.VARIANCE_PERIOD_COLUMN,
    suppressMovable: true,
    cellDataType: CellDataType.NUMBER,
    headerClass: period.endsWith('_variance') ? 'header-variance' : '',
    cellClass: ['forecast-month-cell'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false,
      'variance-cell': (params: CellClassParams) => params.column.getColId().endsWith('_variance')
    },
    valueGetter: (params: ValueGetterParams) => {
      const { data } = params;
      if (!data) return null;

      if (period.endsWith('_current')) {
        return data[`${period}`];
      } else if (period.endsWith('_comparison')) {
        return data[`${period}`];
      } else if (period.endsWith('_variance')) {
        const currentVal = data[`${period.replace('_variance', '')}_current`];
        const comparisonVal = data[`${period.replace('_variance', '')}_comparison`];
        const { variance } = calculateVariance(currentVal, comparisonVal);
        return variance;
      }
      return null;
    },
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    aggFunc: 'sum',
    enableValue: true
  };
};

export const createVariancePeriodColumn = (
  currentPlanningCycleName: string,
  comparisonPlanningCycleName: string,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  period: string
): ColDef => {
  return {
    field: period,
    headerName: getReadableVarianceHeader(currentPlanningCycleName, comparisonPlanningCycleName, selectedRollupPeriod, period),
    wrapHeaderText: true,
    width: ColumnWidths.VARIANCE_PERIOD_COLUMN,
    minWidth: ColumnWidths.VARIANCE_PERIOD_COLUMN,
    suppressMovable: true,
    cellDataType: CellDataType.NUMBER,
    headerClass: [period.endsWith('_variance_percent') ? 'header-variance-percent' : ''],
    cellClass: ['forecast-month-cell'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false,
      'variance-percent-null': (params: CellClassParams) => params.column.getColId().endsWith('_variance_percent') && params.value == null,
      'variance-percent-zero': (params: CellClassParams) => params.column.getColId().endsWith('_variance_percent') && params.value == 0,
      'variance-percent-positive': (params: CellClassParams) => params.column.getColId().endsWith('_variance_percent') && params.value > 0,
      'variance-percent-negative': (params: CellClassParams) => params.column.getColId().endsWith('_variance_percent') && params.value < 0
    },
    valueGetter: (params: ValueGetterParams) => {
      const { data } = params;
      if (!data) return null;

      const currentVal = data[`${period.replace('_variance_percent', '')}_current`];
      const comparisonVal = data[`${period.replace('_variance_percent', '')}_comparison`];
      const { variancePercentage } = calculateVariance(currentVal, comparisonVal);
      return variancePercentage;
    },
    valueFormatter: (params: ValueFormatterParams) => percentFormatter(params.value),
    aggFunc: (params: IAggFuncParams) => {
      if (params.rowNode.group && params.rowNode.aggData) {
        const aggregatedData = params.rowNode.aggData;
        const currentTotal = aggregatedData[`${period.replace('_variance_percent', '')}_current`] || 0;
        const comparisonTotal = aggregatedData[`${period.replace('_variance_percent', '')}_comparison`] || 0;
        const { variancePercentage } = calculateVariance(currentTotal, comparisonTotal);
        return variancePercentage;
      }
      return null;
    },
    enableValue: true
  };
};

export const getReadableVarianceHeader = (
  currentPlanningCycleName: string,
  comparisonPlanningCycleName: string,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  period: string
): string => {
  let readableHeader = '';

  if (selectedRollupPeriod === ROLL_UP_PERIOD.YEARLY) {
    if (period.includes('_current')) {
      const readablePeriod = period.replace('_current', '');
      readableHeader = `${currentPlanningCycleName} ${readablePeriod}`;
    } else if (period.includes('_comparison')) {
      const readablePeriod = period.replace('_comparison', '');
      readableHeader = `${comparisonPlanningCycleName} ${readablePeriod}`;
    } else if (period.includes('_variance_percent')) {
      const readablePeriod = period.replace('_variance_percent', '');
      readableHeader = `${readablePeriod} Variance %`;
    } else if (period.includes('_variance')) {
      const readablePeriod = period.replace('_variance', '');
      readableHeader = `${readablePeriod} Variance $`;
    }
  } else {
    if (period.includes('_current')) {
      const readablePeriod = period.replace('_current', '');
      const year = readablePeriod.split('_')[0];
      const quarter = readablePeriod.split('_')[1];
      readableHeader = `${currentPlanningCycleName} ${year} ${quarter}`;
    } else if (period.includes('_comparison')) {
      const readablePeriod = period.replace('_comparison', '');
      const year = readablePeriod.split('_')[0];
      const quarter = readablePeriod.split('_')[1];
      readableHeader = `${comparisonPlanningCycleName} ${year} ${quarter}`;
    } else if (period.includes('_variance_percent')) {
      const readablePeriod = period.replace('_variance_percent', '');
      const year = readablePeriod.split('_')[0];
      const quarter = readablePeriod.split('_')[1];
      readableHeader = `${year} ${quarter} Variance %`;
    } else if (period.includes('_variance')) {
      const readablePeriod = period.replace('_variance', '');
      const year = readablePeriod.split('_')[0];
      const quarter = readablePeriod.split('_')[1];
      readableHeader = `${year} ${quarter} Variance $`;
    }
  }
  return readableHeader;
};
